import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import {
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import * as Yup from "yup";

const AuraCasinoForm = (props) => {
  const {
    viewOnly,
    isOpen,
    toggle,
    auraCasino,
    updateAuraCasino,
    addAuraCasino,
  } = props;
  const [isEdit, setIsEdit] = useState(!!auraCasino);

  useEffect(() => {
    setIsEdit(!!auraCasino);
    setImageSrc({
      displayIcon: auraCasino?.displayIcon,
      gameImage: auraCasino?.gameImage,
    });
  }, [auraCasino]);


  const [imageSrc, setImageSrc] = useState({
    displayIcon: "",
    gameImage: "",
  });

  // Handle file input change
  const previewFileChange = (name, file) => {
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImageSrc({ ...imageSrc, [name]: e.target.result });
      };
      reader.readAsDataURL(file);
    }
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Please Enter Name"),
    gameId: Yup.number().required("Please Enter Game Id"),
    gameLaunch: Yup.string().required("Please Enter Game Launch"),
    displayIcon: Yup.mixed().when("type", {
      is: (type) => isEdit,
      then: Yup.string().notRequired(),
      otherwise: Yup.mixed()
        .required("Display Icon is required")
        .test("fileSize", "File size should be less than equal to 40KB", (value) => {
          return !value || (value && value.size <= 40 * 1024); // 1MB in bytes
        })
        .test("fileType", "File should be png or webp.", (value) => {
          return (
            !value ||
            (value &&
              ["image/png", "image/webp"].includes(value.type))
          );
        })
        .test("resolution", "Image resolution should be 130 x 90", (value) => {
          if (!value) return true; 
          return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => {
              resolve(img.width == 130 && img.height == 90);
            };
            img.onerror = () => {
              reject(new Error("Failed to load image"));
            };
            img.src = URL.createObjectURL(value);
          });
        })
    }),
    gameImage: Yup.mixed().when("type", {
      is: (type) => isEdit,
      then: Yup.string().notRequired(),
      otherwise: Yup.mixed()
        .required("Game Image is required")
        .test("fileSize", "File size should be less than equal to 40KB", (value) => {
          return !value || (value && value.size <= 40 * 1024); // 1MB in bytes
        })
        .test("fileType", "File should be png or webp.", (value) => {
          return (
            !value ||
            (value &&
              ["image/png", "image/webp"].includes(value.type))
          );
        })
        .test("resolution", "Image resolution should be 250 x 250", (value) => {
          if (!value) return true; 
          return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => {
              resolve(img.width == 250 && img.height == 250);
            };
            img.onerror = () => {
              reject(new Error("Failed to load image"));
            };
            img.src = URL.createObjectURL(value);
          });
        })
    }),
  });

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      name: (auraCasino && auraCasino.name) || "",
      gameId: (auraCasino && auraCasino.gameId) ?? "",
      gameLaunch: (auraCasino && auraCasino.gameLaunch) ?? "",
      displayIcon: (auraCasino && auraCasino.displayIcon) || "",
      gameImage: (auraCasino && auraCasino.gameImage) || "",
      isFavourite: (auraCasino && auraCasino.isFavourite) || false,
      isNew: (auraCasino && auraCasino.isNew) || false,
      isPopular: (auraCasino && auraCasino.isPopular) || false,
      isLive: (auraCasino && auraCasino.isLive) || false,
      isVirtual: (auraCasino && auraCasino.isVirtual) || false,
      isActive: (auraCasino && auraCasino.isActive) || false,
    },
    validationSchema,
    onSubmit: (values) => {
      const newAuraCasino = new FormData();
      for (const k in values) {
        if (!Object.hasOwnProperty.call(values, k)) continue;
        const element = values[k];
        if (
          ["displayIcon", "gameImage"].includes(k) &&
          !(element instanceof File)
        )
          continue;
        newAuraCasino.append(k, element);
      }

      isEdit
        ? updateAuraCasino(auraCasino._id ?? 0, newAuraCasino, true)
        : addAuraCasino(newAuraCasino);
    },
  });

  useEffect(() => {
    validation.validateForm();
  }, [validation.values.type]);

  useEffect(() => {
    if (!isOpen) {
      setImageSrc({ displayIcon: "", gameImage: "" });
      validation.resetForm();
    }
  }, [isOpen]);

  return (
    <Modal
      id="showModal"
      isOpen={isOpen}
      toggle={toggle}
      centered
      className="modal-lg"
    >
      <ModalHeader className="bg-light p-3" toggle={toggle}>
        {!!isEdit ? "Edit Aura Casino" : "Add Aura Casino"}
      </ModalHeader>
      <Form
        className="tablelist-form"
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
      >
        <ModalBody>
          <Row>
            <Col sm="6">
              <div className="mb-3">
                <Label htmlFor="name-field" className="form-label">
                  Name
                </Label>
                <Input
                  name="name"
                  id="name-field"
                  className="form-control"
                  placeholder="Name"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.name || ""}
                  invalid={
                    validation.touched.name && validation.errors.name
                      ? true
                      : false
                  }
                />
                {validation.touched.name && validation.errors.name ? (
                  <FormFeedback type="invalid">
                    {validation.errors.name}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col sm="6">
              <div className="mb-3">
                <Label htmlFor="gameId-field" className="form-label">
                  Game Id
                </Label>
                {/* className={classnames({ active: currentActiveTab === '1' })} */}
                <Input
                  name="gameId"
                  id="gameId-field"
                  className="form-control"
                  placeholder="Game Id"
                  type="number"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.gameId ?? ""}
                  invalid={
                    validation.touched.gameId && validation.errors.gameId
                      ? true
                      : false
                  }
                />
                {validation.touched.gameId && validation.errors.gameId ? (
                  <FormFeedback type="invalid">
                    {validation.errors.gameId}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col sm="6">
              <div className="mb-3">
                <Label htmlFor="displayIcon-field" className="form-label">
                  Display Icon
                </Label>
                <Input
                  name="displayIcon"
                  id="displayIcon-field"
                  className="form-control"
                  placeholder="Display Icon"
                  type="file"
                  onChange={(event) => {
                    validation.setFieldValue(
                      "displayIcon",
                      event.currentTarget.files[0]
                    );
                    previewFileChange(
                      "displayIcon",
                      event.currentTarget.files[0]
                    );
                  }}
                  invalid={
                    validation.touched.displayIcon &&
                    validation.errors.displayIcon
                      ? true
                      : false
                  }
                />
                {validation.touched.displayIcon &&
                validation.errors.displayIcon ? (
                  <FormFeedback type="invalid">
                    {validation.errors.displayIcon}
                  </FormFeedback>
                ) : null}

                {(imageSrc.displayIcon && (
                  <>
                    <span>Preview </span>
                    <img
                      src={imageSrc?.displayIcon}
                      alt="Preview"
                      style={{ maxWidth: "100%", maxHeight: "100px" }}
                    />
                  </>
                )) ||
                  (isEdit && auraCasino?.displayIcon && (
                    <>
                      <span>Preview </span>
                      <img
                        src={auraCasino?.displayIcon}
                        alt="Preview"
                        style={{ maxWidth: "100%", maxHeight: "100px" }}
                      />
                    </>
                  ))}
              </div>
            </Col>
            <Col sm="6">
              <div className="mb-3">
                <Label htmlFor="gameImage-field" className="form-label">
                  Game Image
                </Label>
                <Input
                  name="gameImage"
                  id="gameImage-field"
                  className="form-control"
                  placeholder="Game Image"
                  type="file"
                  onChange={(event) => {
                    validation.setFieldValue(
                      "gameImage",
                      event.currentTarget.files[0]
                    );
                    previewFileChange(
                      "gameImage",
                      event.currentTarget.files[0]
                    );
                  }}
                  invalid={
                    validation.touched.gameImage && validation.errors.gameImage
                      ? true
                      : false
                  }
                />
                {validation.touched.gameImage && validation.errors.gameImage ? (
                  <FormFeedback type="invalid">
                    {validation.errors.gameImage}
                  </FormFeedback>
                ) : null}

                {(imageSrc.gameImage && (
                  <>
                    <span>Preview </span>
                    <img
                      src={imageSrc?.gameImage}
                      alt="Preview"
                      style={{ maxWidth: "100%", maxHeight: "100px" }}
                    />
                  </>
                )) ||
                  (isEdit && auraCasino?.gameImage && (
                    <>
                      <span>Preview </span>
                      <img
                        src={auraCasino?.gameImage}
                        alt="Preview"
                        style={{ maxWidth: "100%", maxHeight: "100px" }}
                      />
                    </>
                  ))}
              </div>
            </Col>
            <Col sm="6">
              <div className="mb-3">
                <Label htmlFor="gameLaunch-field" className="form-label">
                  Game Launch
                </Label>
                <Input
                  name="gameLaunch"
                  id="gameLaunch-field"
                  className="form-control"
                  placeholder="Game Launch"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.gameLaunch || ""}
                  invalid={
                    validation.touched.gameLaunch && validation.errors.gameLaunch
                      ? true
                      : false
                  }
                />
                {validation.touched.gameLaunch && validation.errors.gameLaunch ? (
                  <FormFeedback type="invalid">
                    {validation.errors.gameLaunch}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>

          <Row>
            <Col sm="4">
              <div className="mb-3">
                <Label htmlFor="isFavourite-field" className="form-label">
                  isFavourite
                </Label>
                <br />
                <Row>
                  <Col sm="12" className="px-4">
                    <div className="form-check form-switch form-switch-right form-switch-md">
                      <Input
                        className="form-check-input"
                        type="checkbox"
                        name="isFavourite"
                        id="isFavourite-field"
                        defaultChecked={auraCasino ? auraCasino.isFavourite : false}
                        onChange={(e) => {
                          validation.setFieldValue(
                            "isFavourite",
                            e.target.checked ? true : false
                          );
                        }}
                      />
                    </div>
                  </Col>
                </Row>

                {validation.touched.isFavourite &&
                validation.errors.isFavourite ? (
                  <FormFeedback type="invalid">
                    {validation.errors.isFavourite}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col sm="4">
              <div className="mb-3">
                <Label htmlFor="isNew-field" className="form-label">
                  isNew
                </Label>
                <br />
                <Row>
                  <Col sm="12" className="px-4">
                    <div className="form-check form-switch form-switch-right form-switch-md">
                      <Input
                        className="form-check-input"
                        type="checkbox"
                        name="isNew"
                        id="isNew-field"
                        defaultChecked={auraCasino ? auraCasino.isNew : false}
                        onChange={(e) => {
                          validation.setFieldValue(
                            "isNew",
                            e.target.checked ? true : false
                          );
                        }}
                      />
                    </div>
                  </Col>
                </Row>

                {validation.touched.isNew && validation.errors.isNew ? (
                  <FormFeedback type="invalid">
                    {validation.errors.isNew}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col sm="4">
              <div className="mb-3">
                <Label htmlFor="isPopular-field" className="form-label">
                  isPopular
                </Label>
                <br />
                <Row>
                  <Col sm="12" className="px-4">
                    <div className="form-check form-switch form-switch-right form-switch-md">
                      <Input
                        className="form-check-input"
                        type="checkbox"
                        name="isPopular"
                        id="isPopular-field"
                        defaultChecked={auraCasino ? auraCasino.isPopular : false}
                        onChange={(e) => {
                          validation.setFieldValue(
                            "isPopular",
                            e.target.checked ? true : false
                          );
                        }}
                      />
                    </div>
                  </Col>
                </Row>

                {validation.touched.isPopular && validation.errors.isPopular ? (
                  <FormFeedback type="invalid">
                    {validation.errors.isPopular}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm="4">
              <div className="mb-3">
                <Label htmlFor="isLive-field" className="form-label">
                  isLive
                </Label>
                <br />
                <Row>
                  <Col sm="12" className="px-4">
                    <div className="form-check form-switch form-switch-right form-switch-md">
                      <Input
                        className="form-check-input"
                        type="checkbox"
                        name="isLive"
                        id="isLive-field"
                        defaultChecked={auraCasino ? auraCasino.isLive : false}
                        onChange={(e) => {
                          validation.setFieldValue(
                            "isLive",
                            e.target.checked ? true : false
                          );
                        }}
                      />
                    </div>
                  </Col>
                </Row>

                {validation.touched.isLive && validation.errors.isLive ? (
                  <FormFeedback type="invalid">
                    {validation.errors.isLive}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col sm="4">
              <div className="mb-3">
                <Label htmlFor="isVirtual-field" className="form-label">
                  isVirtual
                </Label>
                <br />
                <Row>
                  <Col sm="12" className="px-4">
                    <div className="form-check form-switch form-switch-right form-switch-md">
                      <Input
                        className="form-check-input"
                        type="checkbox"
                        name="isVirtual"
                        id="isVirtual-field"
                        defaultChecked={auraCasino ? auraCasino.isVirtual : false}
                        onChange={(e) => {
                          validation.setFieldValue(
                            "isVirtual",
                            e.target.checked ? true : false
                          );
                        }}
                      />
                    </div>
                  </Col>
                </Row>

                {validation.touched.isVirtual && validation.errors.isVirtual ? (
                  <FormFeedback type="invalid">
                    {validation.errors.isVirtual}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>
          <div className="mb-3">
            <Label htmlFor="active-field" className="form-label">
              Active
            </Label>
            <br />
            <Row>
              <Col sm="12" className="px-4">
                <div className="form-check form-switch form-switch-right form-switch-md">
                  <Input
                    className="form-check-input"
                    type="checkbox"
                    name="isActive"
                    id="active-field"
                    defaultChecked={auraCasino ? auraCasino.isActive : false}
                    onChange={(e) => {
                      validation.setFieldValue(
                        "isActive",
                        e.target.checked ? true : false
                      );
                    }}
                  />
                </div>
              </Col>
            </Row>

            {validation.touched.password && validation.errors.password ? (
              <FormFeedback type="invalid">
                {validation.errors.password}
              </FormFeedback>
            ) : null}
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="hstack gap-2 justify-content-end">
            <button
              type="button"
              className="btn btn-light"
              onClick={() => {
                toggle();
              }}
            >
              Close
            </button>

            <button type="submit" className="btn btn-success">
              {!!isEdit ? "Update" : "Add"}
            </button>
          </div>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default AuraCasinoForm;
