import { createAsyncThunk } from "@reduxjs/toolkit";
import { deleteBlacklistApi, getIpsList, addBlacklistApi } from "../../../helpers/backend_helper";
import { toast } from "react-toastify";

// StakeButtons
export const getBlacklistedIps = createAsyncThunk("market/getBlacklistedIps", async (payload) => {
  const response = await getIpsList(payload);
  return response.data;
});

export const addBlacklistedIps = createAsyncThunk("market/addBlacklistedIps", async (payload) => {
  try {
    const response = await addBlacklistApi(payload);
    return response.data;
  } catch (error) {
    toast.error("Failed to Add");
    return false;
  }
});

export const deleteBlacklistedIps = createAsyncThunk(
  "market/deleteBlacklistedIps",
  async (payload) => {
    try {
      const response = await deleteBlacklistApi(payload.ipIds, payload.data);
      return response.data;
    } catch (error) {
      toast.error("Failed to delete");
      return false;
    }
  }
);
