import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  addSiteRulesAPI,
  createEventRulesAPI,
  deleteSiteRulesAPI,
  getEventRuleAPI,
  getSiteRulesAPI,
  getSportTypesListApi,
  updateSiteRulesAPI,
} from "../../../helpers/backend_helper";

export const getSportTypesList = createAsyncThunk(
  "rules/getSportTypesList",
  async () => {
    const response = await getSportTypesListApi();
    return response;
  }
);
export const getEventRule = createAsyncThunk(
  "sports/getEventRule",
  async (payload) => {
    const response = await getEventRuleAPI(payload);
    return response.data;
  }
);

export const createEventRules = createAsyncThunk(
  "sports/createEventRules",
  async (payload) => {
    const response = await createEventRulesAPI(payload);
    return response.data;
  }
);
export const addSiteRules = createAsyncThunk(
  "sitemanagement/rules/addSiteRules",
  async (payload) => {
    const response = await addSiteRulesAPI(payload);
    return response.data;
  }
);
export const getSiteRules = createAsyncThunk(
  "sitemanagement/rules/getSiteRules",
  async (payload) => {
    const response = await getSiteRulesAPI(payload);
    return response.data;
  }
);
export const updateSiteRules = createAsyncThunk(
  "sitemanagement/rules/updateSiteRules",
  async (payload) => {
    const response = await updateSiteRulesAPI(payload);
    return response.data;
  }
);
export const deleteSiteRules = createAsyncThunk(
  "sitemanagement/rules/deleteSiteRules",
  async (payload) => {
    const response = await deleteSiteRulesAPI(payload);

    return response.data;
  }
);
