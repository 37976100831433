import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getOpenBets,
  getBetHistory as getBetHistoryAPI,
  getSearchClients,
  getLiveCasinoReport as getLiveCasinoReportAPI,
  getAuraCasinoReport as getAuraCasinoReportAPI,
  voidOpenBetRequest as voidOpenBetRequestAPI,
  getSearchUserApi,
  // getDepositHistoryAPI,
  getSearchDepositApi,
  getAccountStatementApi,
  downloadAccountStatementApi,
  downloadWithdrawReportApi,
  downloadDepositReportApi,
  downloadBetsReportApi,
  getCasinoAnalysisReportAPI,
  downloadCasinoAnalysisApi,
  getVoidBets,
} from "../../helpers/backend_helper";

// Open Bets
export const getOpenBetsList = createAsyncThunk(
  "market/getOpenBetsList",
  async (payload) => {
    const response = await getOpenBets(payload);
    return response.data;
  }
);

// Void Bets
export const getVoidBetsList = createAsyncThunk(
  "market/getVoidBetsList",
  async (payload) => {
    const response = await getVoidBets(payload);
    return response.data;
  }
);

export const getBetHistory = createAsyncThunk(
  "market/getBetHistory",
  async (payload) => {
    const response = await getBetHistoryAPI(payload);
    return response.data;
  }
);

export const voidOpenBetRequest = createAsyncThunk(
  "Report/voidOpenBetRequest",
  async (payload) => {
    const response = await voidOpenBetRequestAPI(payload);
    return response;
  }
);

export const searchClients = createAsyncThunk(
  "market/getBetHistory",
  async (payload) => {
    const response = await getSearchClients(payload);
    return response.data;
  }
);

export const getLiveCasinoReport = createAsyncThunk(
  "market/getLiveCasinoReport",
  async (payload) => {
    const response = await getLiveCasinoReportAPI(payload);
    return response.data;
  }
);

export const getAuraCasinoReport = createAsyncThunk(
  "market/getAuraCasinoReport",
  async (payload) => {
    const response = await getAuraCasinoReportAPI(payload);
    return response.data;
  }
);

export const getCasinoAnalysisReport = createAsyncThunk(
  "market/getCasinoAnalysisReport",
  async (payload) => {
    const response = await getCasinoAnalysisReportAPI(payload);
    return response.data;
  }
);

export const searchWithdrawUsers = createAsyncThunk(
  "report/getWithdrawHistorysearch",
  async (payload) => {
    const response = await getSearchUserApi(payload);
    return response.data;
  }
);

export const downloadWithdrawReport = createAsyncThunk(
  "report/downloadWithdrawReport",
  async (payload) => {
    const response = await downloadWithdrawReportApi(
      payload.data,
      payload.type
    );
    return response.data;
  }
);

export const searchDepositUsers = createAsyncThunk(
  "report/getDepositHistorysearch",
  async (payload) => {
    const response = await getSearchDepositApi(payload);
    return response.data;
  }
);

export const downloadDepositReport = createAsyncThunk(
  "report/downloadDepositReport",
  async ({ data, type }) => {
    const response = await downloadDepositReportApi(data, type);
    return response.data;
  }
);

export const AccountStatementUsers = createAsyncThunk(
  "report/getAccountStatement",
  async (payload) => {
    const response = await getAccountStatementApi(payload);
    return response.data;
  }
);

export const DownloadAccountStatement = createAsyncThunk(
  "report/downloadAccountStatement",
  async ({ data, type }) => {
    const response = await downloadAccountStatementApi(data, type);
    return response.data;
  }
);

export const downloadBetsReport = createAsyncThunk(
  "report/downloadBetsReport",
  async ({ data, type }) => {
    const response = await downloadBetsReportApi(data, type);
    return response.data;
  }
);

export const downloadCasinoAnalysis = createAsyncThunk(
  "report/downloadCasinoAnalysis",
  async ({ data, type }) => {
    const response = await downloadCasinoAnalysisApi(data, type);
    return response.data;
  }
);

// export const createPaymentMode = createAsyncThunk('market/createPaymentMode', async (payload) => {
//   const response = await createPaymentModeAPI(payload);
//   return response.data;
// });

// export const updatePaymentMode = createAsyncThunk(
//   'market/updatePaymentMode',
//   async ({ id, update }) => {
//     const response = await updatePaymentModeAPI(id, update);
//     return response.data;
//   }
// );

// export const deletePaymentMode = createAsyncThunk('market/deletePaymentMode', async (payload) => {
//   const response = await deletePaymentModeAPI(payload);
//   return response.data;
// });
