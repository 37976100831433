import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Label, Input, Form, FormFeedback } from "reactstrap";
import { useDispatch } from "react-redux";
import { updateTransactionCode } from "../../../../store/user/action";
import { changeLoader } from "../../../../store/actions";

const ChangeTransactionCodeTab = ({ account, toggle }) => {
  const dispatch = useDispatch();
  const [transactionCode, setTransactionCode] = useState(false);
  const [cTransactionCode, setCTransactionCode] = useState(false);
  const [pPasswordShow, setPPasswordShow] = useState(false);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      newTransactionCode: "",
      confirmTransactionCode: "",
      transactionCode: "",
    },
    validationSchema: Yup.object({
      newTransactionCode: Yup.number()
        .test(
          "len",
          "Must be exactly 6 characters",
          (val) => val?.toString().length === 6
        )
        .required("Please Enter Transaction Code"),
      confirmTransactionCode: Yup.number()
        .oneOf(
          [Yup.ref("newTransactionCode"), null],
          "Transaction Codes must match"
        )
        .required("Please enter confirm Transaction Code"),
      transactionCode: Yup.number().required("Please Enter Transaction Code"),
    }),
    onSubmit: (values) => {
      dispatch(changeLoader(true));
      dispatch(updateTransactionCode({ ...values, ...{ userId: account._id } }))
        .then((a) => {
          if (a.payload) {
            dispatch(changeLoader(false));
            toggle();
          } else {
            dispatch(changeLoader(false));
          }
        })
        .catch((error) => dispatch(changeLoader(false)));
    },
  });

  return (
    <Form
      className="tablelist-form p-4"
      onSubmit={(e) => {
        e.preventDefault();
        validation.handleSubmit();
        return false;
      }}
    >
      <div className="mb-3">
        <Label htmlFor="newTransactionCode-field" className="form-label">
          New Transaction Code
        </Label>
        <div className="position-relative auth-pass-inputgroup mb-3">
          <Input
            name="newTransactionCode"
            id="newTransactionCode-field"
            className="form-control"
            placeholder="Transaction Code"
            type={transactionCode ? "text" : "password"}
            validate={{
              required: { value: true },
            }}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.newTransactionCode || ""}
            invalid={
              validation.touched.newTransactionCode &&
              validation.errors.newTransactionCode
                ? true
                : false
            }
          />
          {validation.touched.newTransactionCode &&
          validation.errors.newTransactionCode ? (
            <FormFeedback type="invalid">
              {validation.errors.newTransactionCode}
            </FormFeedback>
          ) : null}
          <button
            className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
            type="button"
            id="transactionCode"
            onClick={() => setTransactionCode(!transactionCode)}
          >
            <i className="ri-eye-fill align-middle"></i>
          </button>
        </div>
      </div>
      <div className="mb-3">
        <Label htmlFor="confirmTransactionCode-field" className="form-label">
          Confirm Transaction Code
        </Label>
        <div className="position-relative auth-pass-inputgroup mb-3">
          <Input
            name="confirmTransactionCode"
            id="confirmTransactionCode-field"
            className="form-control"
            placeholder="Transaction Code"
            type={cTransactionCode ? "text" : "password"}
            validate={{
              required: { value: true },
            }}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.confirmTransactionCode || ""}
            invalid={
              validation.touched.confirmTransactionCode &&
              validation.errors.confirmTransactionCode
                ? true
                : false
            }
          />
          {validation.touched.confirmTransactionCode &&
          validation.errors.confirmTransactionCode ? (
            <FormFeedback type="invalid">
              {validation.errors.confirmTransactionCode}
            </FormFeedback>
          ) : null}
          <button
            className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
            type="button"
            id="transactionCode-c"
            onClick={() => setCTransactionCode(!cTransactionCode)}
          >
            <i className="ri-eye-fill align-middle"></i>
          </button>
        </div>
      </div>
      <div className="mb-3">
        <Label htmlFor="transactionCode-field" className="form-label">
          Transaction Code
        </Label>
        <div className="position-relative auth-pass-inputgroup mb-3">
          <Input
            name="transactionCode"
            id="transactionCode-field"
            className="form-control"
            placeholder="Enter Code"
            type={pPasswordShow ? "text" : "password"}
            validate={{
              required: { value: true },
            }}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.transactionCode || ""}
            invalid={
              validation.touched.transactionCode &&
              validation.errors.transactionCode
                ? true
                : false
            }
          />
          {validation.touched.transactionCode &&
          validation.errors.transactionCode ? (
            <FormFeedback type="invalid">
              {validation.errors.transactionCode}
            </FormFeedback>
          ) : null}
          <button
            className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
            type="button"
            id="password-2fa"
            onClick={() => setPPasswordShow(!pPasswordShow)}
          >
            <i className="ri-eye-fill align-middle"></i>
          </button>
        </div>
      </div>

      <div className="hstack gap-2 justify-content-end">
        <button
          type="button"
          className="btn btn-light"
          onClick={() => {
            toggle();
          }}
        >
          Close
        </button>

        <button type="submit" className="btn btn-success">
          Update
        </button>
      </div>
    </Form>
  );
};

export default ChangeTransactionCodeTab;
