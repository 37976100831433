import { toast } from "react-toastify";
import {
  pendingDepositTotal as pendingDepositTotalAPI,
  getPendingDeposit as getPendingDepositAPI,
  getSignedImageURL as getSignedImageURLAPI,
  processPayment as processPaymentAPI,
  depositAmount as depositAmountAPI,
  withdrawalAmount as withdrawalAmountAPI,
  withdrawDetailAPI,
  withdrawUpdateStatusAPI,
  getVerifiedWithdrawAPI,
  getApprovedWithdrawAPI,
  getApprovedVerifiedAPI,
} from "../../helpers/backend_helper";

import { createAsyncThunk } from "@reduxjs/toolkit";

export const pendingDepositTotal = createAsyncThunk(
  "Payment/pendingDeposit",
  async (payload) => {
    const response = await pendingDepositTotalAPI();
    return response.data;
  }
);

export const getPendingDeposit = createAsyncThunk(
  "Payment/getPendingDeposit",
  async (payload) => {
    const response = await getPendingDepositAPI(payload);
    return response.data;
  }
);

export const getSignedImageURL = createAsyncThunk(
  "Payment/getSignedImageURL",
  async (payload) => {
    const response = await getSignedImageURLAPI(payload);
    return response.data;
  }
);

export const processPayment = createAsyncThunk(
  "Payment/processPayment",
  async (payload) => {
    const response = await processPaymentAPI(payload);
    return response.data;
  }
);

export const depositAmount = createAsyncThunk(
  "Payment/depositAmount",
  async (payload) => {
    const response = await depositAmountAPI(payload);
    return response.data;
  }
);

export const withdrawalAmount = createAsyncThunk(
  "Payment/withdrawalAmount",
  async (payload) => {
    const response = await withdrawalAmountAPI(payload);
    return response.data;
  }
);
export const getWithdrawDetails = createAsyncThunk(
  "withdraw/withdrawalDetails",
  async (payload) => {
    try {
      const response = await withdrawDetailAPI(payload);
      return response.data;
    } catch (error) {
      console.log(error);
    }
    return false;
  }
);
export const updateWithDrawStatus = createAsyncThunk(
  "withdraw/updateWithdrawalStatus",
  async (payload) => {
    const response = await withdrawUpdateStatusAPI(payload);
    return response.data;
  }
);

export const getVerifiedWithdrawList = createAsyncThunk(
  "withdraw/getWithdrawalVerifiedList",
  async (payload) => {
    try {
      const response = await getVerifiedWithdrawAPI(payload);
      return response.data;
    } catch (error) {
      console.log(error);
    }
    return false;
  }
);
export const getApprovedWithdrawal = createAsyncThunk(
  "account/getapprovedWithdrawList",
  async (payload) => {
    try {
      const response = await getApprovedWithdrawAPI(payload);
      return response.data;
    } catch (error) {
      console.log(error);
    }
    return false;
  }
);

export const getApprovedVerifiedDeposit = createAsyncThunk(
  "account/getpprovedVerifiedList",
  async (payload) => {
    try {
      const response = await getApprovedVerifiedAPI(payload);
      return response.data;
    } catch (error) {
      console.log(error);
    }
    return false;
  }
);
