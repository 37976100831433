import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  CardHeader,
  Button,
  Label,
  Form,
  UncontrolledTooltip,
} from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import {
  changeLoader,
  downloadCasinoAnalysis,
  getCasinoAnalysisReport,
} from "../../../store/actions";
import { useFormik } from "formik";
import * as Yup from "yup";
import DatePicker from "react-datepicker";
import {
  resetAuraCasinoData,
  resetCasinoAnalysisData,
} from "../../../store/reports/reducer";
import CasinoAnalysisReportTable from "./CasinoAnalysisReportTable";
import "react-datepicker/dist/react-datepicker.css";
import { getStartEndDayDate } from "../../../Components/constants/commonConstants";
import {
  csvBufferConvert,
  pdfBufferConvert,
} from "../../../helpers/methods/bufferConvert";

const CasinoAnalysis = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [sortOrder, setSortOrder] = useState("desc");
  const [sortField, setSortField] = useState("providerName");
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();

  const handlePerRowsChange = async (newPerPage, page) => {
    setLimit(newPerPage);
  };

  const handleSelection = () => false;

  const handleSort = (column, sortDirection) => {
    setSortField(column.fieldName);
    setSortOrder(String(sortDirection).toLowerCase());
  };
  const { data } = useSelector((state) => ({
    data: state.Report.casinoAnalysisReport.items,
  }));

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      startDate: startDate,
      endDate: endDate,
    },
    validationSchema: Yup.object().shape({
      startDate: Yup.date()
        .nullable()
        .test(
          "startDateTest",
          "Start Date must be less than End Date",
          function (value) {
            const { endDate } = this.parent;
            if (endDate && value) {
              return value <= endDate;
            }
            return true;
          }
        )
        .required("Start date is required"),
      endDate: Yup.date()
        .nullable()
        .test(
          "endDateTest",
          "End Date must be greater than Start Date",
          function (value) {
            const { startDate } = this.parent;
            if (startDate && value) {
              return value >= startDate;
            }
            return true;
          }
        )
        .required("End date is required"),
    }),
    onSubmit: (values) => {
      dispatch(changeLoader(true));

      const { sDate, eDate } = getStartEndDayDate(
        values.startDate,
        values.endDate
      );
      const payload = {
        startDate: sDate,
        endDate: eDate,
        page,
        limit,
        sortOrder,
        sortField,
      };

      dispatch(getCasinoAnalysisReport(payload))
        .then((e) => {
          dispatch(changeLoader(false));
        })
        .catch(() => dispatch(changeLoader(false)));
    },
  });

  useEffect(() => {
    dispatch(changeLoader(true));

    const { sDate, eDate } = getStartEndDayDate(
      validation.values.startDate,
      validation.values.endDate
    );
    const payload = {
      startDate: sDate,
      endDate: eDate,
      page,
      limit,
      sortOrder,
      sortField,
    };

    dispatch(getCasinoAnalysisReport(payload))
      .then((e) => {
        dispatch(changeLoader(false));
      })
      .catch(() => dispatch(changeLoader(false)));
  }, [dispatch]);

  const handleReset = () => {
    resetForm();
    dispatch(resetCasinoAnalysisData());
  };

  const resetForm = async () => {
    validation.setFieldValue("startDate", new Date());
    validation.setFieldValue("endDate", new Date());
    setStartDate(new Date());
    setEndDate(new Date());
  };

  document.title =
    "Reports | Casino Analysis Report | " + process.env.REACT_APP_NAME;

  const getSelectedDownloadFile = (type, fileData) => {
    dispatch(changeLoader(false));
    let blob;
    if (type === "csv" && fileData) {
      const uint8Array = csvBufferConvert(fileData.data);
      blob = new Blob([uint8Array], { type: "text/csv" });
    } else if (type === "pdf" && fileData) {
      const uint8Array = pdfBufferConvert(fileData.data);
      blob = new Blob([uint8Array], { type: "application/pdf" });
    } else if (type === "excel" && fileData) {
      const uint8Array = pdfBufferConvert(fileData.data);
      blob = new Blob([uint8Array], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
    }

    if (blob) {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download =
        type === "csv"
          ? "Deposit Report.csv"
          : type === "pdf"
          ? "Deposit Report.pdf"
          : "Deposit Report.xlsx";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }
  };

  const handleFileDownLoad = async (type) => {
    const { sDate, eDate } = getStartEndDayDate(
      validation.values.startDate,
      validation.values.endDate
    );
    dispatch(changeLoader(true));

    const data = {
      startDate: new Date(sDate),
      endDate: new Date(eDate),
    };

    dispatch(downloadCasinoAnalysis({ data, type })).then(async (res) => {
      getSelectedDownloadFile(type, res.payload);
    });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="Casino Analysis Report"
            pageTitle="Reports"
            pageSubTitle="Casino Analysis Report"
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader className="align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">
                    Casino Analysis Report
                  </h4>
                  <div className="d-flex gap-3">
                    <button
                      onClick={() => handleFileDownLoad("pdf")}
                      type="button"
                      id="pdf"
                      className="btn btn-outline-success btn-icon waves-effect waves-light"
                      disabled={data?.length ? false : true}
                    >
                      <i className="ri-file-pdf-line"></i>
                    </button>
                    <UncontrolledTooltip
                      placement="bottom"
                      target={`pdf`}
                      className="d-flex align-items-center gap-2"
                    >
                      <span>PDF </span>
                      <i className="ri-download-line"></i>
                    </UncontrolledTooltip>

                    <button
                      onClick={() => handleFileDownLoad("csv")}
                      type="button"
                      id="csv"
                      className="btn btn-outline-primary btn-icon waves-effect waves-light"
                      disabled={data?.length ? false : true}
                    >
                      <i className="ri-file-pdf-line"></i>
                    </button>
                    <UncontrolledTooltip
                      placement="bottom"
                      target={`csv`}
                      className="d-flex align-items-center gap-2"
                    >
                      <span>CSV</span>
                      <i className="ri-download-line"></i>
                    </UncontrolledTooltip>

                    <button
                      onClick={() => handleFileDownLoad("excel")}
                      type="button"
                      id="excel"
                      className="btn btn-outline-secondary btn-icon waves-effect waves-light"
                      disabled={data?.length ? false : true}
                    >
                      <i className="ri-file-pdf-line"></i>
                    </button>
                    <UncontrolledTooltip
                      placement="bottom"
                      target={`excel`}
                      className="d-flex align-items-center gap-2"
                    >
                      <span>EXCEL</span>
                      <i className="ri-download-line"></i>
                    </UncontrolledTooltip>
                  </div>
                </CardHeader>
                <CardHeader className="align-items-center ">
                  <Form
                    className="needs-validation"
                    onSubmit={async (e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    <Row>
                      <Col lg={2} md={4} sm={6}>
                        <Label className="form-label pe-2">From Date</Label>
                        <div className="customDatePickerWidth">
                          <DatePicker
                            dateFormat="dd/MM/yyyy"
                            selected={validation.values.startDate}
                            onChange={(date) => {
                              validation.setFieldValue("startDate", date);
                            }}
                            className=" p-2 form-control"
                            name="startDate"
                            validate={{
                              required: { defaultValue: true },
                            }}
                            invalid={validation.errors.startDate ? true : false}
                          />
                        </div>
                        {validation.errors.startDate ? (
                          <div
                            className="dropDown-error text-danger"
                            type="invalid"
                          >
                            {validation.errors.startDate}
                          </div>
                        ) : null}
                      </Col>
                      <Col lg={2} md={4} sm={6}>
                        <Label className="form-label pe-2">To Date</Label>
                        <div className="customDatePickerWidth">
                          <DatePicker
                            // minDate={new Date()}
                            dateFormat="dd/MM/yyyy"
                            className=" p-2 form-control"
                            selected={validation.values.endDate}
                            onChange={(date) => {
                              validation.setFieldValue("endDate", date);
                            }}
                            validate={{
                              required: { defaultValue: true },
                            }}
                            name="endDate"
                            invalid={validation.errors.endDate ? true : false}
                          />
                        </div>
                        {validation.errors.endDate ? (
                          <div
                            className="dropDown-error text-danger"
                            type="invalid"
                          >
                            {validation.errors.endDate}
                          </div>
                        ) : null}
                      </Col>
                    </Row>

                    <Button
                      color="primary"
                      type="submit"
                      className="m-2 rounded-pill float-end mt-1"
                    >
                      Search
                    </Button>
                    <Button
                      color="info"
                      type="button"
                      onClick={handleReset}
                      className="m-2 rounded-pill float-end mt-1"
                    >
                      Reset
                    </Button>
                  </Form>
                </CardHeader>

                <CardBody>
                  <div id="table-loading-state">
                    <CasinoAnalysisReportTable
                      handlePerRowsChange={handlePerRowsChange}
                      handlePageChange={setPage}
                      handleSort={handleSort}
                      handleSelection={handleSelection}
                      data={data}
                      totalRows={data?.length}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CasinoAnalysis;
