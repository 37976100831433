import { combineReducers } from "redux";

// Front
import Layout from "./layouts/reducer";

// Authentication
import Login from "./auth/login/reducer";
// import ForgetPassword from './auth/forgetpwd/reducer';

// Dashboard
import Dashboard from "./Dashboard/reducer";

// Role
import Role from "./administration/roles/reducer";
import Admins from "./administration/admins/reducer";

//User
import User from "./user/reducer";

import Sports from "./sports/sportType/reducer";
import Compititions from "./sports/compititions/reducer";
import Events from "./sports/events/reducer";
import Market from "./sports/market/reducer";

//Site Management
import BlacklistedIps from "./siteManagement/blacklistedIps/reducer";
import PaymentMode from "./siteManagement/paymentMode/reducer";
import StakeButtons from "./siteManagement/stakeButtons/reducer";
import LimitSetting from "./siteManagement/limitSetting/reducer";
import Casinos from "./siteManagement/casinos/reducer";
import AuraCasinos from "./siteManagement/auraCasinos/reducer";
import Rules from "./siteManagement/rules/reducer";
// Payment
import Payment from "./payment/reducer";

// Report
import Report from "./reports/reducer";

//Analysis
import Analysis from "./analysis/reducer";
import Settings from "./siteManagement/generalSettings/reducer";

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Dashboard,
  Role,
  Admins,
  User,
  Sports,
  Compititions,
  Events,
  Market,
  PaymentMode,
  StakeButtons,
  LimitSetting,
  Payment,
  Report,
  Analysis,
  Casinos,
  AuraCasinos,
  Rules,
  BlacklistedIps,
  Settings,
});

export default rootReducer;
