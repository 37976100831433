import { APIHelper } from "../../api_helper";
import * as url from "../../url_helper";

/*
    SPORT
*/

// SPORT TYPE
export const getSportTypes = (data) => APIHelper.get(url.GET_SPORT_TYPES, data);
export const updateSportType = (eventTypeId, data) =>
  APIHelper.put(url.PUT_SPORT_TYPE_UPDATE(eventTypeId), data);
export const createEventRulesAPI = ({ eventTypeReferenceId, value }) =>
  APIHelper.put(url.CREATE_EVENT_RULES(eventTypeReferenceId), value);
export const getEventRuleAPI = (data) =>
  APIHelper.get(url.GET_EVENT_RULE(data));

// COMPITIONS
export const getCompititions = (data, params) =>
  APIHelper.post(url.GET_COMPITITION_LIST, data, params);

// EVENTS
export const getEvents = (data, params) =>
  APIHelper.post(url.GET_EVENTS_LIST, data, params);
export const importEvent = (data) =>
  APIHelper.post(url.POST_EVENT_IMPORT, data);
export const eventBetLock = (data) => APIHelper.post(url.POST_EVENTS_BETLOCK, data);

// MARKET
export const getMarketList = (data, params) =>
  APIHelper.post(url.GET_MARKET_LIST, data, params);
export const marketStatusApi = (data) =>
  APIHelper.get(url.GET_MARKET_STATUS(data));
export const refreshMarket = (data) =>
  APIHelper.post(url.POST_MARKET_REFRESH, data);
export const updateMarketStatus = (data) =>
  APIHelper.post(url.POST_MARKET_STATUS, data);
