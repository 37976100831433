import { APIHelper } from "../../api_helper";
import * as url from "../../url_helper";

// Deposit
export const pendingDepositTotal = () => APIHelper.get(url.GET_PENDING_DEPOSIT);
export const getPendingDeposit = (payload) => APIHelper.get(url.GET_PENDING_DEPOSIT_LIST, payload);
export const getSignedImageURL = (payload) => APIHelper.post(url.POST_SIGNED_IMAGE_URL, payload);
export const processPayment = (payload) => APIHelper.post(url.POST_PROCESS_PAYMENT, payload);

export const depositAmount = (payload) => APIHelper.post(url.POST_DEPOSIT_AMOUNT, payload);
export const withdrawalAmount = (payload) => APIHelper.post(url.POST_WITHDRAWAL_AMOUNT, payload);
export const withdrawDetailAPI = (payload) => APIHelper.get(url.GET_WITHDRAWAL_DETAILS, payload);
export const withdrawUpdateStatusAPI = (payload) =>
  APIHelper.put(url.UPDATE_WITHDRAWAL_STATUS, payload);

export const getVerifiedWithdrawAPI = (payload) =>
  APIHelper.get(url.GET_VERIFIED_WITHDRAWAL, payload);
export const getApprovedWithdrawAPI = (payload) =>
  APIHelper.get(url.GET_APPROVED_WITHDRAWAL, payload);
export const getApprovedVerifiedAPI = (payload) =>
  APIHelper.get(url.GET_APPROVED_VERIFIED_DEPOSIT, payload);
