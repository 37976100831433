import { toast } from "react-toastify";
import {
  addAdminApi,
  deleteAdminApi,
  getAdminListApi,
  updateAdminApi,
  getAdminTypes as getAdminTypesAPI,
  updateUserLockApi,
  addSubAdminApi,
  getSubAdminListApi,
  updateSubAdminApi,
  deleteSubAdminApi,
  updateSubAdminPasswordApi,
  downloadAdminListPDFApi,
  downloadAdminListCSVApi,
  downloadAdminListEXCELApi,
  downloadAdminListReportApi,
  checkUserMobileApi,
} from "../../../helpers/backend_helper";

import { createAsyncThunk } from "@reduxjs/toolkit";

export const updateUserLock = createAsyncThunk("user/account/updateLock", async (data) => {
  const response = await updateUserLockApi(data);
  return response.data;
});

export const getAdminTypes = createAsyncThunk("Admins/getAdminTypes", async (payload) => {
  const response = await getAdminTypesAPI(payload);
  return response.data;
});

export const getAdminList = createAsyncThunk("Admins/getAdminList", async (payload) => {
  const response = await getAdminListApi(payload);
  return response.data;
});
export const downloadAdminListPDF = createAsyncThunk(
  "Admins/downloadAdminListPDF",
  async (payload) => {
    const response = await downloadAdminListPDFApi(payload);
    return response;
  }
);
export const downloadAdminListEXCEL = createAsyncThunk(
  "Admins/downloadAdminListEXCEL",
  async (payload) => {
    const response = await downloadAdminListEXCELApi(payload);
    return response.data;
  }
);
export const downloadAdminListCSVFile = createAsyncThunk(
  "Admins/downloadAdminListCSV",
  async (payload) => {
    const response = await downloadAdminListCSVApi(payload);
    return response?.data;
  }
);

export const downloadAdminListReportFile = createAsyncThunk(
  "Admins/downloadAdminListCSV",
  async ({ data, type }) => {
    const response = await downloadAdminListReportApi(data, type);
    return response?.data;
  }
);

export const addNewAdmin = createAsyncThunk("Admins/addNewAdmin", async (payload) => {
  const response = await addAdminApi(payload.create);
  return response.data;
});

export const checkUserMobile = createAsyncThunk("Admins/checkUserMobile", async (payload) => {
  const response = await checkUserMobileApi(payload);
  return response.data;
});

export const deleteAdmin = createAsyncThunk("Admins/deleteAdmin", async (data) => {
  try {
    if (!("adminIds" in data)) throw new Error("Admin id not selected");
    await deleteAdminApi(data.adminIds);
    delete data.adminIds;
    return true;
  } catch (error) {
    // toast.error("Failed to delete admin" );
    return false;
  }
});

export const updateAdminDetail = createAsyncThunk("Admins/updateAdminDetail", async (data) => {
  try {
    if (!("update" in data)) return;
    await updateAdminApi(data.update);
    delete data.update;
    // toast.success("Admin Update Successfully" );
    return true;
  } catch (error) {
    // toast.error("Failed to Update admin" );
    return false;
  }
});

// SUB-ADMIN

export const addNewSubAdmin = createAsyncThunk("Admins/addNewSubAdmin", async (payload) => {
  const response = await addSubAdminApi(payload.create);
  return response.data;
});

export const getSubAdminList = createAsyncThunk("Admins/getSubAdminList", async (payload) => {
  const response = await getSubAdminListApi(payload);
  return response.data;
});

export const updateSubAdminDetail = createAsyncThunk("Admins/updateAdminDetail", async (data) => {
  let res = await updateSubAdminApi(data.update);

  return res;
});

export const deleteSubAdmin = createAsyncThunk("Admins/deleteAdmin", async (data) => {
  return await deleteSubAdminApi(data.subAdminIds);
});

export const updateSubAdminPassword = createAsyncThunk(
  "user/account/updatePassword",
  async (data) => {
    return await updateSubAdminPasswordApi(data);
  }
);
