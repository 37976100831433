import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getGeneralSettingsApi,
  updateGeneralSettingsApi,
} from "../../../helpers/backend_helper";

export const getGeneralSettings = createAsyncThunk("market/getGeneralSettings", async (data) => {
  const response = await getGeneralSettingsApi(data);
  return response.data;
});

export const updateGeneralSettings = createAsyncThunk("market/updateGeneralSettings", async (data) => {
  const response = await updateGeneralSettingsApi(data);
  return response.data;
});