import React, { useEffect, useLayoutEffect } from "react";

//import Scss
import "./assets/scss/themes.scss";
import "react-datepicker/dist/react-datepicker.css";

//imoprt Route
import Route from "./Routes";
import { encrypt } from "./helpers/methods/encryptDecrypt";
import { useDispatch } from "react-redux";
import { getIPAction } from "./store/actions";
import { updateHeader } from "./helpers/api_helper";
import { ClientJS } from "clientjs";
import { api } from "./config";
import { publicIpv4 } from "public-ip";

function App() {
  const dispatch = useDispatch();
  const client = new ClientJS();

  // useEffect(() => {
  //   dispatch(getIPAction()).then((e) => {
  //     const encryptedData = encrypt(e.payload);
  //     updateHeader(e.payload);
  //     localStorage.setItem("_GE_IED_", encryptedData);
  //   });
  // }, []);

  useLayoutEffect(() => {
    const geoData = localStorage.getItem("_GE_IED_");
    if (!geoData) {
      const browser = client.getBrowser();
      const browserVersion = client.getBrowserVersion();
      const fingerprint = client.getFingerprint();
      const isMobile = client.isMobile();

      publicIpv4({
        fallbackUrls: [api.IP_URL, api.IP2_URL],
      }).then(async (e) => {
        const data = {
          ip: e,
          browser,
          browserVersion,
          fingerprint,
          deviceType: isMobile ? "MOBILE" : "WEB",
        };
        const geoData = encrypt(data);

        await localStorage.setItem("_GE_IED_", geoData);
        updateHeader(data);
      });
    }
  }, []);

  useEffect(() => {
    const handleStorageChange = () => {
      if (localStorage.getItem("authUser")) {
        window.location.reload();
      } else {
        window.location = "/";
      }
    };
    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  return (
    <React.Fragment>
      <Route />
    </React.Fragment>
  );
}

export default App;
