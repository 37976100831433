import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getIP,
  postLogin,
  resetUserPassword,
  loginVerify2FA as verify2FA,
} from "../../../helpers/backend_helper";
import { logoutUserSuccess } from "./reducer";
import { toast } from "react-toastify";

export const getIPAction = createAsyncThunk("Common/getIP", async () => {
  return await getIP();
});

export const logoutUser = createAsyncThunk("login/logoutUser", async (payload, { dispatch }) => {
  dispatch(logoutUserSuccess());
  return "logout successful";
});

export const resetLoginFlag = createAsyncThunk("login/resetLoginFlag", () => {
  return true;
});

export const loginUser = createAsyncThunk("login/loginUser", async ({ user, history }) => {
  try {
    const userData = {
      username: user.username,
      password: user.password,
    };
    const response = await postLogin(userData);
    if (response.status === "success") {
      if (response.data.enable2FA) {
        history("/verify/twostep");
        return userData;
      } else {
        if (response.data.isNewUser) {
          history("/auth-pass-create-new", {
            state: user.username,
          });
        } else {
          console.log(response.data, "------data");
          localStorage.setItem("authUser", JSON.stringify(response.data));
          localStorage.setItem("refreshCounter", 3);
          history("/dashboard");
        }

        return response.data;
      }
    } else {
      throw new Error(response);
    }
  } catch (error) {
    console.log("Error", error);
    throw error;
  }
});

export const loginVerify2FA = createAsyncThunk(
  "login/loginVerify2FA",
  async ({ request, history }) => {
    try {
      const response = await verify2FA({
        code: request.code,
        username: request.username,
        password: request.password,
      });
      if (response.status === "success") {
        localStorage.setItem("refreshCounter", 3);
        localStorage.setItem("authUser", JSON.stringify(response.data));
        history("/dashboard");
        return response.data;
      } else {
        toast.error("Invalid 2FA Code");
        throw new Error(response);
      }
    } catch (error) {
      toast.error("Invalid 2FA Code");
      console.log("Error", error);
      throw error;
    }
  }
);

export const resetPasswordAction = createAsyncThunk(
  "login/resetPassword",
  async ({ resetPasswordData, history }) => {
    try {
      const response = await resetUserPassword(resetPasswordData);
      return response.data;
    } catch (error) {
      console.log("Error", error);
      throw error;
    }
  }
);
