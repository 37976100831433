import React, { useEffect } from "react";
import { Col, Card, CardBody, CardHeader } from "reactstrap";
import ReactApexChart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import { changeLoader, getPLGraphData } from "../../store/actions";

const ProfitLossChart = ({ dataColors }) => {
  const dispatch = useDispatch();
  const { plData } = useSelector((state) => ({
    plData: state.Dashboard.plData,
  }));

  useEffect(() => {
    dispatch(changeLoader(true));
    dispatch(getPLGraphData())
      .then((res) => {
        dispatch(changeLoader(false));
      })
      .catch((err) => {
        dispatch(changeLoader(false));
      });
  }, []);

  const series = [
    {
      name: "P/L",
      data: [
        { x: "Sports P/L", y: plData?.sportsPL || 0 },
        { x: "Casino P/L", y: plData?.casinoPL || 0 },
        { x: "Indian Casino P/L", y: plData?.auraCasinoPL || 0 },
        {
          x: "Total P/L",
          y:
            (plData?.sportsPL || 0) +
            (plData?.casinoPL || 0) +
            (plData?.auraCasinoPL || 0),
        },
      ],
    },
  ];

  const options = {
    chart: {
      type: "bar",
      height: 650,
      toolbar: {
        show: true,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: "50%",
        barWidth: "80%",
        colors: {
          ranges: [
            { from: -999999999999999, to: 0, color: "#f56e6e" },
            { from: 1, to: 999999999999999, color: "#34c38f" },
          ],
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: (val) => val.toLocaleString(),
    },
    xaxis: {
      labels: {
        formatter: (val) => val.toLocaleString(),
      },
      tooltip: {
        enabled: true,
      },
    },
    yaxis: {
      labels: {
        formatter: (val) => val,
        tooltip: {
          enabled: true,
        },
      },
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: "center",
    },
    tooltip: {
      y: {
        formatter: (val) => val.toLocaleString(),
      },
    },
    annotations: {
      xaxis: [
        {
          x: "Sports P/L",
          borderColor: "#999",
          offsetY: 20,
          label: {
            borderColor: "#999",
            style: {
              fontSize: "12px",
              color: "#fff",
              background: "#333",
            },
            text: `Sports P/L: ${plData?.sportsPL || 0}`,
          },
        },
        {
          x: "Casino P/L",
          borderColor: "#999",
          offsetY: 40,
          label: {
            borderColor: "#999",
            style: {
              fontSize: "12px",
              color: "#fff",
              background: "#333",
            },
            text: `Casino P/L: ${plData?.casinoPL || 0}`,
          },
        },
        {
          x: "Indian Casino P/L",
          borderColor: "#999",
          offsetY: 60,
          label: {
            borderColor: "#999",
            style: {
              fontSize: "12px",
              color: "#fff",
              background: "#333",
            },
            text: `Indian Casino P/L: ${plData?.auraCasinoPL || 0}`,
          },
        },
        {
          x: "Total P/L",
          borderColor: "#999",
          offsetY: 80,
          label: {
            borderColor: "#999",
            style: {
              fontSize: "12px",
              color: "#fff",
              background: "#333",
            },
            text: `Total P/L: ${
              plData?.sportsPL + plData?.casinoPL + plData?.auraCasinoPL || 0
            }`,
          },
        },
      ],
    },
  };

  return (
    <React.Fragment>
      <Col xl={6}>
        <Card className="card-height-100">
          <CardHeader className="align-items-center d-flex">
            <h4 className="card-title mb-0 flex-grow-1">
              Profit/Loss Analysis
            </h4>
          </CardHeader>
          <CardBody className="p-0 pb-2">
            <div className="w-100">
              <ReactApexChart
                options={options}
                series={series}
                type="bar"
                height="400"
                className="apex-charts"
              />
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default ProfitLossChart;
