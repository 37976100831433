import { createSlice } from '@reduxjs/toolkit';
import { getCompititions } from './action';
import { toast } from 'react-toastify';

const initialState = {
  compititions: {},
  totalRows: 0,
};

const Compititions = createSlice({
  name: 'compititions',
  initialState,
  reducers: {},
  extraReducers: (b) => {
    b.addCase(getCompititions.fulfilled, (state, action) => {
      state.compititions = action.payload.items ? action.payload.items : [];
      state.totalRows = action.payload.total ? action.payload.total : 0;
    });

  },
});

export default Compititions.reducer;
