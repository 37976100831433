import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getPaymentMode,
  createPaymentMode as createPaymentModeAPI,
  updatePaymentMode as updatePaymentModeAPI,
  deletePaymentMode as deletePaymentModeAPI,
} from '../../../helpers/backend_helper';

// StakeBUttons
export const getPaymentModeList = createAsyncThunk('market/getPaymentModeList', async (payload) => {
  const response = await getPaymentMode(payload);
  return response.data;
});

export const createPaymentMode = createAsyncThunk('market/createPaymentMode', async (payload) => {
  const response = await createPaymentModeAPI(payload);
  return response.data;
});

export const updatePaymentMode = createAsyncThunk('market/updatePaymentMode', async ({id, update}) => {
  const response = await updatePaymentModeAPI(id, update);
  return response.data;
});

export const deletePaymentMode = createAsyncThunk('market/deletePaymentMode', async (payload) => {
  const response = await deletePaymentModeAPI(payload);
  return response.data;
});
