//constants
import {
  layoutTypes,
  leftSidebarTypes,
  layoutModeTypes,
  layoutWidthTypes,
  layoutPositionTypes,
  topbarThemeTypes,
  leftsidbarSizeTypes,
  leftSidebarViewTypes,
  leftSidebarImageTypes,
} from "../../Components/constants/layout";
import { createSlice } from "@reduxjs/toolkit";

import { changeLayoutMode, changeLoader } from "./action";

const initialState = {
  layoutType: layoutTypes.VERTICAL,
  leftSidebarType: leftSidebarTypes.DARK,
  layoutModeType:
    localStorage.getItem("layoutModeType") || layoutModeTypes.LIGHTMODE,
  layoutWidthType: layoutWidthTypes.FLUID,
  layoutPositionType: layoutPositionTypes.FIXED,
  topbarThemeType: topbarThemeTypes.LIGHT,
  leftsidbarSizeType: leftsidbarSizeTypes.DEFAULT,
  leftSidebarViewType: leftSidebarViewTypes.DEFAULT,
  leftSidebarImageType: leftSidebarImageTypes.NONE,
  loader: false,
};

const Layout = createSlice({
  name: "Layout",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(changeLoader.fulfilled, (state, action) => {
        state.loader = action.payload;
      })
      .addCase(changeLayoutMode.fulfilled, (state, action) => {
        state.layoutModeType = action.payload;
      });
  },
});
export default Layout.reducer;
