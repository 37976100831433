import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Label, Input, Form, FormFeedback, Col } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { updateUserLock } from "../../../../store/administration/admins/action";
import { toast } from "react-toastify";
import { changeLoader } from "../../../../store/actions";

const UserLockTab = ({ account, toggle }) => {
  const dispatch = useDispatch();

  const [pPasswordShow, setPPasswordShow] = useState(false);

  const { modules } = useSelector((state) => ({
    modules: state.User.modules,
  }));

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      isActive: account?.isActive || false,
      isBetLock: account?.isBetLock || false,
      isCasinoLock: account.isCasinoLock || false,
      transactionCode: "",
    },
    validationSchema: Yup.object({
      transactionCode: Yup.number().required("Please Enter Transaction Code"),
    }),
    onSubmit: (values) => {
      dispatch(changeLoader(true));

      dispatch(updateUserLock({ ...values, ...{ userId: account._id } }))
        .then((a) => {
          dispatch(changeLoader(false));
          if (a.payload) {
            toggle();
          }
        })
        .catch(() => dispatch(changeLoader(false)));
    },
  });

  return (
    <Form
      className="tablelist-form p-4 w-50"
      onSubmit={(e) => {
        e.preventDefault();
        validation.handleSubmit();
        return false;
      }}
    >
      {modules?.["account-profile-user-lock-button"] ? (
        <div className="mb-3">
          <div className="d-flex  align-item-center justify-content-start gap-3">
            <div className="form-check form-switch form-switch-right form-switch-md">
              <Input
                className="form-check-input ms-2"
                type="checkbox"
                name="isActive"
                id="user-lock"
                onChange={(e) => {
                  validation.setFieldValue(
                    "isActive",
                    e.target.checked ? false : true
                  );
                }}
                checked={!validation.values.isActive}
              />
            </div>
            <Label htmlFor="user-lock" className="form-label">
              User Lock
            </Label>
          </div>
        </div>
      ) : (
        ""
      )}

      {modules?.["account-profile-bet-lock-button"] ? (
        <div className="d-flex  align-item-center justify-content-start mb-3 gap-3">
          <div className="form-check form-switch form-switch-right form-switch-md">
            <Input
              className="form-check-input ms-2"
              type="checkbox"
              name="isBetlock"
              id="bet-lock"
              onChange={(e) => {
                validation.setFieldValue(
                  "isBetLock",
                  e.target.checked ? true : false
                );
              }}
              checked={validation.values.isBetLock}
            />
          </div>
          <Label htmlFor="bet-lock" className="form-label">
            Bet Lock
          </Label>
        </div>
      ) : (
        ""
      )}

      <div className="d-flex  align-item-center justify-content-start mb-3 gap-3">
        <div className="form-check form-switch form-switch-right form-switch-md">
          <Input
            className="form-check-input ms-2"
            type="checkbox"
            name="isCasinoLock"
            id="casino-lock"
            onChange={(e) => {
              validation.setFieldValue(
                "isCasinoLock",
                e.target.checked ? true : false
              );
            }}
            checked={validation.values.isCasinoLock}
          />
        </div>
        <Label htmlFor="bet-lock" className="form-label">
          Casino Lock
        </Label>
      </div>

      <div className="mb-3">
        <Label htmlFor="transactionCode-field" className="form-label">
          Transaction Code
        </Label>
        <div className="position-relative auth-pass-inputgroup mb-3">
          <Input
            name="transactionCode"
            id="transactionCode-field"
            className="form-control"
            placeholder="Enter Code"
            type={pPasswordShow ? "text" : "password"}
            validate={{
              required: { value: true },
            }}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.transactionCode || ""}
            invalid={
              validation.touched.transactionCode &&
              validation.errors.transactionCode
                ? true
                : false
            }
          />
          {validation.touched.transactionCode &&
          validation.errors.transactionCode ? (
            <FormFeedback type="invalid">
              {validation.errors.transactionCode}
            </FormFeedback>
          ) : null}
          <button
            className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
            type="button"
            id="password-2fa"
            onClick={() => setPPasswordShow(!pPasswordShow)}
          >
            <i className="ri-eye-fill align-middle"></i>
          </button>
        </div>
      </div>

      <div className="hstack gap-2 justify-content-end">
        <button
          type="button"
          className="btn btn-light"
          onClick={() => {
            toggle();
          }}
        >
          Close
        </button>

        <button type="submit" className="btn btn-success">
          Update
        </button>
      </div>
    </Form>
  );
};

export default UserLockTab;
