import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  CardHeader,
  Button,
} from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import {
  changeLoader,
  getAuraCasinoList,
  updateAuraCasino,
  changeAuraCasinoGameStatus,
  createAuraCasino,
  deleteAuraCasino,
} from "../../../store/actions";
import { toast } from "react-toastify";
import DeleteModal from "../../../Components/Common/DeleteModal";
import { debounce } from "lodash";
import AuraCasinoForm from "./AuraCasinoForm";
import AuraCasinoListTable from "./AuraCasinoListTable";

const AuraCasinos = () => {
  const dispatch = useDispatch();

  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [sortField, setSortField] = useState("createdAt");
  const [sortOrder, setSortOrder] = useState("desc");
  const [selectedIds, setSelectedIds] = useState([]);

  const [modal, setModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [auraCasino, setAuraCasino] = useState(null);
  const [search, setSearchQuery] = useState("");
  const [searchVal, setSearchValQuery] = useState("");

  const { auraCasinos, totalRows, modules } = useSelector((state) => ({
    auraCasinos: state.AuraCasinos.auraCasinos.data,
    totalRows: state.AuraCasinos.auraCasinos.totalRows,
    modules: state.User.modules,
  }));

  const refreshData = () => {
    dispatch(
      getAuraCasinoList({ search, page, limit, sortOrder, sortField })
    ).then((a) => {
      dispatch(changeLoader(false));
      setSelectedIds([]);
    });
  };

  useEffect(() => {
    dispatch(changeLoader(true));
    refreshData();
  }, [page, limit, search, sortOrder, sortField]);

  const handlePerRowsChange = async (newPerPage, page) => setLimit(newPerPage);

  const handleSort = (column, sortDirection) => {
    setSortField(column.fieldName);
    setSortOrder(String(sortDirection).toLowerCase());
  };

  const handleSelection = (e) => {
    setSelectedIds(e.selectedRows.map((x) => x._id));
  };

  const handleDelete = () => {
    dispatch(changeLoader(true));
    dispatch(deleteAuraCasino(selectedIds)).then((a) => {
      refreshData();
      setDeleteModal(false);
      dispatch(changeLoader(false));
    });
  };

  const deleteMultiple = (e) => {
    if (selectedIds?.length) setDeleteModal(true);
    else toast.info("No selected row found");
  };

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setAuraCasino(null);
    } else setModal(true);
  }, [modal]);

  const updateAuraCasinos = (id, update, tggl) => {
    dispatch(changeLoader(true));
    dispatch(updateAuraCasino({ id, update })).then((a) => {
      if (tggl) toggle();
      refreshData();
      dispatch(changeLoader(false));
    });
  };

  const updateAuraCasinoGameStatus = (id, update) => {
    dispatch(changeAuraCasinoGameStatus({ id, update }))
      .then((a) => {
        refreshData();
      })
      .catch((error) => {
        dispatch(changeLoader(false));
      });
  };

  const addAuraCasino = (create) => {
    dispatch(changeLoader(true));
    dispatch(createAuraCasino(create)).then((a) => {
      if (a.payload) toggle();
      refreshData();
      dispatch(changeLoader(false));
      setSelectedIds([]);
    });
  };

  const onCloseDeleteModal = () => {
    setDeleteModal(false);
    setSelectedIds([]);
  };

  const debouncedGetAuraCasinos = useCallback(
    debounce((query) => {
      if (
        String(query.search).length > 2 ||
        String(query.search).length === 0
      ) {
        setPage(1);
        setSearchQuery(query.search);
      }
    }, 500),
    [dispatch]
  );

  const handleSearchChange = useCallback(
    (event) => {
      setSearchValQuery(event.target.value);
      debouncedGetAuraCasinos({
        search: event.target.value,
      });
    },
    [debouncedGetAuraCasinos]
  );

  document.title = "Casinos | " + process.env.REACT_APP_NAME;

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Aura Casinos" pageTitle="Site Management" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader className="align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">Aura Casinos</h4>
                </CardHeader>

                <CardBody>
                  <div id="table-loading-state">
                    <Row className="g-4 mb-3">
                      <Col className="col-sm-auto">
                        <div>
                          {modules?.[
                            "site-management-add-aura-casinos-button"
                          ] == 1 && (
                            <Button
                              color="success"
                              className="add-btn me-1 rounded-pill "
                              onClick={() => setModal(true)}
                              id="create-btn"
                            >
                              <i className="ri-add-line align-bottom me-1"></i>
                              Add
                            </Button>
                          )}
                          {modules?.[
                            "site-management-delete-aura-casinos-button"
                          ] == 1 && (
                            <Button
                              className="btn btn-soft-danger rounded-pill"
                              onClick={deleteMultiple}
                            >
                              <i className="ri-delete-bin-2-line"></i>
                            </Button>
                          )}
                        </div>
                      </Col>
                      <Col className="col-sm">
                        <div className="d-flex justify-content-sm-end">
                          <div className="search-box ms-2">
                            <input
                              type="text"
                              className="form-control search"
                              placeholder="Search..."
                              value={searchVal}
                              onChange={handleSearchChange}
                            />
                            <i className="ri-search-line search-icon"></i>
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <AuraCasinoListTable
                      data={auraCasinos}
                      totalRows={totalRows}
                      handlePerRowsChange={handlePerRowsChange}
                      handlePageChange={setPage}
                      handleSort={handleSort}
                      handleSelection={handleSelection}
                      handleDelete={handleDelete.bind(null)}
                      setModal={setModal}
                      setAuraCasino={setAuraCasino}
                      setSelectedIds={setSelectedIds}
                      setDeleteModal={setDeleteModal}
                      updateAuraCasino={updateAuraCasino}
                      updateAuraCasinoGameStatus={updateAuraCasinoGameStatus}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <AuraCasinoForm
            setModal={setModal.bind(null)}
            isOpen={modal}
            toggle={toggle.bind(null)}
            auraCasino={auraCasino}
            setAuraCasino={setAuraCasino}
            updateAuraCasino={updateAuraCasinos.bind(null)}
            addAuraCasino={addAuraCasino.bind(null)}
          />

          <DeleteModal
            show={deleteModal}
            onDeleteClick={() => handleDelete(selectedIds)}
            onCloseClick={onCloseDeleteModal}
          />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AuraCasinos;
