import { createSlice } from "@reduxjs/toolkit";
import {
  getCreditGraphData,
  getDepositsData,
  getPLGraphData,
  getRevenueChartsData,
  getWithdrawalsData,
} from "./action";

const initialState = {
  revenueData: [],
  error: {},
  deposits: {
    data: {},
    topUserDeposits: [],
  },
  withdrawals: {
    data: {},
    topUserwithdrawals: [],
  },
  plData: {},
  creditData: {},
};

const Dashboard = createSlice({
  name: "Dashboard",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getRevenueChartsData.fulfilled, (state, action) => {
      state.loading = false;
      state.revenueData = action.payload;
    });
    builder.addCase(getRevenueChartsData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
      state.errorMsg = action.error.message;
    });

    builder.addCase(getDepositsData.fulfilled, (state, action) => {
      return {
        ...state,
        deposits: {
          ...state.deposits,
          data: action?.payload?.depositData[0],
          topUserDeposits: action?.payload?.topUserDeposits,
        },
      };
    });

    builder.addCase(getWithdrawalsData.fulfilled, (state, action) => {
      return {
        ...state,
        withdrawals: {
          ...state.withdrawals,
          data: action.payload?.withdrawalData?.[0] || {},
          topUserWithdrawals: action?.payload?.topUserWithdrawals || [],
        },
      };
    });

    builder.addCase(getPLGraphData.fulfilled, (state, action) => {
      state.plData = action.payload;
    });

    builder.addCase(getCreditGraphData.fulfilled, (state, action) => {
      state.creditData = action.payload;
    });
  },
});

export default Dashboard.reducer;
