import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";

const BreadCrumb = ({
  title,
  pageTitle,
  pageSubTitle,
  subPage,
  backLink = "",
}) => {
  const [subTitles, setSubTitles] = useState([]);
  useEffect(() => {
    if (subPage) setSubTitles(Object.keys(subPage));
  }, [title, pageTitle, subPage]);

  return (
    <React.Fragment>
      <Row>
        <Col xs={12}>
          <div className="page-title-box d-sm-flex align-items-center justify-content-between">
            <h4 className="mb-sm-0">{title}</h4>

            <div className="page-title-right">
              <ol className="breadcrumb m-0">
                {backLink ? (
                  <li className="breadcrumb-item">
                    <Link to={backLink}>Back</Link>
                  </li>
                ) : (
                  ""
                )}
                <li className="breadcrumb-item">
                  <Link to="/dashboard">Home</Link>
                </li>
                {subTitles?.map((page, index) => (
                  <li className="breadcrumb-item" key={index}>
                    <Link to={subPage[page]}>{page}</Link>
                  </li>
                ))}
                <li className="breadcrumb-item active">{pageTitle}</li>
                {pageSubTitle && (
                  <li className="breadcrumb-item active">{pageSubTitle}</li>
                )}
              </ol>
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default BreadCrumb;
