import React, { useState, useCallback, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";

const AddSubAdmin = (props) => {
  const {
    isOpen,
    toggle,
    addSubAdmin,
    updateSubAdmin,
    roleDropdownList,
    editData,
  } = props;
  const [cPasswordShow, setCPasswordShow] = useState(false);
  const [passwordShow, setPasswordShow] = useState(false);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      username: editData?.username || "",
      firstName: editData?.firstName || "",
      lastName: editData?.lastName || "",
      mobileNumber: editData?.mobileNumber || "",
      role: editData?.roleId || "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      username: Yup.string()
        .min(4, "Username must be at least 4 characters")
        .matches(/^[a-zA-Z0-9]+$/, "Username must be alphanumeric")
        .required("Please Enter Username"),
      firstName: Yup.string().required("Please Enter First Name"),
      mobileNumber: Yup.string()
        .matches(/^\d{10}$/, "Phone number must be exactly 10 digits"),
      role: Yup.string().required("Please select Name"),
      password: Yup.string().when("isEdit", {
        is: false,
        then: Yup.string()
          .min(8, "Password must be at least 8 characters")
          .matches(
            /[A-Z]/,
            "Password must contain at least one uppercase letter."
          )
          .matches(
            /[a-z]/,
            "Password must contain at least one lowercase letter."
          )
          .matches(/[0-9]/, "Password must contain at least one numeric digit.")
          .matches(
            /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/,
            "Password must contain at least one special character."
          )
          .required("Please Enter Password"),
        otherwise: Yup.string().notRequired(),
      }),
      confirmPassword: Yup.string().when("isEdit", {
        is: false,
        then: Yup.string()
          .oneOf([Yup.ref("password"), null], "Passwords must match")
          .required("Please confirm password"),
        otherwise: Yup.string().notRequired(),
      }),
    }),

    onSubmit: (values) => {
      const payload = {
        firstName: values.firstName.trim(),
        lastName: values.lastName.trim(),
        username: values.username.trim(),
        mobileNumber: values.mobileNumber.trim(),
        role: values.role,
        password: values.password.trim(),
      };
      if (props.editData) {
        let temp = { ...payload, id: editData?._id };
        updateSubAdmin(temp);
      } else {
        addSubAdmin(payload);
      }
    },
  });

  useEffect(() => {
    if (!isOpen) validation.resetForm();
  }, [isOpen]);

  return (
    <Modal
      id="showModal"
      isOpen={isOpen}
      toggle={toggle}
      centered
      className="modal"
    >
      <ModalHeader className="bg-light p-3" toggle={toggle}>
        {editData ? "Edit" : "Add"} Sub-admin
      </ModalHeader>
      <Form
        className="tablelist-form"
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
      >
        <ModalBody>
          <Row>
            <Col sm="12">
              <div className="mb-3">
                <Label htmlFor="username-field" className="form-label">
                  Username
                </Label>
                <Input
                  name="username"
                  id="username-field"
                  className="form-control"
                  placeholder="Enter Username"
                  type="text"
                  validate={{ required: { value: true } }}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.username || ""}
                  invalid={
                    validation.touched.username && validation.errors.username
                      ? true
                      : false
                  }
                />
                {validation.touched.username && validation.errors.username ? (
                  <FormFeedback type="invalid">
                    {validation.errors.username}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="mb-3">
                <Label htmlFor="firstName-field" className="form-label">
                  First Name
                </Label>
                <Input
                  name="firstName"
                  id="firstName-field"
                  className="form-control"
                  placeholder="Enter First Name"
                  type="text"
                  validate={{ required: { value: true } }}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.firstName || ""}
                  invalid={
                    validation.touched.firstName && validation.errors.firstName
                      ? true
                      : false
                  }
                />
                {validation.touched.firstName && validation.errors.firstName ? (
                  <FormFeedback type="invalid">
                    {validation.errors.firstName}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="mb-3">
                <Label htmlFor="lastName-field" className="form-label">
                  Last Name
                </Label>
                <Input
                  name="lastName"
                  id="lastName-field"
                  className="form-control"
                  placeholder="Enter Last Name"
                  type="text"
                  validate={{ required: { value: true } }}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.lastName || ""}
                  // invalid={
                  //   validation.touched.lastName && validation.errors.lastName
                  //     ? true
                  //     : false
                  // }
                />
                {/* {validation.touched.lastName && validation.errors.lastName ? (
                  <FormFeedback type="invalid">
                    {validation.errors.lastName}
                  </FormFeedback>
                ) : null} */}
              </div>
              <div className="mb-3">
                <Label htmlFor="mobileNumber-field" className="form-label">
                  Phone
                </Label>
                <Input
                  name="mobileNumber"
                  type="text"
                  id="mobileNumber-field"
                  placeholder="Enter Phone no."
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.mobileNumber || ""}
                  invalid={
                    validation.touched.mobileNumber &&
                    validation.errors.mobileNumber
                      ? true
                      : false
                  }
                />
                {validation.touched.mobileNumber &&
                validation.errors.mobileNumber ? (
                  <FormFeedback type="invalid">
                    {validation.errors.mobileNumber}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="mb-3">
                <Label htmlFor="role-field" className="form-label">
                  role
                </Label>
                <Input
                  name="role"
                  type="select"
                  className="form-select"
                  id="role-field"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.role || ""}
                  invalid={
                    validation.touched.role && validation.errors.role
                      ? true
                      : false
                  }
                >
                  <option value="" key="0">
                    Please Select
                  </option>
                  {roleDropdownList?.map((itm) => {
                    return (
                      <option value={itm._id} key={itm._id}>
                        {itm.name}
                      </option>
                    );
                  })}
                </Input>
                {validation.errors.role ? (
                  <FormFeedback type="invalid">
                    {validation.errors.role}
                  </FormFeedback>
                ) : null}
              </div>

              <React.Fragment>
                <div className="mb-3">
                  <Label htmlFor="username-field" className="form-label">
                    Set User Password
                  </Label>
                  <div className="position-relative auth-pass-inputgroup mb-3">
                    <Input
                      name="password"
                      type={passwordShow ? "text" : "password"}
                      id="password-field"
                      placeholder="Enter Password"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.password || ""}
                      invalid={
                        validation.touched.password &&
                        validation.errors.password
                          ? true
                          : false
                      }
                    />
                    {validation.touched.username &&
                    validation.errors.password ? (
                      <FormFeedback type="invalid">
                        {validation.errors.password}
                      </FormFeedback>
                    ) : null}
                    <button
                      className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                      type="button"
                      id="password"
                      onClick={() => setPasswordShow(!passwordShow)}
                    >
                      <i className="ri-eye-fill align-middle"></i>
                    </button>
                  </div>
                </div>

                <div className="mb-3">
                  <Label htmlFor="confirmPassword-field" className="form-label">
                    Confirm User Password
                  </Label>
                  <div className="position-relative auth-pass-inputgroup mb-3">
                    <Input
                      name="confirmPassword"
                      type={cPasswordShow ? "text" : "password"}
                      id="confirmPassword-field"
                      placeholder="Confirm Password"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.confirmPassword || ""}
                      invalid={
                        validation.touched.confirmPassword &&
                        validation.errors.confirmPassword
                          ? true
                          : false
                      }
                    />
                    {validation.touched.confirmPassword &&
                    validation.errors.confirmPassword ? (
                      <FormFeedback type="invalid">
                        {validation.errors.confirmPassword}
                      </FormFeedback>
                    ) : null}
                    <button
                      className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                      type="button"
                      id="password-c"
                      onClick={() => setCPasswordShow(!cPasswordShow)}
                    >
                      <i className="ri-eye-fill align-middle"></i>
                    </button>
                  </div>
                </div>
              </React.Fragment>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <div className="hstack gap-2 justify-content-end">
            <button
              type="button"
              className="btn btn-light"
              onClick={() => {
                toggle();
              }}
            >
              Close
            </button>

            <button type="submit" className="btn btn-success">
              {editData ? "Edit" : "Add"} Sub-admin
            </button>
          </div>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default AddSubAdmin;
