import { createSlice } from "@reduxjs/toolkit";
import {
  getBlacklistedIps,
  addBlacklistedIps,
  deleteBlacklistedIps,
} from "./action";
import { toast } from "react-toastify";

const initialState = {
  blacklistedIps: [],
  totalRows: 0,
};

const BlacklistedIps = createSlice({
  name: "BlacklistedIps",
  initialState,
  reducers: {},
  extraReducers: (b) => {
    b.addCase(getBlacklistedIps.fulfilled, (state, action) => {
      state.blacklistedIps = action.payload.items;
      state.totalRows = action.payload.total;
    });
    b.addCase(addBlacklistedIps.fulfilled, (state, action) => {
      if (action.payload) {
        toast.success(action.payload.message);
        return {
          ...state,
          blacklistedIps: [...state.blacklistedIps, action.payload.data],
          totalRows: state.totalRows + 1,
        };
      }
    });
    b.addCase(deleteBlacklistedIps.fulfilled, (state, action) => {
      if (action.payload) {
        toast.success(action.payload.message);
      }
      if (action.meta.arg.ipIds.length) {
        const idsToRemove = action.meta.arg.ipIds;
        const lengthOfSet = idsToRemove.size;
        return {
          ...state,
          blacklistedIps: state.blacklistedIps.filter(
            (stakeButton) => !idsToRemove.includes(stakeButton._id.toString())
          ),
          totalRows: state.totalRows - lengthOfSet,
        };
      }
    });
  },
});

export default BlacklistedIps.reducer;
