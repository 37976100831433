import moment from "moment-timezone";

const formatDateRelativeToNow = (inputDateStr) => {
  let inputDateTemp =
    typeof inputDateStr === "string" && !isNaN(inputDateStr)
      ? moment.unix(inputDateStr)
      : inputDateStr;
  const currentTimezone = moment.tz.guess();
  const inputDate = moment.tz(inputDateTemp, currentTimezone);
  const currentDate = moment.tz(currentTimezone);

  // Compare the dates.
  if (inputDate.isSame(currentDate, "day")) {
    // If it's today.
    return { date: "Today", time: inputDate.format("hh:mm:ss A") };
  } else if (inputDate.isSame(currentDate.clone().add(1, "days"), "day")) {
    // If it's tomorrow.
    return { date: "Tomorrow", time: inputDate.format("hh:mm:ss A") };
  } else {
    // Otherwise, format as "DD-MM HH:mm".
    return {
      date: inputDate.format("DD-MM-YYYY"),
      time: inputDate.format("hh:mm:ss A"),
    };
  }
};

const formatDate = (inputDateStr) => {
  const currentTimezone = moment.tz.guess();
  const inputDate = moment.tz(inputDateStr, currentTimezone);

  return inputDate.format("DD-MM-YYYY hh:mm:ss A");
};

export { formatDateRelativeToNow, formatDate };
