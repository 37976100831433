import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";

const TopWithdrawal = ({ topUserWithdrawals, toggleProfile }) => {
  const { modules } = useSelector((state) => ({
    modules: state.User.modules,
  }));
  return (
    <React.Fragment>
      <Col xl={6}>
        <Card>
          <CardHeader className="align-items-center d-flex">
            <h4 className="card-title mb-0 flex-grow-1">
              Top Withdrawal{" "}
              <Link
                to="/reports/withdraw-history"
                className="fw-small fs-10 link-primary"
              >
                (View More)
              </Link>
            </h4>
            {/* <div className="flex-shrink-0">
              <UncontrolledDropdown
                className="card-header-dropdown"
                direction="start"
              >
                <DropdownToggle tag="a" className="text-reset" role="button">
                  <span className="fw-semibold text-uppercase fs-12">
                    Sort by:{" "}
                  </span>
                  <span className="text-muted">
                    Today<i className="mdi mdi-chevron-down ms-1"></i>
                  </span>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                  <DropdownItem>Today</DropdownItem>
                  <DropdownItem>Yesterday</DropdownItem>
                  <DropdownItem>Last 7 Days</DropdownItem>
                  <DropdownItem>Last 30 Days</DropdownItem>
                  <DropdownItem>This Month</DropdownItem>
                  <DropdownItem>Last Month</DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div> */}
          </CardHeader>
          <CardBody>
            <div className="table-responsive table-card">
              <table className="table table-borderless table-centered align-middle table-nowrap mb-0">
                <thead className="text-muted table-light">
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {(topUserWithdrawals || []).map((item, key) => {
                    return (
                      <tr key={key}>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="flex-shrink-0 me-2">
                              {/* <img
                              src={item.img}
                              alt=""
                              className="avatar-xs rounded-circle"
                            /> */}
                            </div>
                            <div className="flex-grow-1">
                              <span
                                onClick={
                                  item.userClickable &&
                                  modules?.["account-profile-button"]
                                    ? (e) => {
                                        e.stopPropagation();
                                        toggleProfile(item?.userId);
                                      }
                                    : null
                                }
                                className={`${
                                  item.userClickable &&
                                  modules?.["account-profile-button"]
                                    ? "cursor-pointer"
                                    : ""
                                } `}
                              >
                                <span>{item?.username ?? ""}</span>
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <span className="text-success">
                            <i className="mdi mdi-currency-inr align-middle text-primary me-2"></i>
                            {Math.round(item?.totalVerifiedWithdrawals * 100) /
                              100 || 0}
                          </span>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default TopWithdrawal;
