import React, { useCallback, useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";

import Deposit from "./Deposit";
import PendingDeposit from "./PendingDeposit";
import TopDeposit from "./TopDeposit";
import TopWithdrawal from "./TopWithdrawal";
import TopLoser from "./TopLoser";
import TopPlayedGame from "./TopPlayedGame";
import Section from "./Section";
import {
  DMWStat,
  WithdrawalStat,
  BonusStat,
  GGRStat,
  BetsStat,
  KYCStat,
  UserStat,
  BalanceStat,
} from "./Stats";

import BetsCountPL from "./Chart-BetsCount_N_PL";
import GameTypeWisePL from "./Chart-GameTypeWisePL";
import ClientAnalysis from "./Chart-ClientAnalysis";
import DepositWithdrawal from "./Chart-DepositWithdrawal";
import SportWiseOpenBet from "./Chart-SportWiseOpenBet";
import SportWisePL from "./Chart-SportWisePL";
import TrafficAnalysis from "./Chart-TrafficAnalysis";
import BalanceCard from "./Balance-Card";
import { useDispatch, useSelector } from "react-redux";
import {
  DATE_RANGE,
  dropdownDateMapping,
} from "../../Components/constants/commonConstants";
import CustomDateModal from "./CustomDateModal";
import { formatDateRelativeToNow } from "../../helpers/methods/formatDate";
import {
  changeLoader,
  getDepositsData,
  getWithdrawalsData,
} from "../../store/actions";
import socket from "../../sockets/socket";
import withdrawlSound from "../../assets/sounds/withdrawl.mp3";
import DetailModal from "../User/Account/DetailModal";
import { resetSingleUserDetail } from "../../store/user/reducer";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import ProfitLossChart from "./ProfitLossBar";
import MyChart from "./CreditGraph";

const Dashboard = () => {
  const [selectedDate, setSelectedDate] = useState("Today");
  const [isOpen, setCustomDateModal] = useState(false);
  const [dateRange, setDateRange] = useState(
    dropdownDateMapping[DATE_RANGE.today]
  );
  const [selectedUserId, setSelectedUserId] = useState("");
  const [profileModal, setProfileModal] = useState(false);

  const { userBalance, type, data, topUserWithdrawals } = useSelector(
    (state) => ({
      type: state.User?.user?.type,
      userBalance: state.User?.user?.userBalance,
      data: state.Dashboard?.withdrawals?.data,
      topUserWithdrawals: state.Dashboard?.withdrawals?.topUserWithdrawals,
    })
  );

  const dispatch = useDispatch();
  const location = useLocation();

  const fetchData = async () => {
    dispatch(changeLoader(true));
    try {
      await Promise.all([
        dispatch(
          getWithdrawalsData({
            startDate: new Date(dateRange?.startDate),
            endDate: new Date(dateRange?.endDate),
          })
        ),
        dispatch(
          getDepositsData({
            startDate: new Date(dateRange?.startDate),
            endDate: new Date(dateRange?.endDate),
          })
        ),
      ]);
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(changeLoader(false));
    }
  };

  const fetchWithdrawals = async () => {
    try {
      dispatch(changeLoader(true));
      dispatch(
        getWithdrawalsData({
          startDate: new Date(dateRange?.startDate),
          endDate: new Date(dateRange?.endDate),
        })
      );
    } finally {
      dispatch(changeLoader(false));
    }
  };

  const fetchDeposits = async () => {
    try {
      dispatch(changeLoader(true));
      dispatch(
        getDepositsData({
          startDate: new Date(dateRange?.startDate),
          endDate: new Date(dateRange?.endDate),
        })
      );
    } finally {
      dispatch(changeLoader(false));
    }
  };

  useEffect(() => {
    if (location?.state?.isRedirect)
      toast.error(`You don't have access to ${location.state?.module} module.`);
  }, []);

  useEffect(() => {
    socket.on("new-deposit", async (data) => {
      await fetchDeposits();
    });
    return () => {};
  }, [dispatch]);

  useEffect(() => {
    socket.on("new-withdrawl", async (data) => {
      await fetchWithdrawals();
      const sound = new Audio(withdrawlSound);
      sound.play();
    });
    return () => {};
  }, []);

  useEffect(() => {
    const fetchDataAndHandleLoader = async () => {
      try {
        await fetchData();
      } catch (error) {
        console.log(error);
      }
    };
    fetchDataAndHandleLoader();

    // return () => {
    //   dispatch(resetSingleUserDetail());
    // };
  }, [dispatch, dateRange]);

  const handleDateDropdownChange = (e) => {
    let dateType = e?.target?.value;
    setSelectedDate(dateType);
    if (dateType === DATE_RANGE.customDate) {
      setCustomDateModal(true);
    } else {
      setDateRange(dropdownDateMapping[dateType]);
    }
  };
  const toggleModal = () => {
    setCustomDateModal(false);
  };

  const toggleProfile = useCallback(
    (userId = null) => {
      if (profileModal) {
        setProfileModal(false);
        setSelectedUserId("");
        dispatch(resetSingleUserDetail());
      } else {
        setSelectedUserId(userId);
        setProfileModal(true);
      }
    },
    [profileModal]
  );
  document.title = "Dashboard | " + process.env.REACT_APP_NAME;
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col md={6} lg={4}>
              <Section />
            </Col>
            <Col md={6} lg={4}>
              {selectedDate === DATE_RANGE.customDate ? (
                <>
                  {" "}
                  <span>
                    {formatDateRelativeToNow(dateRange?.startDate)?.date}{" "}
                    {formatDateRelativeToNow(dateRange?.startDate)?.time}
                  </span>
                  -{" "}
                  <span>
                    {formatDateRelativeToNow(dateRange?.endDate)?.date}{" "}
                    {formatDateRelativeToNow(dateRange?.endDate)?.time}
                  </span>
                </>
              ) : (
                ""
              )}
            </Col>
            <Col md={6} lg={4}>
              <select
                className="form-select form-select-sm p-2 fs-6 mb-4"
                aria-label=".form-select-sm example"
                placeholder="Select Date"
                value={selectedDate}
                onChange={handleDateDropdownChange}
              >
                <option value={DATE_RANGE.today}>Today</option>
                <option value={DATE_RANGE.yesterday}>Yesterday</option>
                <option value={DATE_RANGE.last7Days}>Last 7 days</option>
                <option
                  onChange={() =>
                    handleDateDropdownChange(DATE_RANGE.thisMonth)
                  }
                  className={
                    selectedDate === DATE_RANGE.thisMonth ? "active" : ""
                  }
                  value={DATE_RANGE.thisMonth}
                >
                  This Month
                </option>
                <option
                  onChange={() =>
                    handleDateDropdownChange(DATE_RANGE.customDate)
                  }
                  className={
                    selectedDate === DATE_RANGE.customDate ? "active" : ""
                  }
                  value={DATE_RANGE.customDate}
                >
                  Custom Date
                </option>
              </select>
            </Col>
          </Row>
          <BalanceCard userBalance={userBalance} type={type} />
          <Row>
            <Col xl={8} md={8}>
              <Row>
                <Col xl={6} md={6}>
                  <Deposit dateRange={dateRange} />
                  <WithdrawalStat data={data} />
                </Col>
                <Col xl={6} md={6}>
                  <PendingDeposit dateRange={dateRange} />
                  <BonusStat />
                </Col>
              </Row>
            </Col>

            <Col xl={4} md={4}>
              <DMWStat />
              {/* <GGRStat /> */}
            </Col>
          </Row>
          <Row>
            <TopDeposit toggleProfile={toggleProfile.bind(null)} />
            <TopWithdrawal
              topUserWithdrawals={topUserWithdrawals}
              toggleProfile={toggleProfile.bind(null)}
            />
          </Row>
          <Row>
            <ProfitLossChart />
          </Row>
          <Row>
            <MyChart />
          </Row>
        </Container>
        <CustomDateModal
          isOpen={isOpen}
          setDateRange={setDateRange}
          toggle={toggleModal}
        />
      </div>
      {selectedUserId && profileModal ? (
        <DetailModal
          isOpen={profileModal}
          userId={selectedUserId}
          // customer={customer}
          toggleDetail={toggleProfile.bind(null)}
        />
      ) : (
        ""
      )}{" "}
    </React.Fragment>
  );
};

export default Dashboard;
