import { APIHelper } from "../../api_helper";
import * as url from "../../url_helper";

// Open Bets
export const getOpenBets = (payload) =>
  APIHelper.get(url.GET_OPEN_BETS, payload);
export const getVoidBets = (payload) =>
  APIHelper.get(url.GET_VOID_BETS, payload);
export const getBetHistory = (payload) =>
  APIHelper.get(url.GET_BET_HISTORY, payload);
export const voidOpenBetRequest = (payload) =>
  APIHelper.post(url.POST_VOID_OPEN_BETS, payload);
export const getSearchClients = (payload) =>
  APIHelper.get(url.GET_SEARCH_CLIENTS(payload));
export const getLiveCasinoReport = (payload) =>
  APIHelper.post(url.POST_LIVE_CASINO_REPORT, payload);
export const getAuraCasinoReport = (payload) =>
  APIHelper.post(url.AURA_CASINO_REPORT, payload);
export const getCasinoAnalysisReportAPI = (payload) =>
  APIHelper.post(url.CASINO_ANALYSIS_REPORT, payload);
export const getSearchUserApi = (payload) =>
  APIHelper.post(url.GET_SEARCH_USER, payload);
export const downloadWithdrawReportApi = (payload, type) =>
  APIHelper.post(url.DOWNLOAD_WITHDRAW_REPORT(type), payload);
export const getSearchDepositApi = (payload) =>
  APIHelper.post(url.GET_SEARCH_DEPOSITS, payload);
export const downloadDepositReportApi = (payload, type) =>
  APIHelper.post(url.DOWNLOAD_DEPOSIT_REPORT(type), payload);
export const getAccountStatementApi = (payload) =>
  APIHelper.post(url.GET_ACCOUNT_STATEMENT, payload);
export const downloadAccountStatementApi = (payload, type) =>
  APIHelper.post(url.DOWNLOAD_ACCOUNT_STATEMENT(type), payload);
export const downloadBetsReportApi = (payload, type) =>
  APIHelper.post(url.DOWNLOAD_BETS_REPORT(type), payload);
export const downloadCasinoAnalysisApi = (payload, type) =>
  APIHelper.post(url.DOWNLOAD_CASINO_REPORT(type), payload);
