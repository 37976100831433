import { APIHelper } from "../../api_helper";
import * as url from "../../url_helper";

// GET deltaexch.games
export const getInPlayData = () => APIHelper.get(url.GET_INPLAY_MATCHES);
export const getAllMatchData = () => APIHelper.get(url.GET_ALL_MATCHES);
export const getBetsCountData = (data) => APIHelper.post(url.GET_EVENTS_BETS, data);
export const getEventMarketData = (eventId) => APIHelper.get(url.GET_EVENT_MARKET(eventId));
export const getEventBetsCountData = (eventId) => APIHelper.get(url.GET_EVENT_BETS(eventId));
export const getOpenBetMarketsData = (eventId) => APIHelper.get(url.GET_OPEN_BET_MARKETS(eventId));
export const getMarketBetsData = (data) => APIHelper.post(url.GET_MARKET_BETS, data);
export const getMarketOddsData = (eventIds) => APIHelper.post(url.GET_EVENTS_ODDS, eventIds);
export const getPlAnalysisAPI = (data) => APIHelper.post(url.GET_PL_ANALYSIS, data);
export const getPlByMarketData = (data) => APIHelper.post(url.GET_PL_MARKET, data);
export const getPlUserBetsData = (marketId) => APIHelper.get(url.GET_PL_USER_BETS(marketId));
export const getMarketTypeData = () => APIHelper.get(url.GET_PL_MARKET_TYPES);
export const getEventTypeData = () => APIHelper.get(url.GET_PL_EVENT_TYPES);
