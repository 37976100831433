import { APIHelper } from "../../api_helper";
import * as url from "../../url_helper";

// Roles
// get Role
export const getRoleList = (params) => APIHelper.get(url.GET_ROLE_LIST, params);
export const getRoleDropDownListApi = () =>
  APIHelper.get(url.GET_ROLE_DROPDOWN_LIST);

// get Role Setting
export const getRoleSetting = () => APIHelper.get(url.GET_ROLE_SETTINGS);

// add Role
export const addNewRole = (role) => APIHelper.post(url.ADD_NEW_ROLE, role);

// update Role
export const updateRole = (role) =>
  APIHelper.patch(url.UPDATE_ROLE + "/" + role._id, role);

// delete Role
export const deleteRole = (data) => APIHelper.delete(url.DELETE_ROLE, { data });

export const moduleListApi = (params) =>
  APIHelper.get(url.GET_MODULE_LIST, params);

export const getRoleDetailApi = (id) => APIHelper.get(url.GET_ROLE_DETAIL(id));
