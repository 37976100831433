import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Label, Input, Form, FormFeedback } from "reactstrap";
import { useDispatch } from "react-redux";
import { updatePassword } from "../../../../store/user/action";
import { changeLoader } from "../../../../store/actions";

const ChangePasswordTab = ({ account, toggle }) => {
  const dispatch = useDispatch();
  const [passwordShow, setPasswordShow] = useState(false);
  const [cPasswordShow, setCPasswordShow] = useState(false);
  const [pPasswordShow, setPPasswordShow] = useState(false);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      newPassword: "",
      confirmPassword: "",
      transactionCode: "",
    },
    validationSchema: Yup.object({
      newPassword: Yup.string()
        .min(8, "Password must be at least 8 characters")
        .matches(
          /[A-Z]/,
          "Password must contain at least one uppercase letter."
        )
        .matches(
          /[a-z]/,
          "Password must contain at least one lowercase letter."
        )
        .matches(/[0-9]/, "Password must contain at least one numeric digit.")
        .matches(
          /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/,
          "Password must contain at least one special character."
        )
        .required("Please Enter Password"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
        .required("Please enter confirm password"),
      transactionCode: Yup.number().required("Please Enter Transaction Code"),
    }),
    onSubmit: (values) => {
      dispatch(changeLoader(true));

      dispatch(updatePassword({ ...values, ...{ userId: account._id } }))
        .then((a) => {
          if (a.payload) {
            dispatch(changeLoader(false));
            toggle();
          } else {
            dispatch(changeLoader(false));
          }
        })
        .catch((error) => {
          dispatch(changeLoader(false));
        });
    },
  });

  return (
    <Form
      className="tablelist-form p-4"
      onSubmit={(e) => {
        e.preventDefault();
        validation.handleSubmit();
        return false;
      }}
    >
      <div className="mb-3">
        <Label htmlFor="newPassword-field" className="form-label">
          New Password
        </Label>
        <div className="position-relative auth-pass-inputgroup mb-3">
          <Input
            name="newPassword"
            id="newPassword-field"
            className="form-control"
            placeholder="Password"
            type={passwordShow ? "text" : "password"}
            validate={{
              required: { value: true },
            }}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.newPassword || ""}
            invalid={
              validation.touched.newPassword && validation.errors.newPassword
                ? true
                : false
            }
          />
          {validation.touched.newPassword && validation.errors.newPassword ? (
            <FormFeedback type="invalid">
              {validation.errors.newPassword}
            </FormFeedback>
          ) : null}
          <button
            className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
            type="button"
            id="password"
            onClick={() => setPasswordShow(!passwordShow)}
          >
            <i className="ri-eye-fill align-middle"></i>
          </button>
        </div>
      </div>
      <div className="mb-3">
        <Label htmlFor="confirmPassword-field" className="form-label">
          Confirm Password
        </Label>
        <div className="position-relative auth-pass-inputgroup mb-3">
          <Input
            name="confirmPassword"
            id="confirmPassword-field"
            className="form-control"
            placeholder="Password"
            type={cPasswordShow ? "text" : "password"}
            validate={{
              required: { value: true },
            }}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.confirmPassword || ""}
            invalid={
              validation.touched.confirmPassword &&
              validation.errors.confirmPassword
                ? true
                : false
            }
          />
          {validation.touched.confirmPassword &&
          validation.errors.confirmPassword ? (
            <FormFeedback type="invalid">
              {validation.errors.confirmPassword}
            </FormFeedback>
          ) : null}
          <button
            className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
            type="button"
            id="password-c"
            onClick={() => setCPasswordShow(!cPasswordShow)}
          >
            <i className="ri-eye-fill align-middle"></i>
          </button>
        </div>
      </div>
      <div className="mb-3">
        <Label htmlFor="transactionCode-field" className="form-label">
          Transaction Code
        </Label>
        <div className="position-relative auth-pass-inputgroup mb-3">
          <Input
            name="transactionCode"
            id="transactionCode-field"
            className="form-control"
            placeholder="Enter Code"
            type={pPasswordShow ? "text" : "password"}
            validate={{
              required: { value: true },
            }}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.transactionCode || ""}
            invalid={
              validation.touched.transactionCode &&
              validation.errors.transactionCode
                ? true
                : false
            }
          />
          {validation.touched.transactionCode &&
          validation.errors.transactionCode ? (
            <FormFeedback type="invalid">
              {validation.errors.transactionCode}
            </FormFeedback>
          ) : null}
          <button
            className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
            type="button"
            id="password-2fa"
            onClick={() => setPPasswordShow(!pPasswordShow)}
          >
            <i className="ri-eye-fill align-middle"></i>
          </button>
        </div>
      </div>

      <div className="hstack gap-2 justify-content-end">
        <button
          type="button"
          className="btn btn-light"
          onClick={() => {
            toggle();
          }}
        >
          Close
        </button>

        <button type="submit" className="btn btn-success">
          Update
        </button>
      </div>
    </Form>
  );
};

export default ChangePasswordTab;
