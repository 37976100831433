import React from "react";
import { useSelector } from "react-redux";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";

const DMWStat = () => {
  const { depositData, withdrawalData } = useSelector((state) => ({
    depositData: state.Dashboard?.deposits?.data,
    withdrawalData: state.Dashboard?.withdrawals?.data,
  }));
  let totalVerifiedDepositsAllUsers = depositData?.totalVerifiedDepositsAllUsers
    ? Math.round(depositData?.totalVerifiedDepositsAllUsers * 100) / 100
    : 0;
  let totalApprovedWithdrawals = withdrawalData?.totalApprovedWithdrawals
    ? Math.round(withdrawalData?.totalApprovedWithdrawals * 100) / 100
    : 0;

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <div className="table-responsive table-card">
            <table className="table align-middle table-borderless table-centered table-nowrap mb-0">
              <tbody>
                <tr>
                  <td>Total DMW</td>
                  <td>
                    <i className="mdi mdi-currency-inr align-middle text-primary me-2"></i>
                    {/* {totalVerifiedDepositsAllUsers >
                    totalApprovedWithdrawals
                      ? totalVerifiedDepositsAllUsers -
                        totalApprovedWithdrawals
                      : 0} */}
                    {Math.round(
                      (totalVerifiedDepositsAllUsers -
                        totalApprovedWithdrawals) *
                        100
                    ) / 100}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

const WithdrawalStat = ({ data }) => {
  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <div className="table-responsive table-card">
            <table className="table align-middle table-borderless table-centered table-nowrap mb-0">
              <tbody>
                <tr>
                  <td
                  // onClick={(e) => {
                  //   e.stopPropagation();
                  //   handleNavigate({
                  //     link: "/reports/withdraw-report",
                  //     status: ENUM_WITHDRAW_STATUS.APPROVED,
                  //   });
                  // }}
                  // className="cursor-pointer"
                  >
                    Withdrawal{" "}
                    {data?.countApprovedWithdrawals ? (
                      <span>
                        (
                        {Math.round(data?.countApprovedWithdrawals * 100) / 100}
                        )
                      </span>
                    ) : (
                      ""
                    )}
                  </td>
                  <td>
                    <span className="text-success">
                      <i className="mdi mdi-currency-inr align-middle text-primary me-2"></i>
                      {Math.round(data?.totalApprovedWithdrawals * 100) / 100 ||
                        0}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td
                  // onClick={(e) => {
                  //   e.stopPropagation();
                  //   handleNavigate({
                  //     link: "/payment/withdraw",
                  //   });
                  // }}
                  // className="cursor-pointer"
                  >
                    Pending{" "}
                    {data?.countPendingWithdrawals ? (
                      <span>({data?.countPendingWithdrawals})</span>
                    ) : (
                      ""
                    )}
                  </td>
                  <td>
                    <span className="text-success">
                      <i className="mdi mdi-currency-inr align-middle text-primary me-2"></i>
                      {Math.round(data?.totalPendingWithdrawals * 100) / 100 ||
                        0}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

const BonusStat = () => {
  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <div className="table-responsive table-card">
            <table className="table align-middle table-borderless table-centered table-nowrap mb-0">
              <tbody>
                <tr>
                  <td>Bonus Used</td>
                  <td>12</td>
                </tr>
                <tr>
                  <td>Bonus To Real</td>
                  <td>12</td>
                </tr>
              </tbody>
            </table>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

const GGRStat = () => {
  return (
    <React.Fragment>
      <Card>
        <CardHeader className="align-items-center d-flex">
          <h4 className="card-title mb-0 flex-grow-1">GGR</h4>
        </CardHeader>
        <CardBody>
          <div className="table-responsive table-card">
            <table className="table align-middle table-borderless table-centered table-nowrap mb-0">
              <tbody>
                <tr>
                  <td>Sport GGR</td>
                  <td>12</td>
                </tr>
                <tr>
                  <td>Casino GGR</td>
                  <td>12</td>
                </tr>
                <tr>
                  <td>Total GGR</td>
                  <td>12</td>
                </tr>
                <tr>
                  <td>Margin</td>
                  <td>12</td>
                </tr>
              </tbody>
            </table>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

const BetsStat = () => {
  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <div className="table-responsive table-card">
            <table className="table align-middle table-borderless table-centered table-nowrap mb-0">
              <tbody>
                <tr>
                  <td>No of Bets</td>
                  <td>12</td>
                </tr>
                <tr>
                  <td>Total Volume</td>
                  <td>12</td>
                </tr>
              </tbody>
            </table>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};
const KYCStat = () => {
  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <div className="table-responsive table-card">
            <table className="table align-middle table-borderless table-centered table-nowrap mb-0">
              <tbody>
                <tr>
                  <td>Pending KYC</td>
                  <td>12</td>
                </tr>
                <tr>
                  <td>New Registration</td>
                  <td>12</td>
                </tr>
              </tbody>
            </table>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};
const UserStat = () => {
  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <div className="table-responsive table-card">
            <table className="table align-middle table-borderless table-centered table-nowrap mb-0">
              <tbody>
                <tr>
                  <td>Total User</td>
                  <td>12</td>
                </tr>
                <tr>
                  <td>User Made Deposit</td>
                  <td>12</td>
                </tr>
                <tr>
                  <td>User Placed Bets</td>
                  <td>12</td>
                </tr>
              </tbody>
            </table>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};
const BalanceStat = () => {
  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <div className="table-responsive table-card">
            <table className="table align-middle table-borderless table-centered table-nowrap mb-0">
              <tbody>
                <tr>
                  <td>Total Balance</td>
                  <td>12</td>
                </tr>
                <tr>
                  <td>Total Exposure</td>
                  <td>12</td>
                </tr>
                <tr>
                  <td>Total Bonus</td>
                  <td>12</td>
                </tr>
              </tbody>
            </table>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export {
  DMWStat,
  WithdrawalStat,
  BonusStat,
  GGRStat,
  BetsStat,
  KYCStat,
  UserStat,
  BalanceStat,
};
