import { createSlice } from '@reduxjs/toolkit';
import { getMarkets, getMarketStatus } from './action';
import { toast } from 'react-toastify';

const initialState = {
  markets: [],
  totalRows: 0,
  marketStatus: [],
};

const Markets = createSlice({
  name: 'markets',
  initialState,
  reducers: {},
  extraReducers: (b) => {
    b.addCase(getMarkets.fulfilled, (state, action) => {
      state.markets = action.payload.items ? action.payload.items : [];
      state.totalRows = action.payload.total ? action.payload.total : 0;
    });
    b.addCase(getMarketStatus.fulfilled, (state, action) => {
      state.marketStatus = action.payload;
    })
  },
});

export default Markets.reducer;
