import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  refreshMarket as refreshMarketAPI,
  getMarketList,
  updateMarketStatus as updateMarketStatusApi,
  marketStatusApi,
} from '../../../helpers/backend_helper';

export const refreshMarkets = createAsyncThunk('market/refreshMarkets', async (payload) => {
  const response = await refreshMarketAPI(payload);
  return response.data;
});

export const getMarkets = createAsyncThunk('market/getMarkets', async (data) => {
  const { payload, params } = data;
  const response = await getMarketList(payload, params);
  return response.data;
});

export const getMarketStatus = createAsyncThunk('market/getMarketStatus', async (data) => {
  const { eventReferenceId } = data;
  const response = await marketStatusApi(eventReferenceId);
  return response.data;
});

export const updateMarketStatus = createAsyncThunk('market/updateMarketStatus', async (data) => {
  const response = await updateMarketStatusApi(data);
  return response.data;
});

