import { createSlice } from "@reduxjs/toolkit";
import { loginUser, resetLoginFlag, loginVerify2FA, resetPasswordAction } from "./actions";

const initialState = {
  errorMsg: "",
  loading: false,
  error: false,
  user: {},
  isUserLogout: true,
  resetPasswordData: {
    errorMsg: "",
    loading: false,
    error: false,
  },
};

const loginSlice = createSlice({
  name: "Login",
  initialState,
  reducers: {
    logoutUserSuccess: (state) => {
      localStorage.removeItem("authUser");
      state.user = {};
      state.isUserLogout = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
        state.isUserLogout = false;
        state.errorMsg = action.error.message;
      });

    builder
      .addCase(loginVerify2FA.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(loginVerify2FA.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(loginVerify2FA.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
        state.isUserLogout = false;
        state.errorMsg = action.error.message;
      });

    builder.addCase(resetLoginFlag.fulfilled, (state, action) => {
      state.user = {};
      state.loading = false;
      state.error = false;
      state.isUserLogout = true;
      state.errorMsg = "";
    });

    builder
      .addCase(resetPasswordAction.pending, (state) => {
        state.resetPasswordData.loading = true;
        state.resetPasswordData.error = false;
      })
      .addCase(resetPasswordAction.fulfilled, (state, action) => {
        state.resetPasswordData.loading = false;
        if (action.payload.isNewUser) {
          state.user = action.payload;
        }
      })
      .addCase(resetPasswordAction.rejected, (state, action) => {
        state.resetPasswordData.loading = false;
        state.resetPasswordData.error = true;
        state.resetPasswordData.errorMsg = action.error.message;
      });
  },
});
export const { logoutUserSuccess } = loginSlice.actions;
export default loginSlice.reducer;
