import React from "react";
import { Button, Input, UncontrolledTooltip } from "reactstrap";
import Datatable from "../../../Components/Common/Datatable";
import { useSelector } from "react-redux";

const AuraCasinoListTable = ({
  data,
  totalRows,
  handlePerRowsChange,
  handlePageChange,
  handleSort,
  setAuraCasino,
  setModal,
  handleSelection,
  setSelectedIds,
  setDeleteModal,
  updateAuraCasinoGameStatus,
}) => {
  const { modules } = useSelector((state) => ({
    modules: state.User?.modules,
  }));

  const handleToggle = (e) => {
    let value = { ...e };
    value.isActive = !value.isActive;
    updateAuraCasinoGameStatus(e._id, { isActive: !e.isActive });
  };

  const handleDelete = (e) => {
    setSelectedIds([e]);
    setDeleteModal(true);
  };

  const rowDisabledCriteria = (cell) => false;
  const columns = [
    {
      name: <span className="font-weight-bold fs-13">Name</span>,
      selector: (row) => (
        <React.Fragment>
          {row.displayIcon ? (
            <img src={row.displayIcon} alt={row.name} height="30" className="me-2 border" />
          ) : null}

          {row.name}
        </React.Fragment>
      ),
      sortable: true,
      fieldName: "name",
    },
    {
      name: <span className="font-weight-bold fs-13">Game Id</span>,
      selector: (row) => row.gameId,
      sortable: true,
      fieldName: "gameId",
    },
    {
      name: <span className="font-weight-bold fs-13">Game Launch Id</span>,
      selector: (row) => row.gameLaunch,
      sortable: true,
      fieldName: "gameLaunch",
    },
    {
      name: <span className="font-weight-bold fs-13">Status</span>,
      sortable: false,
      cell: (e) => {
        return (
          <React.Fragment>
            <div>
              <div className="form-check form-switch form-switch-right form-switch-md">
                <Input
                  className="form-check-input"
                  type="checkbox"
                  checked={e.isActive == true}
                  onChange={() => handleToggle(e)}
                />
              </div>
            </div>
          </React.Fragment>
        );
      },
    },
    {
      name: <span className="font-weight-bold fs-13">Action</span>,
      sortable: false,
      cell: (e) => {
        return (
          <React.Fragment>
            {modules?.["site-management-edit-aura-casino-button"] == 1 && (
              <React.Fragment>
                <Button
                  type="button"
                  className="btn  btn-icon waves-effect waves-light me-1"
                  id={`edit-${e._id}`}
                  onClick={() => {
                    setAuraCasino(e);
                    setModal(true);
                  }}
                >
                  <i className="ri-pencil-line"></i>
                </Button>
                <UncontrolledTooltip placement="bottom" target={`edit-${e._id}`}>
                  Edit Aura Casino
                </UncontrolledTooltip>
              </React.Fragment>
            )}
            {e._id && modules?.["site-management-delete-aura-casinos-button"] == 1 && (
              <React.Fragment>
                <Button
                  type="button"
                  className="btn btn-danger btn-icon   waves-effect waves-light me-1"
                  id={`delete-${e._id}`}
                  onClick={() => handleDelete(e._id)}
                >
                  <i className="ri-delete-bin-5-line"></i>
                </Button>
                <UncontrolledTooltip placement="bottom" target={`delete-${e._id}`}>
                  Delete Aura Casino
                </UncontrolledTooltip>
              </React.Fragment>
            )}
          </React.Fragment>
        );
      },
    },
  ];

  return (
    <React.Fragment>
      <Datatable
        columns={columns}
        data={data}
        totalRows={totalRows}
        handlePerRowsChange={handlePerRowsChange}
        handlePageChange={handlePageChange}
        handleSort={handleSort}
        handleSelection={handleSelection}
        rowDisabledCriteria={rowDisabledCriteria}
        selectableRows={true}
      />
    </React.Fragment>
  );
};

export default AuraCasinoListTable;
