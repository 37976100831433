import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getCompititions as getCompititionsAPI,
} from '../../../helpers/backend_helper';

export const getCompititions = createAsyncThunk('market/getCompititions', async (data) => {
  const { payload, params } = data;
  const response = await getCompititionsAPI(payload, params);
  return response.data;
});
