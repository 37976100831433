import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getLimitSettings,
  setLimitSettings,
  setFancySettings,
  getAccountLimitSettings,
  setAccountLimitSettings,
  setSportLimitSettings,
  getSportLimitSettings,
  deleteAccountLimitSetting,
  deleteSportLimitSetting,
} from "../../../helpers/backend_helper";
import { toast } from "react-toastify";

// // Market Limit
export const getMarketLimit = createAsyncThunk(
  "market/getMarketLimit",
  async (data) => {
    const response = await getLimitSettings();
    return response.data;
  }
);

export const getSportMarketLimit = createAsyncThunk(
  "market/getSportMarketLimit",
  async (data) => {
    const response = await getSportLimitSettings(data);
    return response.data;
  }
);

export const setMarketLimit = createAsyncThunk(
  "market/setMarketLimit",
  async (data) => {
    const response = await setLimitSettings(data.type, data.payload);
    return response.data;
  }
);

export const setSportMarketLimit = createAsyncThunk(
  "market/setSportMarketLimit",
  async (data) => {
    const response = await setSportLimitSettings(data);
    return response.data;
  }
);

export const deleteSportMarketLimit = createAsyncThunk(
  "market/deleteSportMarketLimit",
  async (data) => {
    return await deleteSportLimitSetting(data.id, data.type);
  }
);

export const getAccountMarketLimit = createAsyncThunk(
  "market/getAccountMarketLimit",
  async (data) => {
    const response = await getAccountLimitSettings(data.id);
    return response.data;
  }
);

export const setAccountMarketLimit = createAsyncThunk(
  "market/setAccountMarketLimit",
  async (data) => {
    const response = await setAccountLimitSettings(
      data.id,
      data.type,
      data.payload
    );
    return response.data;
  }
);

export const deleteAccountMarketLimit = createAsyncThunk(
  "market/deleteAccountMarketLimit",
  async (data) => {
    return await deleteAccountLimitSetting(data.id, data.type);
  }
);

export const setFancyLimit = createAsyncThunk(
  "market/setFancyLimit",
  async (data) => {
    const response = await setFancySettings(data.type, data.payload);
    return response.data;
  }
);
