import React from "react";
import DataTable from "react-data-table-component";

const CasinoAnalysisReportTable = ({
  data,
  totalRows,
  handlePerRowsChange,
  handlePageChange,
  handleSort,
  handleSelection,
}) => {
  const columns = [
    {
      name: <span className="font-weight-bold fs-13">Provider</span>,
      sortable: true,
      selector: (row) => row.providerName,
      fieldName: "providerName",
    },
    {
      name: <span className="font-weight-bold fs-13">P&L</span>,
      sortable: true,
      fieldName: "profitLoss",
      selector: (row) => row.profitLoss,
      cell: (row) => <span>{Math.round(row.profitLoss * 100) / 100}</span>,
    },
    {
      name: <span className="font-weight-bold fs-13">No of Clients</span>,
      sortable: true,
      fieldName: "totalUsers",
      selector: (row) => row.totalUsers,
    },
    {
      name: <span className="font-weight-bold fs-13">No of Rounds</span>,
      sortable: true,
      fieldName: "totalRounds",
      selector: (row) => row.totalRounds,
    },
  ];

  const rowDisabledCriteria = (cell) => false;

  return (
    <React.Fragment>
      <DataTable
        responsive={true}
        columns={columns}
        data={data}
        totalRows={totalRows}
        handlePerRowsChange={handlePerRowsChange}
        handlePageChange={handlePageChange}
        handleSort={handleSort}
        handleSelection={handleSelection}
        pagination
        sortable
        rowDisabledCriteria={rowDisabledCriteria}
      />
    </React.Fragment>
  );
};

export default CasinoAnalysisReportTable;
