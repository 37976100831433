import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import { isEmpty } from "lodash";

//import images
import avatar1 from "../../assets/images/users/avatar-1.jpg";
import { roundNumber } from "../constants/commonConstants";

const ProfileDropdown = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => ({
    user: state.User.user,
  }));

  //Dropdown Toggle
  const [isProfileDropdown, setIsProfileDropdown] = useState(false);
  const toggleProfileDropdown = () => {
    setIsProfileDropdown(!isProfileDropdown);
  };

  return (
    <React.Fragment>
      <Dropdown
        isOpen={isProfileDropdown}
        toggle={toggleProfileDropdown}
        className="ms-sm-3 header-item topbar-user"
      >
        <DropdownToggle tag="button" type="button" className="btn">
          <span className="d-flex align-items-center">
            <img className="rounded-circle header-profile-user" src={avatar1} alt="Header Avatar" />
            <span className="text-start ms-xl-2">
              <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">
                {user?.username}
              </span>
              <span className="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text">
                {user?.type}
                {"    "}
                <b>{user?.partnershipPercentage ? <>[{user?.partnershipPercentage}%]</> : ""}</b>
              </span>
              {user?.type != "SUPERADMIN" && (
                <span className="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text">
                  Balance:{" "}
                  <b>
                    {user?.userBalance?.balance
                      ? roundNumber(user?.userBalance?.balance)
                      : "0000.00"}
                  </b>
                </span>
              )}
            </span>
          </span>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <h6 className="dropdown-header">Welcome {user?.username}!</h6>
          <DropdownItem href={process.env.PUBLIC_URL + "/users/profile"}>
            <i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i>
            <span className="align-middle">Profile</span>
          </DropdownItem>
          <DropdownItem href={process.env.PUBLIC_URL + "/users/change-password"}>
            <i className="mdi mdi-message-text-outline text-muted fs-16 align-middle me-1"></i>{" "}
            <span className="align-middle">Change Password</span>
          </DropdownItem>
          <DropdownItem href={process.env.PUBLIC_URL + "/users/setting"}>
            <i className="mdi mdi-lock-open text-muted fs-16 align-middle me-1"></i>{" "}
            <span className="align-middle">Enable 2FA</span>
          </DropdownItem>
          <div className="dropdown-divider"></div>
          {user?.type != "SUPERADMIN" && (
            <DropdownItem href={process.env.PUBLIC_URL + "/pages-profile"}>
              <i className="mdi mdi-wallet text-muted fs-16 align-middle me-1"></i>{" "}
              <span className="align-middle">
                Balance :
                <b>
                  {user?.userBalance?.balance
                    ? Math.round(user?.userBalance?.balance * 100) / 100
                    : "0000.00"}
                </b>
              </span>
            </DropdownItem>
          )}
          <DropdownItem href={process.env.PUBLIC_URL + "/users/setting"}>
            <i className="mdi mdi-cog-outline text-muted fs-16 align-middle me-1"></i>{" "}
            <span className="align-middle">Settings</span>
          </DropdownItem>
          <DropdownItem href={process.env.PUBLIC_URL + "/auth-lockscreen-basic"}>
            <i className="mdi mdi-lock text-muted fs-16 align-middle me-1"></i>{" "}
            <span className="align-middle">Lock screen</span>
          </DropdownItem>
          <DropdownItem href={process.env.PUBLIC_URL + "/logout"}>
            <i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>{" "}
            <span className="align-middle" data-key="t-logout">
              Logout
            </span>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default ProfileDropdown;
