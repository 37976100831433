import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  getCasinos,
  createCasino as createCasinoAPI,
  updateCasino as updateCasinoAPI,
  deleteCasino as deleteCasinoAPI,
  getCasinoProviders,
  addCasinoProvider as addCasinoProviderAPI,
  updateCasinoProvider as updateCasinoProviderAPI,
  getCasinoGameTypes,
  addCasinoGameType as addCasinoGameTypeAPI,
  updateCasinoGameType as updateCasinoGameTypeAPI,
  getGameProviders as getGameProvidersAPI,
  getGameTypes as getGameTypesAPI,
  changeCasinoGameStatusApi,
  getAllCasinos,
} from "../../../helpers/backend_helper";

// Casinos -
export const getCasinoList = createAsyncThunk("Casinos/getCasinoList", async (payload) => {
  const response = await getCasinos(payload);
  return response.data;
});

export const createCasino = createAsyncThunk("Casinos/createCasino", async (payload) => {
  const response = await createCasinoAPI(payload);
  return response.data;
});

export const updateCasino = createAsyncThunk("Casinos/updateCasino", async ({ id, update }) => {
  const response = await updateCasinoAPI(id, update);
  return response.data;
});

export const changeCasinoGameStatus = createAsyncThunk(
  "Casinos/changeCasinoGameStatus",
  async ({ id, update }) => {
    const response = await changeCasinoGameStatusApi(id, update);
    return response.data;
  }
);

export const deleteCasino = createAsyncThunk("Casinos/deleteCasino", async (ids) => {
  const response = await deleteCasinoAPI(ids);
  return response.data;
});

// Casinos Providers
export const getCasinoProvidersList = createAsyncThunk(
  "Casinos/getCasinoProvidersList",
  async (payload) => {
    const response = await getCasinoProviders(payload);
    return response.data;
  }
);

export const addCasinoProvider = createAsyncThunk("Casinos/addCasinoProvider", async (payload) => {
  const response = await addCasinoProviderAPI(payload);
  return response.data;
});

export const updateCasinoProvider = createAsyncThunk(
  "Casinos/updateCasinoProvider",
  async ({ id, update }) => {
    const response = await updateCasinoProviderAPI(id, update);
    return response.data;
  }
);

export const getGameProviders = createAsyncThunk("Casinos/getGameProviders", async () => {
  const response = await getGameProvidersAPI();
  return response.data;
});

// export const deleteCasino = createAsyncThunk(
//   "Casinos/deleteCasino",
//   async (payload) => {
//     const response = await deleteCasinoAPI(payload);
//     return response.data;
//   }
// );

// Casinos Games Types Providers
export const getCasinoGameTypesList = createAsyncThunk(
  "Casinos/getCasinoGameTypesList",
  async (payload) => {
    const response = await getCasinoGameTypes(payload);
    return response.data;
  }
);

export const addCasinoGameType = createAsyncThunk("Casinos/addCasinoGameType", async (payload) => {
  const response = await addCasinoGameTypeAPI(payload);
  return response.data;
});

export const updateCasinoGameType = createAsyncThunk(
  "Casinos/updateCasinoGameType",
  async ({ id, update }) => {
    const response = await updateCasinoGameTypeAPI(id, update);
    return response.data;
  }
);

export const getGameTypes = createAsyncThunk("Casinos/getGameTypes", async () => {
  const response = await getGameTypesAPI();
  return response.data;
});

export const getallCasinoList = createAsyncThunk("Casinos/getAllCasinoList", async () => {
  const response = await getAllCasinos();
  return response.data;
});
