import { createSlice } from "@reduxjs/toolkit";

import {
  transactionHistory,
  decreaseCreditAmount,
  disable2FA,
  enable2FA,
  getBankDetails,
  getSingleUserDetails,
  getUserLoginActivity,
  getUserMenu,
  getUserModule,
  getUserProfile,
  resetPassword,
  resetVerify2FA,
  updateCreditAmount,
  updatePassword,
  updateTransactionCode,
  verify2FA,
} from "./action";
import { toast } from "react-toastify";

const initialState = {
  user: {},
  isverify2FA: false,
  modules: {},
  authRoutes: {},
  menu: [],
  menuState: {},
  bankDetailsData: {},
  singleUserDetail: {},
  userWithSameIp: 0,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setMenuState: (state, action) => {
      for (const k in state.menuState) {
        if (Object.hasOwnProperty.call(state.menuState, k)) {
          state.menuState[k] = false;
        }
      }
      state.menuState[action.payload] = true;
    },
    resetSingleUserDetail: (state, action) => {
      state.singleUserDetail = {};
    },
  },
  extraReducers: (b) => {
    b.addCase(getUserProfile.fulfilled, (state, action) => {
      state.user = action.payload;
    });

    b.addCase(getBankDetails.fulfilled, (state, action) => {
      state.bankDetailsData = action.payload;
    });

    b.addCase(verify2FA.fulfilled, (state, action) => {
      state.user.enable2FA = true;
      state.isverify2FA = true;
    }).addCase(verify2FA.rejected, (state, action) => {
      state.error = action.error;
      state.isverify2FA = false;
    });

    b.addCase(updateCreditAmount.fulfilled, (state, action) => {
      toast.success(action.payload.message);
    }).addCase(updateCreditAmount.rejected, (state, action) => {
      toast.error(
        action?.error?.message
          ? action.error.message
          : "Cannot update Credit. Please try again"
      );
    });
    b.addCase(decreaseCreditAmount.fulfilled, (state, action) => {
      toast.success(action.payload.message);
    }).addCase(decreaseCreditAmount.rejected, (state, action) => {
      toast.error(
        action?.error?.message
          ? action.error.message
          : "Cannot update Credit. Please try again"
      );
    });
    b.addCase(transactionHistory.rejected, (state, action) => {
      toast.error(
        action?.error?.message
          ? action.error.message
          : "Cannot fetch transactions. Please try again"
      );
    });

    b.addCase(disable2FA.fulfilled, (state, action) => {
      state.user.enable2FA = false;
      state.isverify2FA = true;
    }).addCase(disable2FA.rejected, (state, action) => {
      state.error = action.payload;
      state.isverify2FA = false;
    });

    b.addCase(enable2FA.fulfilled, (state, action) => {
      state.secret = action.payload;
    })
      .addCase(resetVerify2FA.fulfilled, (state, action) => {
        state.isverify2FA = false;
      })
      .addCase(getUserModule.fulfilled, (state, action) => {
        state.modules = action.payload.data?.modules;
      })
      .addCase(getUserMenu.fulfilled, (state, action) => {
        state.menu = action.payload.data.menu;
        state.modules = action.payload.data.constants;
        state.authRoutes = action.payload.data.authRoutes;
      });
    b.addCase(resetPassword.fulfilled, (state, action) => {
      toast.success(action.payload.message);
      state.user = action.payload.data;
    }).addCase(resetPassword.rejected, (state, action) => {
      toast.error(
        action?.error?.message
          ? action.error.message
          : "Unable to change the password!"
      );
    });
    b.addCase(updatePassword.fulfilled, (state, action) => {
      toast.success(action.payload.message);
    }).addCase(updatePassword.rejected, (state, action) => {
      toast.error(
        action?.error?.message
          ? action.error.message
          : "Unable to change the password!"
      );
    });
    b.addCase(updateTransactionCode.fulfilled, (state, action) => {
      toast.success(action.payload.message);
    }).addCase(updateTransactionCode.rejected, (state, action) => {
      toast.error(
        action?.error?.message
          ? action.error.message
          : "Unable to change the transaction code!"
      );
    });
    b.addCase(getUserLoginActivity.fulfilled, (state, action) => {
      state.userWithSameIp = action.payload.count;
    });

    b.addCase(getSingleUserDetails.fulfilled, (state, action) => {
      return {
        ...state,
        singleUserDetail: action?.payload,
      };
    });
  },
});

export const { setMenuState, resetSingleUserDetail } = userSlice.actions;

export default userSlice.reducer;
