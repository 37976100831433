//REGISTER
// export const POST_FAKE_REGISTER = '/auth/signup';
import { api } from "../config";

//IP
export const GET_IP_LIST = "/v1/site-management/blacklist-ip/ip-list";
export const ADD_BLACKLIST_API = "/v1/site-management/blacklist-ip";
export const DELETE_BLACKLIST_API = "/v1/site-management/blacklist-ip";

//LOGIN
export const GET_IP =
  process.env.REACT_APP_LOCATION_URL ?? "https://ipapi.co/json/";

export const AUTH_LOGIN = "/v1/auth/login";
export const AUTH_VERIFY2FA = "/v1/auth/verify2fa";
export const AUTH_REFERSH_TOKEN = "/v1/auth/refresh-token";
export const RESET_USER_NEW_PASSWORD = "/v1/auth/password/reset";

export const GET_USER_PROFILE = "/v1/users/me/profile";
export const ENABLE_2FA = "/v1/users/me/enable2FA";
export const VERIFY_2FA = "/v1/users/me/verify2FA";
export const DISABLE_2FA = "/v1/users/me/disable2FA";
export const RESET_USER_PASSWORD = "/v1/users/me/password/reset";
export const UPDATE_USER = "/v1/users";

//Accounts
export const UPDATE_USER_PASSWORD = "/v1/users/password/update";
export const UPDATE_USER_TRANSACTION_CODE = "/v1/users/transaction-code/update";
export const UPDATE_USER_LOCK = "/v1/users/lock/update";
export const UPDATE_CREDIT_USER = "/v1/users/update-credit";
export const DECREASE_CREDIT_USER = "/v1/users/decrease-credit";
export const TRANSACTION_HISTORY = "/v1/users/transaction-history";
//ROLE
export const GET_ROLE_SETTINGS = "/v1/roles/settings";
export const GET_ROLE_LIST = "/v1/roles";
export const ADD_NEW_ROLE = "/v1/roles";
export const UPDATE_ROLE = "/v1/roles";
export const DELETE_ROLE = "/v1/roles";
export const GET_ROLE_DETAIL = (id) => `/v1/roles/${id}`;
export const GET_MODULE_LIST = "/v1/modules";
export const GET_ROLE_DROPDOWN_LIST = "/v1/roles/get-roles-list";

//ADMIN
export const GET_ADMIN_TYPES = "/v1/users/types";
export const GET_ADMIN_LIST = "/v1/users";
export const DOWNLOAD_ADMIN_LIST_PDF = "/v1/users/download/pdf";
export const DOWNLOAD_ADMIN_LIST_EXCEL = "/v1/users/download/excel";
export const DOWNLOAD_ADMIN_LIST_CSV = "/v1/users/download/csv";
export const DOWNLOAD_ADMIN_LIST_REPORT = (type) =>
  `/v1/users/download/accounts/${type}`;
export const ADD_NEW_ADMIN = "/v1/users";
export const CHECK_USER_MOBILE = (mobile) =>
  `/v1/users/mobile-exists/${mobile}`;
export const DELETE_ADMIN = "/v1/users";
export const UPDATE_ADMIN = "/v1/users";

//SUB-ADMIN

export const ADD_NEW_SUB_ADMIN = "/v1/sub-admin";
export const GET_SUB_ADMIN_LIST = "/v1/sub-admin";
export const UPDATE_SUB_ADMIN = "/v1/sub-admin";
export const DELETE_SUB_ADMIN = "/v1/sub-admin";
export const UPDATE_SUB_ADMIN_PASSWORD = "/v1/sub-admin/password/update";

//API Setting
export const GET_EXCHAGE_TABLE = "/v1/api/setting/tables";
export const GET_ALL_API_SETTINGS = "/v1/api/setting";
export const UPDATE_API_SETTINGS = (id) => `/v1/api/setting/${id}`;
export const GET_API_KEY = "/v1/api/provider";
export const SET_API_KEY = "/v1/api/provider";
export const UPDATE_API_PROVIDER = (id) => `/v1/api/provider/${id}`;
export const DELETE_API_PROVIDER = (id) => `/v1/api/provider/${id}`;
export const ACTIVATE_API_PROVIDER = (id) => `/v1/api/provider/${id}/active`;

//Sport
export const GET_SPORT_TYPES = "v1/sports/types";
export const GET_EVENT_RULE = ({ eventTypeId }) =>
  `v1/sports/types/${eventTypeId}`;
export const GET_EVENT_TYPE_LIST = "v1/sports/types/list";
export const PUT_SPORT_TYPE_UPDATE = (eventTypeId) =>
  `v1/sports/types/${eventTypeId}`;
export const CREATE_EVENT_RULES = (eventTypeReferenceId) =>
  `v1/sports/types/rules/${eventTypeReferenceId}`;

export const GET_COMPITITION_LIST = "v1/sports/compititions";

export const POST_EVENT_REFERESH = "v1/api/import/events";
export const GET_EVENTS_LIST = "v1/sports/events";
export const POST_EVENTS_BETLOCK = "v1/sports/events/betlock";
export const POST_EVENT_IMPORT = `${api.FRONT_API_URL}/v1/events/import`;

//Market
export const GET_MARKET_LIST = "v1/sports/markets";
export const GET_MARKET_STATUS = (eventId) =>
  `v1/sports/markets/status/${eventId}`;
export const SET_MARKET_LIST = "v1/sports/markets";
export const POST_MARKET_REFRESH = `v1/sports/markets`;
export const POST_MARKET_STATUS = `v1/sports/markets/status`;

// Market Limit
export const GET_MARKET_LIMIT = `v1/site-management/market-limit`;
export const SET_MARKET_LIMIT = (type) =>
  `v1/site-management/market-limit/${type}`;
export const GET_SPORT_MARKET_LIMIT = (sport) =>
  `v1/sports/limit-settings/get/${sport}`;
export const SET_SPORT_MARKET_LIMIT = (type) =>
  `v1/sports/limit-settings/${type}`;
export const DELETE_SPORT_MARKET_LIMIT = (type) =>
  `v1/sports/limit-settings/delete/${type}`;
export const GET_ACCOUNT_MARKET_LIMIT = (id) =>
  `v1/accounts/limit-settings/${id}`;
export const SET_ACCOUNT_MARKET_LIMIT = (id, type) =>
  `v1/accounts/limit-settings/${id}/${type}`;
export const DELETE_ACCOUNT_MARKET_LIMIT = (id, type) =>
  `v1/accounts/limit-settings/${id}/${type}`;

// Payment Mode
export const GET_PAYMENT_MODE = "v1/site-management/payment-mode";
export const SET_PAYMENT_MODE = "v1/site-management/payment-mode";
export const PUT_PAYMENT_MODE = (id) => `v1/site-management/payment-mode/${id}`;
export const DELETE_PAYMENT_MODE = "v1/site-management/payment-mode";

//sitemanagement Rules
export const ADD_SITE_RULES = "v1/site-management/rules";
export const GET_SITE_RULES = "v1/site-management/rules";
export const UPDATE_SITE_RULES = (id) => `v1/site-management/rules/${id}`;
export const DELETE_SITE_RULES = (id) => `v1/site-management/rules/${id}`;

// Casinos
export const GET_CASINOS = "v1/site-management/casinos";
export const POST_CASINOS = "v1/site-management/casinos";
export const PUT_CASINOS = (id) => `v1/site-management/casinos/${id}`;
export const PATCH_CASINO = (id) => `v1/site-management/casinos/${id}`;
export const DELETE_CASINOS = `v1/site-management/casinos/deleteCasinoGame`;

// Aura Casinos
export const GET_AURA_CASINOS = "v1/site-management/aura-casinos";
export const POST_AURA_CASINOS = "v1/site-management/aura-casinos";
export const PUT_AURA_CASINOS = (id) => `v1/site-management/aura-casinos/${id}`;
export const PATCH_AURA_CASINO = (id) =>
  `v1/site-management/aura-casinos/${id}`;
export const DELETE_AURA_CASINOS = `v1/site-management/aura-casinos/deleteAuraCasinoGame`;

//All Casinos
export const GET_ALL_CASINOS = "v1/site-management/casinos/allcasinolist";

// Casino Providers
export const GET_CASINO_PROVIDERS = "v1/site-management/casinos/providers";
export const POST_CASINO_PROVIDERS = "v1/site-management/casinos/providers";
export const PUT_CASINO_PROVIDERS = (id) =>
  `v1/site-management/casinos/providers/${id}`;
export const DELETE_CASINO_PROVIDERS = "v1/site-management/casinos/providers";
export const GET_CASINO_PROVIDERS_LIST =
  "v1/site-management/casinos/providers-list";

// Casino Game Types
export const GET_CASINO_GAME_TYPES = "v1/site-management/casinos/game-types";
export const POST_CASINO_GAME_TYPES = "v1/site-management/casinos/game-types";
export const PUT_CASINO_GAME_TYPES = (id) =>
  `v1/site-management/casinos/game-types/${id}`;
export const DELETE_CASINO_GAME_TYPES = "v1/site-management/casinos/game-types";
export const GET_CASINO_GAME_TYPES_LIST =
  "v1/site-management/casinos/game-types-list";

// Stake Button
export const GET_STAKE_BUTTON = "v1/site-management/stake-button";
export const SET_STAKE_BUTTON = "v1/site-management/stake-button";
export const PUT_STAKE_BUTTON = (id) => `v1/site-management/stake-button/${id}`;
export const DELETE_STAKE_BUTTON = "v1/site-management/stake-button";

// Deposit
export const GET_PENDING_DEPOSIT = "v1/payment/deposit/pending";
export const GET_PENDING_DEPOSIT_LIST = "v1/payment/deposit";
export const POST_PROCESS_PAYMENT = "v1/payment/deposit/process";

export const POST_DEPOSIT_AMOUNT = "v1/payment/deposit/amount";
export const POST_WITHDRAWAL_AMOUNT = "v1/payment/withdrawal/amount";

//withdrawal
export const GET_WITHDRAWAL_DETAILS =
  "v1/payment/withdrawal/get-withdraw-details";

export const UPDATE_WITHDRAWAL_STATUS =
  "v1/payment/withdrawal/update-withdraw-status";
export const GET_VERIFIED_WITHDRAWAL =
  "v1/payment/withdrawal/get-verified-withdraw";
export const GET_APPROVED_WITHDRAWAL =
  "v1/payment/withdrawal/get-approved-withdrawal";
export const GET_APPROVED_VERIFIED_DEPOSIT =
  "v1/payment/deposit/get-approved-verified-deposits";

// Common
export const POST_SIGNED_IMAGE_URL = "v1/common/file-url";

// Open Bets
export const GET_OPEN_BETS = "v1/reports/open-bets";
export const GET_VOID_BETS = "v1/reports/void-bets";
export const GET_BET_HISTORY = "v1/reports/bet-history";
export const POST_VOID_OPEN_BETS = "v1/reports/open-bets";
export const GET_SEARCH_CLIENTS = (search) =>
  "v1/users/select-search/" + search;
export const POST_LIVE_CASINO_REPORT = "v1/reports/live-casino";
export const AURA_CASINO_REPORT = "v1/reports/aura-casino";
export const CASINO_ANALYSIS_REPORT = "v1/reports/casino-analysis";

//Reports
export const GET_SEARCH_USER = "v1/reports/withdraws/withdrawal-history";
export const DOWNLOAD_WITHDRAW_REPORT = (type) =>
  `v1/reports/withdraws/download/${type}`;
export const GET_SEARCH_DEPOSITS = "v1/reports/deposits/deposits-history";
export const DOWNLOAD_DEPOSIT_REPORT = (type) =>
  `v1/reports/deposits/download/${type}`;
export const GET_DOWNLOADS_EXCEL = "v1/payment/deposit/download/excel";
export const GET_ACCOUNT_STATEMENT = "v1/reports/transaction-history";
export const DOWNLOAD_ACCOUNT_STATEMENT = (type) =>
  `v1/reports/download/${type}`;
export const DOWNLOAD_BETS_REPORT = (type) =>
  `v1/reports/bets/download/${type}`;
export const DOWNLOAD_CASINO_REPORT = (type) =>
  `v1/reports/casino-analysis/download/${type}`;

//KYC
export const GET_KYC_LIST = "v1/users/kyc";
export const GET_SIGNED_IMAGE = "v1/users/kyc/image";
export const APPROVE_KYC = "v1/users/kyc/approve";
export const DECLINE_KYC = "v1/users/kyc/decline";
export const GET_USER_ALL_DETAILS = "v1/users/all-details";
export const USER_LOGIN_ACTIVITY = (userId) => `v1/users/activity/${userId}`;
export const USER_MODULES = (id) => "v1/modules/" + id;
export const USER_MENU = (id) => "v1/modules/menu/" + id;

// ANALYSIS
export const GET_INPLAY_MATCHES = "v1/analysis/risk-analysis/inplay";
export const GET_ALL_MATCHES = "v1/analysis/risk-analysis/all-matches";
export const GET_EVENTS_BETS = "v1/analysis/risk-analysis/events-bets";
export const GET_EVENT_MARKET = (id) =>
  "v1/analysis/risk-analysis/event-markets/" + id;
export const GET_EVENT_BETS = (id) =>
  "v1/analysis/risk-analysis/event-bets/" + id;
export const GET_OPEN_BET_MARKETS = (id) =>
  "v1/analysis/risk-analysis/open-bets-markets/" + id;
export const GET_MARKET_BETS = "v1/analysis/risk-analysis/market-bets";
export const GET_EVENTS_ODDS = "v1/analysis/risk-analysis/event-odds";
export const GET_PL_ANALYSIS = "v1/analysis/pl-analysis";
export const GET_PL_MARKET = "v1/analysis/pl-market";
export const GET_PL_USER_BETS = (marketId) =>
  `v1/analysis/pl-market/user-bets/${marketId}`;
export const GET_PL_MARKET_TYPES = "v1/analysis/pl-market/market-types";
export const GET_PL_EVENT_TYPES = "v1/analysis/pl-market/event-types";

// banks details related
export const GET_USER_BANK_DETAILS = (userId) =>
  `v1/bank-details/get-bank-details/${userId}`;

//DASHBOARD
export const GET_DASHBOARD_DEPOSITS = "v1/dashboard/deposit";
export const GET_DASHBOARD_WITHDRAWALS = "v1/dashboard/withdrawal";
export const GET_SINGLE_USER_DETAIL = "/v1/users/get-single-user";
export const GET_PL_GRAPH_DATA = "v1/dashboard/pl-graph";
export const GET_CREDIT_GRAPH_DATA = "v1/dashboard/credit-graph";

// General Settings
export const GET_GENERAL_SETTINGS = (name) =>
  `v1/site-management/general-settings/${name}`;
export const PATCH_GENERAL_SETTINGS = (name) =>
  `v1/site-management/general-settings/${name}`;
