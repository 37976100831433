import { createSlice } from "@reduxjs/toolkit";
import { getEvents } from "./action";
import { toast } from "react-toastify";

const initialState = {
  events: [],
  totalRows: 0,
};

const Events = createSlice({
  name: "events",
  initialState,
  reducers: {},
  extraReducers: (b) => {
    b.addCase(getEvents.fulfilled, (state, action) => {
      state.events = action.payload.items ? action.payload.items : [];
      state.totalRows = action.payload.total ? action.payload.total : 0;
    });

  },
});

export default Events.reducer;
