import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getRoleList as getRoleListApi,
  addNewRole as addNewRoleApi,
  updateRole as updateRoleApi,
  deleteRole as deleteRoleApi,
  getRoleSetting as getRoleSettingApi,
  moduleListApi,
  getRoleDetailApi,
  getRoleDropDownListApi,
} from "../../../helpers/backend_helper";

import { toast } from "react-toastify";

export const getRoleSettings = createAsyncThunk(
  "Role/getRoleSettings",
  async () => {
    return await getRoleSettingApi();
  }
);

export const getRoleList = createAsyncThunk(
  "Role/getRoleList",
  async (params) => {
    const response = await getRoleListApi(params);
    return response.data;
  }
);

export const getRoleDropdownList = createAsyncThunk(
  "Role/getRoleDropdownList",
  async () => {
    const response = await getRoleDropDownListApi();
    return response.data;
  }
);

export const updateRole = createAsyncThunk("Role/updateRole", (role) => {
  return new Promise((res, rej) => {
    updateRoleApi(role)
      .then((response) => {
        toast.success(response.data.message);
        res(true);
      })
      .catch((error) => {
        res(false);
        toast.error("Role Updated Failed");
      });
  });
});

export const addNewRole = createAsyncThunk("Role/addNewRole", (role) => {
  return new Promise((res, rej) => {
    addNewRoleApi(role)
      .then((response) => {
        toast.success(response.data.message);
        res(true);
      })
      .catch((error) => {
        toast.error(error);
        res(false);
      });
  });
});

export const deleteRole = createAsyncThunk("Role/deleteRole", (role) => {
  return new Promise((res, rej) => {
    deleteRoleApi(role)
      .then((response) => {
        toast.success(response.data.message);
        res(true);
      })
      .catch((error) => {
        toast.error(error);
        res(false);
      });
  });
});

export const getModuleList = createAsyncThunk(
  "Role/getModuleList",
  async () => {
    const data = await moduleListApi();
    return data;
  }
);

export const getRoleDetail = createAsyncThunk(
  "Role/getRoleDetail",
  async (roleId) => {
    return await getRoleDetailApi(roleId);
  }
);
