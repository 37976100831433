import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  getAuraCasinos,
  createAuraCasino as createAuraCasinoAPI,
  updateAuraCasino as updateAuraCasinoAPI,
  deleteAuraCasino as deleteAuraCasinoAPI,
  changeAuraCasinoGameStatusApi,
  getAllAuraCasinos,
} from "../../../helpers/backend_helper";

// Casinos -
export const getAuraCasinoList = createAsyncThunk(
  "Casinos/getAuraCasinoList",
  async (payload) => {
    const response = await getAuraCasinos(payload);
    return response.data;
  }
);

export const createAuraCasino = createAsyncThunk(
  "Casinos/createAuraCasino",
  async (payload) => {
    const response = await createAuraCasinoAPI(payload);
    return response.data;
  }
);

export const updateAuraCasino = createAsyncThunk(
  "Casinos/updateAuraCasino",
  async ({ id, update }) => {
    const response = await updateAuraCasinoAPI(id, update);
    return response.data;
  }
);

export const changeAuraCasinoGameStatus = createAsyncThunk(
  "Casinos/changeAuraCasinoGameStatus",
  async ({ id, update }) => {
    const response = await changeAuraCasinoGameStatusApi(id, update);
    return response.data;
  }
);

export const deleteAuraCasino = createAsyncThunk(
  "Casinos/deleteAuraCasino",
  async (ids) => {
    const response = await deleteAuraCasinoAPI(ids);
    return response.data;
  }
);
