import { APIHelper, setAuthorization } from "../../api_helper";
import * as url from "../../url_helper";

//Post
export const postLogin = (data) => APIHelper.post(url.AUTH_LOGIN, data);
export const loginVerify2FA = (data) =>
  APIHelper.post(url.AUTH_VERIFY2FA, data);

export const postRefreshToken = (data) =>
  APIHelper.post(url.AUTH_REFERSH_TOKEN, data);
export const resetUserPassword = (data, token) => {
  setAuthorization(token);
  return APIHelper.patch(url.RESET_USER_NEW_PASSWORD, data);
};

export const getIP = () => APIHelper.get(url.GET_IP);
