import { createSlice, current } from "@reduxjs/toolkit";
import {
  addNewAdmin,
  addNewSubAdmin,
  deleteSubAdmin,
  downloadAdminListCSVFile,
  getAdminList,
  getAdminTypes,
  getSubAdminList,
  updateSubAdminDetail,
  updateSubAdminPassword,
  updateUserLock,
} from "./action";
import { toast } from "react-toastify";

const initialState = {
  adminList: [],
  adminCount: 0,
  subAdminCount: 0,
  adminType: [],
  error: "",
  subAdminList: [],
};

const adminsSlice = createSlice({
  name: "Admins",
  initialState,
  reducers: {
    setAdminsList: (state, action) => {
      state.adminList = action.payload.items;
      state.adminCount = action.payload.total;
    },
    setSubAdminsList: (state, action) => {
      state.subAdminList = action.payload.items;
      state.subAdminCount = action.payload.total;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
  extraReducers: (b) => {
    b.addCase(getAdminList.fulfilled, (state, action) => {
      state.adminList = action.payload.items;
      state.adminCount = action.payload.total;
    });

    b.addCase(getSubAdminList.fulfilled, (state, action) => {
      state.subAdminList = action.payload.items;
      state.subAdminCount = action.payload.total;
    });

    b.addCase(getAdminTypes.fulfilled, (state, action) => {
      state.adminType = action.payload;
    });

    b.addCase(addNewSubAdmin.fulfilled, (state, action) => {
      toast.success(action.payload.message);
    }).addCase(addNewSubAdmin.rejected, (state, action) => {
      toast.error(
        action?.error?.message
          ? action.error.message
          : "Failed to add sub admin"
      );
    });

    b.addCase(updateSubAdminDetail.fulfilled, (state, action) => {
      toast.success(action.payload.data.message);
    }).addCase(updateSubAdminDetail.rejected, (state, action) => {
      toast.error(
        action?.error?.message
          ? action.error.message
          : "Failed to update sub admin"
      );
    });

    b.addCase(deleteSubAdmin.fulfilled, (state, action) => {
      toast.success(action.payload.data.message);
    }).addCase(deleteSubAdmin.rejected, (state, action) => {
      toast.error(
        action?.error?.message
          ? action.error.message
          : "Failed to delete sub admin"
      );
    });

    b.addCase(addNewAdmin.fulfilled, (state, action) => {
      toast.success(`${action.payload.message}`);
    }).addCase(addNewAdmin.rejected, (state, action) => {
      toast.error(
        action?.error?.message ? action.error.message : "Failed to add admin"
      );
    });

    b.addCase(downloadAdminListCSVFile.fulfilled, (state, action) => {
      toast.success(`File Downloaded Successfully`);
    }).addCase(downloadAdminListCSVFile.rejected, (state, action) => {
      toast.error(
        action?.error?.message
          ? action.error.message
          : "Failed to download file"
      );
    });

    b.addCase(updateUserLock.fulfilled, (state, action) => {
      if (action.payload) {
        toast.success(action.payload.message);
      }
      return {
        ...state,

        adminList: state.adminList.map((cp) => {
          return cp._id.toString() === action.payload.response._id.toString()
            ? { ...cp, ...action.payload.response }
            : cp;
        }),
      };
    }).addCase(updateUserLock.rejected, (state, action) => {
      toast.error(
        action?.error?.message
          ? action.error.message
          : "Unable to change the Lock!"
      );
    });

    b.addCase(updateSubAdminPassword.fulfilled, (state, action) => {
      if (action.payload) {
        toast.success(action.payload.data.message);
      }
    });
  },
});

export const { setAdminsList, setError, setSubAdminsList } =
  adminsSlice.actions;

export default adminsSlice.reducer;
