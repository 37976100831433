import { createSlice } from "@reduxjs/toolkit";
import {
  getPaymentModeList,
  createPaymentMode,
  updatePaymentMode,
  deletePaymentMode,
} from "./action";
import { toast } from "react-toastify";

const initialState = {
  paymentModes: [],
  totalRows: 0,
};

const PaymentMode = createSlice({
  name: "PaymentMode",
  initialState,
  reducers: {},
  extraReducers: (b) => {
    b.addCase(getPaymentModeList.fulfilled, (state, action) => {
      state.paymentModes = action.payload?.items;
      state.totalRows = action.payload?.total;
    });
    b.addCase(createPaymentMode.fulfilled, (state, action) => {
      toast.success(action.payload.message);
    }).addCase(createPaymentMode.rejected, (state, action) => {
      if (action.error) {
        toast.error(action?.error?.message);
      }
    });
    b.addCase(deletePaymentMode.fulfilled, (state, action) => {
      toast.success(action.payload.message);
    });
  },
});

export default PaymentMode.reducer;
