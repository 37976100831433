import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getUserMenu,
  getUserModule,
  getUserProfile,
  logoutUser,
} from "../store/actions";
import { setMenuState } from "../store/user/reducer";
import { getLoggedinUser } from "../helpers/api_helper";
import socket from "../sockets/socket";

const Navdata = () => {
  const intervalRef = useRef(null);

  const history = useNavigate();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //state data
  const [finalMenu, setFinalMenu] = useState([]);
  const [iscurrentState, setIscurrentState] = useState({});

  const { menu, userId, userProfileId, currentState, User } = useSelector(
    (state) => ({
      menu: state.User.menu,
      userId: state.Login?.user?._id,
      userProfileId: state.User?.user?._id,
      User: state.User?.user,
      currentState: state.User.menuState,
    })
  );

  function updateIconSidebar(e, id) {
    if (e && e.target && e.target.getAttribute("subitems")) {
      const ul = document.getElementById("two-column-menu");
      const iconItems = ul.querySelectorAll(".nav-icon.active");
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        let someid = item.getAttribute("subitems");
        if (document.getElementById(someid))
          document.getElementById(someid).classList.remove("show");
      });
    }
    document.getElementById(id).classList.add("show");
  }

  useEffect(() => {
    let temp = [];
    if (menu.length == 0) return;
    menu.map((a) => {
      dispatch(setMenuState(a.id));
    });
    menu.map((a) => {
      let x = JSON.parse(JSON.stringify(a));
      x.stateVariables = currentState[a.id];
      x.click = function (e) {
        e.preventDefault();
        updateIconSidebar(e, a.id);
        dispatch(setMenuState(a.id));
      };
      temp.push(x);
    });
    setFinalMenu(temp);
  }, [menu]);

  useEffect(() => {
    dispatch(getUserProfile());
  }, []);

  useEffect(() => {
    const isLoggedIn = getLoggedinUser();
    socket.on("logout-user", (id) => {
      if (isLoggedIn && userProfileId === id)
        dispatch(logoutUser()).then((r) => {
          if (r.payload) navigate("/");
        });
    });
    return () => {
      socket.off("logout-user");
    };
  }, [dispatch, userProfileId]);
  useEffect(() => {
    if (userId || userProfileId) {
      dispatch(getUserMenu(userId || userProfileId));
      intervalRef.current = setInterval(() => {
        dispatch(getUserProfile());
      }, 5000);
    }

    document.body.classList.remove("twocolumn-panel");
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [userId, userProfileId]);

  const menuItems = [
    {
      label: "Menu",
      isHeader: true,
    },
    ...finalMenu,
  ];

  return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
