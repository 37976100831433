import { APIHelper } from "../../api_helper";
import * as url from "../../url_helper";

export const getAllRevenueData = (data) => {
  return [
    {
      name: "Orders",
      type: "area",
      data: [34, 65, 46, 68, 49, 61, 42, 44, 78, 52, 63, 67],
    },
    {
      name: "Earnings",
      type: "bar",
      data: [
        89.25, 98.58, 68.74, 108.87, 77.54, 84.03, 51.24, 28.57, 92.57, 42.36,
        88.51, 36.57,
      ],
    },
    {
      name: "Refunds",
      type: "line",
      data: [8, 12, 7, 17, 21, 11, 5, 9, 7, 29, 12, 35],
    },
  ];
};
// APIHelper.post(url.POST_AUTH_LOGIN, data);

export const getDepositsDataAPI = (params) => {
  return APIHelper.post(url.GET_DASHBOARD_DEPOSITS, params);
};

export const getWithdrawalsDataAPI = (params) => {
  return APIHelper.post(url.GET_DASHBOARD_WITHDRAWALS, params);
};

export const getPLGraphAPI = () => {
  return APIHelper.get(url.GET_PL_GRAPH_DATA);
};

export const getCreditGraphAPI = () => {
  return APIHelper.get(url.GET_CREDIT_GRAPH_DATA);
};
