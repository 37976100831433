import { createAsyncThunk } from "@reduxjs/toolkit";

export const changeLoader = createAsyncThunk("Layout/changeLoader", (value) => {
  return value;
});

export const changeLayout = createAsyncThunk(
  "Layout/changeLayout",
  (layout) => {
    try {
      if (layout === "twocolumn") {
        document.documentElement.removeAttribute("data-layout-width");
      } else if (layout === "horizontal") {
        document.documentElement.removeAttribute("data-sidebar-size");
      }
      changeHTMLAttribute("data-layout", layout);
    } catch (error) {
      // console.log(error);
    }
  }
);

export const changeLayoutMode = createAsyncThunk(
  "Layout/changeLayoutMode",
  (mode) => {
    try {
      changeHTMLAttribute("data-layout-mode", mode);
      return mode;
    } catch (error) {
      // console.log(error);
    }
  }
);
export const changeSidebarTheme = createAsyncThunk(
  "Layout/changeSidebarTheme",
  (theme) => {
    try {
      changeHTMLAttribute("data-sidebar", theme);
    } catch (error) {
      // console.log(error);
    }
  }
);

export const changeLayoutWidth = createAsyncThunk(
  "Layout/changeLayoutWidth",
  (layoutWidth) => {
    try {
      if (layoutWidth === "lg") {
        changeHTMLAttribute("data-layout-width", "fluid");
      } else {
        changeHTMLAttribute("data-layout-width", "boxed");
      }
      // changeHTMLAttribute("data-sidebar-size", layoutWidth);
    } catch (error) {
      // console.log(error);
    }
  }
);

export const changeLayoutPosition = createAsyncThunk(
  "Layout/changeLayoutPosition",
  (layoutposition) => {
    try {
      changeHTMLAttribute("data-layout-position", layoutposition);
    } catch (error) {
      // console.log(error);
    }
  }
);

export const changeTopbarTheme = createAsyncThunk(
  "Layout/changeTopbarTheme",
  (topbarTheme) => {
    try {
      changeHTMLAttribute("data-topbar", topbarTheme);
    } catch (error) {
      // console.log(error);
    }
  }
);

export const changeLeftsidebarSizeType = createAsyncThunk(
  "Layout/changeLeftsidebarSizeType",
  (leftsidebarSizetype) => {
    try {
      switch (leftsidebarSizetype) {
        case "lg":
          changeHTMLAttribute("data-sidebar-size", "lg");
          break;
        case "md":
          changeHTMLAttribute("data-sidebar-size", "md");
          break;
        case "sm":
          changeHTMLAttribute("data-sidebar-size", "sm");
          break;
        case "sm-hover":
          changeHTMLAttribute("data-sidebar-size", "sm-hover");
          break;
        default:
          changeHTMLAttribute("data-sidebar-size", "lg");
      }
    } catch (error) {
      // console.log(error);
    }
  }
);

export const changeLeftsidebarViewType = createAsyncThunk(
  "Layout/changeLeftsidebarViewType",
  (leftsidebarViewtype) => {
    try {
      changeHTMLAttribute("data-layout-style", leftsidebarViewtype);
    } catch (error) {
      // console.log(error);
    }
  }
);

export const resetValue = (value) => ({});

function changeHTMLAttribute(attribute, value) {
  if (document.documentElement)
    document.documentElement.setAttribute(attribute, value);
  return true;
}
