import { createSlice } from "@reduxjs/toolkit";
import {
  pendingDepositTotal,
  getPendingDeposit,
  processPayment,
  getWithdrawDetails,
  updateWithDrawStatus,
  getVerifiedWithdrawList,
  getApprovedWithdrawal,
  getApprovedVerifiedDeposit,
  getSignedImageURL,
  depositAmount,
  withdrawalAmount,
} from "./action";
import { toast } from "react-toastify";
const initialState = {
  deposit: {
    pendingDeposit: {},
    pendingDepositList: [],
    pendingDepositTotalRows: 0,
  },
  withdrawDetails: {
    data: [],
    total: 0,
  },
  verifiedWithdrawList: {
    data: [],
    total: 0,
  },
  approvedWithdrawList: {
    data: [],
    total: 0,
  },
  approvedVerifiedDepositList: {
    data: [],
    total: 0,
  },
};

const paymentSlice = createSlice({
  name: "Payment",
  initialState,
  reducers: {},
  extraReducers: (b) => {
    b.addCase(pendingDepositTotal.fulfilled, (state, action) => {
      state.deposit.pendingDeposit = action.payload;
    }).addCase(pendingDepositTotal.rejected, (state, action) => {
      if (action.error)
        toast.error(
          action?.error?.message
            ? action.error.message
            : "Failed to fetch Pending Deposits",
          { autoClose: 3000 }
        );
    });

    b.addCase(getPendingDeposit.fulfilled, (state, action) => {
      state.deposit.pendingDepositList = action.payload.items
        ? action.payload.items
        : [];
      state.deposit.pendingDepositTotalRows = action.payload.total
        ? action.payload.total
        : 0;
    }).addCase(getPendingDeposit.rejected, (state, action) => {
      if (action.error)
        toast.error(
          action?.error?.message
            ? action.error.message
            : "Failed to fetch Pending Deposits"
        );
    });

    b.addCase(processPayment.fulfilled, () => {
      toast.success("Amount approved successfully");
    }).addCase(processPayment.rejected, (state, action) => {
      toast.error(
        action?.error?.message
          ? action.error.message
          : "Failed to Process Payment"
      );
    });

    b.addCase(getWithdrawDetails.fulfilled, (state, action) => {
      state.withdrawDetails.data = action.payload.details;
      state.withdrawDetails.total = action.payload.total;
    });

    b.addCase(updateWithDrawStatus.fulfilled, (state, action) => {
      toast.success("Withdraw Request Updated successfully");
    }).addCase(updateWithDrawStatus.rejected, (state, action) => {
      toast.error(
        action?.error?.message
          ? action.error.message
          : "Failed to update Status"
      );
    });

    b.addCase(getVerifiedWithdrawList.fulfilled, (state, action) => {
      state.verifiedWithdrawList.data = action.payload.details;
      state.verifiedWithdrawList.total = action.payload.total;
    });
    b.addCase(getApprovedWithdrawal.fulfilled, (state, action) => {
      state.approvedWithdrawList.data = action.payload.details;
      state.approvedWithdrawList.total = action.payload.total;
    });
    b.addCase(getApprovedVerifiedDeposit.fulfilled, (state, action) => {
      state.approvedVerifiedDepositList.data = action.payload.items;
      state.approvedVerifiedDepositList.total = action.payload.total;
    });
    b.addCase(getSignedImageURL.rejected, (state, action) => {
      toast.error(
        action?.error?.message
          ? action.error.message
          : "Failed to fetch Pending Deposits"
      );
    });
    b.addCase(depositAmount.fulfilled, (state, action) => {
      console.log(action.payload);
      if (action.payload) toast.success(action.payload.message);
    }).addCase(depositAmount.rejected, (state, action) => {
      if (action.error)
        toast.error(
          action?.error?.message
            ? action.error.message
            : "Failed to deposit Amount"
        );
    });
    b.addCase(withdrawalAmount.fulfilled, (state, action) => {
      if (action.payload) toast.success(action.payload.message);
    }).addCase(withdrawalAmount.rejected, (state, action) => {
      if (action.error)
        toast.error(
          action?.error?.message
            ? action.error.message
            : "Failed to withdraw amount"
        );
    });
  },
});

export default paymentSlice.reducer;
