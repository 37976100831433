import React, { lazy } from "react";
import { Navigate } from "react-router-dom";
import SubAdminList from "../pages/User/Administration";
import AuraCasinos from "../pages/SiteManagement/AuraCasinos";
import CasinoAnalysis from "../pages/Report/CasinoAnalysis";

//Dashboard
const Dashboard = lazy(() => import("../pages/Dashboard"));

const BasicLockScreen = lazy(() =>
  import("../pages/AuthenticationInner/LockScreen/BasicLockScr")
);
const BasicLogout = lazy(() =>
  import("../pages/AuthenticationInner/Logout/BasicLogout")
);

const BasicTwosVerify = lazy(() =>
  import("../pages/AuthenticationInner/TwoStepVerification/BasicTwosVerify")
);
const Basic404 = lazy(() =>
  import("../pages/AuthenticationInner/Errors/Basic404")
);
const Error500 = lazy(() =>
  import("../pages/AuthenticationInner/Errors/Error500")
);

const BasicPasswCreate = lazy(() => import("../pages/Auth/BasicPasswCreate"));
const CreateNewPassword = lazy(() => import("../pages/Auth/CreateNewPassword"));
const NewUserEnter = lazy(() => import("../pages/Auth/NewUserEnter"));
//login
const Login = lazy(() => import("../pages/Auth/Login"));
const ForgetPasswordPage = lazy(() => import("../pages/Auth/ForgetPassword"));
const Logout = lazy(() => import("../pages/Auth/Logout"));

// Roles

// Account & Roles
const AccountList = lazy(() => import("../pages/User/Account"));
const RoleList = lazy(() => import("../pages/User/Role"));
const RoleEdit = lazy(() => import("../pages/User/Role/RoleEdit"));
const RoleAdd = lazy(() => import("../pages/User/Role/RoleAdd"));

// Users

const UserProfile = lazy(() => import("../pages/User/Profile"));

// Payment
const Deposit = lazy(() => import("../pages/Payment/Deposit"));
const Withdraw = lazy(() => import("../pages/Payment/Withdraw"));

//Sports
const SportTypes = lazy(() => import("../pages/Sport/SportTypes"));
const Compititions = lazy(() => import("../pages/Sport/Compititions"));
const Events = lazy(() => import("../pages/Sport/Events"));
const Market = lazy(() => import("../pages/Sport/Market"));

// SiteManagement
const DefaultStakeButton = lazy(() =>
  import("../pages/SiteManagement/DefaultStakeButton")
);
const PaymentMode = lazy(() => import("../pages/SiteManagement/PaymentMode"));
const BlacklistIp = lazy(() => import("../pages/SiteManagement/BlacklistIp"));
const LimitSetting = lazy(() => import("../pages/SiteManagement/LimitSetting"));
const Casinos = lazy(() => import("../pages/SiteManagement/Casinos"));
const CasinoLists = lazy(() =>
  import("../pages/SiteManagement/Casinos/CasinoLists")
);
const Rules = lazy(() => import("../pages/SiteManagement/Rules"));
const GeneralSetting = lazy(() =>
  import("../pages/SiteManagement/GeneralSetting")
);
// Report
const OpenBets = lazy(() => import("../pages/Report/OpenBets"));
const VoidBets = lazy(() => import("../pages/Report/VoidBets"));
const BetHistory = lazy(() => import("../pages/Report/BetHistory"));
const AccountStatement = lazy(() => import("../pages/Report/AccountStatement"));
const LiveCasino = lazy(() => import("../pages/Report/LiveCasino"));
const IndianCasino = lazy(() => import("../pages/Report/IndianCasino"));

const WithdrawHistory = lazy(() => import("../pages/Report/WithdrawalHistory"));
const DepositHistory = lazy(() => import("../pages/Report/DepositHistory"));

// Analysis
const RiskAnalysis = lazy(() => import("../pages/Analysis/RiskAnalysis"));
const UserAnalysis = lazy(() => import("../pages/Analysis/UserAnalysis"));
const MarketAnalysis = lazy(() => import("../pages/Analysis/MarketAnalysis"));
const EventRiskAnalysis = lazy(() =>
  import("../pages/Analysis/EventRiskAnalysis")
);

const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/index", component: <Dashboard /> },

  //User Profile
  { path: "/", exact: true, component: <Navigate to="/dashboard" /> },
  { path: "*", component: <Navigate to="/dashboard" /> },

  // Administration
  { path: "/accounts", component: <AccountList /> },
  { path: "/accounts/:userId", component: <AccountList /> },
  { path: "/administration", component: <SubAdminList /> },
  { path: "/administration/roles", component: <RoleList /> },

  //Roles
  { path: "/roles", exact: true, component: <Navigate to="/roles/list" /> },
  { path: "/roles/list", component: <RoleList /> },
  { path: "/roles/edit/:roleId", component: <RoleEdit /> },
  { path: "/roles/add", component: <RoleAdd /> },

  //Users
  { path: "/users", exact: true, component: <Navigate to="/users/list" /> },
  { path: "/users/profile", component: <UserProfile page="profile" /> },
  { path: "/users/setting", component: <UserProfile page="setting" /> },
  {
    path: "/users/change-password",
    component: <UserProfile page="change-password" />,
  },

  //Payment
  {
    path: "/payment",
    exact: true,
    component: <Navigate to="/payment/deposit" />,
  },
  { path: "/payment/withdraw", component: <Withdraw /> },
  { path: "/payment/deposit", component: <Deposit /> },
  { path: "/sports", component: <SportTypes /> },
  { path: "/sports/:sportTypeId/:sport", component: <Compititions /> },
  {
    path: "/sports/:sportTypeId/:sport/:compititionId/:compititionName",
    component: <Events />,
  },
  {
    path: "/sports/:sportTypeId/:sport/:compititionId/:compititionName/:eventId/:eventName",
    component: <Market />,
  },

  // Site Management
  {
    path: "/site-management/default-stake-button",
    component: <DefaultStakeButton />,
  },
  { path: "/site-management/blacklist-ip", component: <BlacklistIp /> },
  { path: "/site-management/payment-mode", component: <PaymentMode /> },
  { path: "/site-management/limit-setting", component: <LimitSetting /> },
  { path: "/site-management/casinos", component: <Casinos /> },
  { path: "/site-management/aura-casinos", component: <AuraCasinos /> },
  { path: "/site-management/rules", component: <Rules /> },
  { path: "/site-management/general-setting", component: <GeneralSetting /> },

  { path: "/casinos", exact: true, component: <Navigate to="/casinos/list" /> },
  { path: "/casinos/list", component: <CasinoLists /> },

  // Reports
  { path: "/reports/account-statement", component: <AccountStatement /> },
  { path: "/reports/open-bets", component: <OpenBets /> },
  { path: "/reports/void-bets", component: <VoidBets /> },
  { path: "/reports/bet-history", component: <BetHistory /> },
  { path: "/reports/live-casinos", component: <LiveCasino /> },
  { path: "/reports/indian-casinos", component: <IndianCasino /> },

  { path: "/reports/withdraw-history", component: <WithdrawHistory /> },
  { path: "/reports/deposit-history", component: <DepositHistory /> },
  { path: "/reports/casino-analysis", component: <CasinoAnalysis /> },

  // Analysis
  { path: "/analysis/risk", component: <RiskAnalysis /> },
  { path: "/analysis/user", component: <UserAnalysis /> },
  { path: "/analysis/market", component: <MarketAnalysis /> },
  {
    path: "/analysis/risk/:eventId/:compititionName/:eventName",
    component: <EventRiskAnalysis />,
  },
];

const publicRoutes = [
  // Authentication Page
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPasswordPage /> },

  //AuthenticationInner pages
  { path: "/auth-lockscreen-basic", component: <BasicLockScreen /> },
  { path: "/auth-logout-basic", component: <BasicLogout /> },
  { path: "/verify/twostep", component: <BasicTwosVerify /> },

  { path: "/auth-404-basic", component: <Basic404 /> },
  { path: "/auth-500", component: <Error500 /> },

  { path: "/auth-pass-change-basic", component: <BasicPasswCreate /> },
  { path: "/auth-pass-create-new", component: <CreateNewPassword /> },
  { path: "/auth-new-user", component: <NewUserEnter /> },
];

export { authProtectedRoutes, publicRoutes };
