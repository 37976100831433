export const csvBufferConvert = (csvBuffer) => {
  console.log("csvBuffer", csvBuffer);
  const bufferString = csvBuffer.reduce((accumulator, currentValue) => {
    return accumulator + String.fromCharCode(currentValue);
  }, "");
  return bufferString;
};

export const pdfBufferConvert = (pdfFile) => {
  const uint8Array = new Uint8Array(pdfFile);
  return uint8Array;
};
