import { createSlice } from "@reduxjs/toolkit";
import {
  getStakeButtonList,
  createStakeButton,
  updateStakeButton,
  deleteStakeButton,
} from "./action";
import { toast } from "react-toastify";

const initialState = {
  stakeButtons: [],
  totalRows: 0,
};

const StakeButtons = createSlice({
  name: "StakeButtons",
  initialState,
  reducers: {},
  extraReducers: (b) => {
    b.addCase(getStakeButtonList.fulfilled, (state, action) => {
      state.stakeButtons = action.payload.items;
      state.totalRows = action.payload.total;
    });
    b.addCase(createStakeButton.fulfilled, (state, action) => {
      if (action.payload) {
        toast.success(action.payload.message);
        return {
          ...state,
          stakeButtons: [...state.stakeButtons, action.payload.data],
          totalRows: state.totalRows + 1,
        };
      }
    });
    b.addCase(deleteStakeButton.fulfilled, (state, action) => {
      const idsToRemove = new Set(
        action.payload.data.map((item) => item._id.toString())
      );
      const lengthOfSet = idsToRemove.size;
      toast.success(action.payload.message);
      if (action.payload)
        return {
          ...state,
          stakeButtons: state.stakeButtons.filter(
            (stakeButton) => !idsToRemove.has(stakeButton._id.toString())
          ),
          totalRows: state.totalRows - lengthOfSet,
        };
    });
    b.addCase(updateStakeButton.fulfilled, (state, action) => {
      if (action.payload) {
        toast.success(action.payload.message);
        return {
          ...state,
          stakeButtons: state.stakeButtons.map((stakeButton) =>
            stakeButton._id.toString() === action.payload.data._id.toString()
              ? { ...stakeButton, ...action.payload.data }
              : stakeButton
          ),
        };
      }
    });
  },
});

export default StakeButtons.reducer;
