import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import Datatable from "../../../../Components/Common/Datatable";
import classNames from "classnames";
import { DateTimeDisplay } from "../../../../Components/Common/DateTimeDisplay";
import { getUserLoginActivity } from "../../../../store/user/action";

const LoginHistoryTab = ({ account }) => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [sortorder, setSortOrder] = useState("DESC");
  const [limit, setLimit] = useState(10);
  const [sortFields, setSortField] = useState("_id");
  const [page, setPage] = useState(1);
  const [loader, setLoader] = useState(false);

  const handlePerRowsChange = async (newPerPage, page) => {
    setLimit(newPerPage);
  };
  const handleSort = (column, sortDirection) => {
    setSortField(column.fieldName);
    setSortOrder(String(sortDirection).toLowerCase());
  };
  useEffect(() => {
    if (account?._id) {
      dispatch(
        getUserLoginActivity({
          userId: account?._id,
          data: { page, limit, sortFields, sortorder },
        })
      )
        .then((d) => {
          setData(d.payload.items);
          setTotal(d.payload.total);
        })
        .catch((e) => {
          setData([]);
          setTotal(0);
        });
    }

    return () => {};
  }, [dispatch, page, limit, sortFields, sortorder, account]);

  const columns = [
    {
      name: <span className="font-weight-bold fs-13">Device</span>,
      selector: (row) => (
        <i
          className={classNames({
            "ri-phone-fill": row.device !== "WEB",
            "ri-global-line": row.device === "WEB",
          })}
        ></i>
      ),
      sortable: true,
      fieldName: "device",
    },
    {
      name: <span className="font-weight-bold fs-13">Login Time</span>,
      selector: (row) => <DateTimeDisplay date={row.createdAt} />,
      sortable: true,
      fieldName: "createdAt",
    },
    {
      name: <span className="font-weight-bold fs-13">Browser</span>,
      selector: (row) => row.browser,
      sortable: true,
      fieldName: "browser",
    },
    {
      name: <span className="font-weight-bold fs-13">Browser Version</span>,
      selector: (row) => row.browserVersion,
      sortable: true,
      fieldName: "browserVersion",
    },
    {
      name: <span className="font-weight-bold fs-13">Fingerprint</span>,
      selector: (row) => row.fingerprint,
      sortable: true,
      fieldName: "fingerprint",
    },
    {
      name: <span className="font-weight-bold fs-13">Platform</span>,
      selector: (row) =>
        row.platform ? row.platform.replaceAll('"', "") : "-",
      sortable: true,
      fieldName: "platform",
    },
    {
      name: <span className="font-weight-bold fs-13">Ip Address</span>,
      selector: (row) => row.ip,
      sortable: true,
      fieldName: "ip",
    },
  ];
  const handleSelection = () => false;
  return (
    <React.Fragment>
      <Datatable
        columns={columns}
        data={data}
        totalRows={total}
        handlePerRowsChange={handlePerRowsChange}
        handlePageChange={setPage}
        handleSort={handleSort}
        loader={loader}
        selectableRows={false}
        handleSelection={handleSelection}
      />
    </React.Fragment>
  );
};

export default LoginHistoryTab;
