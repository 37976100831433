import React from "react";

const BalanceCard = ({ userBalance, type }) => {
  const cards = [
    {
      id: 1,
      title: "Total Balance",
      iconClass: "ri-money-dollar-circle-fill",
      value: "balance",
      visible: type !== "SUPERADMIN",
    },
    {
      id: 2,
      title: "Total Credit",
      iconClass: "ri-money-dollar-circle-fill",
      value: "credit",
      visible: type !== "SUPERADMIN",
    },
    {
      id: 3,
      title: "Total Exposure",
      iconClass: "ri-arrow-up-circle-fill",
      value: "exposure",
      visible: type !== "SUPERADMIN",
    },
    {
      id: 4,
      title: "Client P/L - Upline",
      iconClass: "ri-arrow-down-circle-fill",
      value: "profitLoss",
      visible: type !== "SUPERADMIN",
    },
  ];
  return (
    <div className="row">
      {cards.map((ele) => {
        return (
          <React.Fragment key={ele.id + ele.title}>
            {ele.visible && userBalance ? (
              <div className="col-lg-3 col-md-5">
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex align-items-center">
                      <div className="avatar-sm flex-shrink-0">
                        <span className="avatar-title bg-light text-primary rounded-circle fs-3">
                          <i className={`${ele.iconClass} align-middle`}></i>
                        </span>
                      </div>
                      <div className="flex-grow-1 ms-3">
                        <p className="text-uppercase fw-semibold fs-12 text-muted mb-1">
                          {" "}
                          {ele.title}
                        </p>
                        <h4 className="mb-0">
                          <i className="mdi mdi-currency-inr align-middle text-primary me-2"></i>
                          <span
                            className="counter-value"
                            data-target={ele.value}
                          >
                            {/* {(userBalance?.[ele.value] || 0).toFixed(2)} */}
                            {userBalance?.[ele.value]
                              ? Math.round(userBalance?.[ele.value] * 100) / 100
                              : 0}
                          </span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default BalanceCard;
