import React from "react";
import { useSelector } from "react-redux";
import { Col, Row } from "reactstrap";

const Section = () => {
  const { user } = useSelector((state) => ({
    user: state.User.user,
  }));

  const getTimeBasedGreeting = () => {
    const date = new Date();
    const hours = date.getHours();

    if (hours < 12) {
      return "Good Morning";
    } else if (hours < 17) {
      return "Good Afternoon";
    } else {
      return "Good Evening";
    }
  };

  return (
    <React.Fragment>
      <Row className="mb-3 pb-1">
        <Col xs={12}>
          <div className="d-flex align-items-lg-center flex-lg-row flex-column">
            <div className="flex-grow-1">
              <h4 className="fs-16 mb-1">
                {getTimeBasedGreeting()}, {user?.username}!
              </h4>
              <p className="text-muted mb-0">
                Here's what's happening with your store today.
              </p>
            </div>
            <div className="mt-3 mt-lg-0"></div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Section;
