import { createSlice } from "@reduxjs/toolkit";
import {
  getGeneralSettings,
  updateGeneralSettings,
} from "./action";
import { toast } from "react-toastify";

const initialState = {
  settingMetaData: {},
};

const GeneralSettings = createSlice({
  name: "Settings",
  initialState,
  reducers: {},
  extraReducers: (b) => {
    b.addCase(getGeneralSettings.fulfilled, (state, action) => {
      state.settingMetaData = action.payload;
    })
    .addCase(getGeneralSettings.rejected, (state, action) => {
      state.settingMetaData = {};
      toast.error("Error in getting setting data.");
    })

    b.addCase(updateGeneralSettings.fulfilled, (state, action) => {
        toast.success("Setting updated Successfully.");
    });
  },
});

export default GeneralSettings.reducer;
