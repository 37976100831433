import React, { useState, useCallback, useEffect } from "react";
import {
  Col,
  Container,
  Row,
  Card,
  CardHeader,
  CardBody,
  Button,
} from "reactstrap";
import { debounce } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  getSubAdminList,
  addNewSubAdmin,
  updateSubAdminDetail,
  deleteSubAdmin,
  getRoleDropdownList,
  changeLoader,
} from "../../../store/actions";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import AddSubAdminModal from "./AddSubAdmin";
import ListAdministrations from "./ListAdministrations";
import { toast } from "react-toastify";
import DeleteModal from "../../../Components/Common/DeleteModal";
import DetailModal from "./DetailModal";

const SubAdminList = () => {
  const dispatch = useDispatch();
  const [search, setSearchQuery] = useState("");
  const [searchVal, setSearchValQuery] = useState("");
  const [sortField, setSortField] = useState("firstName");
  const [sortOrder, setSortOrder] = useState("asc");
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const [selectedIds, setSelectedIds] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [account, setAccount] = useState({});
  const [detailModal, setDetailModal] = useState(false);

  const { subAdmins, totalRows, modules, roleDropdownList } = useSelector(
    (state) => ({
      subAdmins: state.Admins.subAdminList,
      totalRows: state.Admins.subAdminCount,
      modules: state.User.modules,
      roleDropdownList: state.Role.roleDropdownList,
    })
  );

  const getSubAdminData = () => {
    dispatch(
      getSubAdminList({ search, page, limit, sortOrder, sortField })
    ).then((e) => {
      setLoading(false);
    });
  };

  useEffect(() => {
    setLoading(true);
    getSubAdminData();
  }, [dispatch, page, limit, sortOrder, search, sortField]);

  const toggleDetail = useCallback(() => {
    if (detailModal) {
      setDetailModal(false);
      setAccount({});
    } else setDetailModal(true);
  }, [detailModal]);

  useEffect(() => {
    dispatch(getRoleDropdownList());
  }, []);

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setEditData(null);
      setAccount({});
    } else setModal(true);
  }, [modal]);

  const handlePerRowsChange = async (newPerPage) => {
    setLimit(newPerPage);
  };

  const handleSort = (column, sortDirection) => {
    setSortField(column.fieldName);
    setSortOrder(String(sortDirection).toLowerCase());
  };

  const debouncedGetSubAdminList = useCallback(
    debounce((query) => {
      if (
        String(query.search).length > 2 ||
        String(query.search).length === 0
      ) {
        setPage(1);
        setSearchQuery(query.search);
      }
    }, 500),
    [dispatch]
  );

  const updateSubAdmin = (update) => {
    dispatch(
      updateSubAdminDetail({
        search,
        page,
        limit,
        sortOrder,
        sortField,
        update,
      })
    ).then((a) => {
      if (a.payload) {
        getSubAdminData();
        toggle();
      }
    });
  };

  const addSubAdmin = (create) => {
    dispatch(changeLoader(true));
    dispatch(
      addNewSubAdmin({ search, page, limit, sortOrder, sortField, create })
    )
      .then((a) => {
        if (a.payload) {
          dispatch(changeLoader(false));
          getSubAdminData();
          toggle();
        } else {
          dispatch(changeLoader(false));
        }
      })
      .catch(() => dispatch(changeLoader(false)));
  };

  const handleSearchChange = useCallback(
    (event) => {
      setSearchValQuery(event.target.value);
      debouncedGetSubAdminList({
        search: event.target.value,
      });
    },
    [debouncedGetSubAdminList]
  );

  const handleEdit = (data) => {
    setModal(true);
    setEditData(data);
  };

  const handleSelection = (e) => {
    setSelectedIds(e.selectedRows.map((x) => x._id));
  };

  const handleDelete = (adminIds) => {
    setSelectedIds(adminIds);
    setDeleteModal(true);
  };

  const deleteMultiple = () => {
    if (selectedIds.length === 0) {
      toast.error("Please select sub-admins to delete.");
      return;
    }
    handleDelete(selectedIds);
  };

  const deleteSubAdmins = (subAdminIds) => {
    dispatch(changeLoader(true));

    dispatch(
      deleteSubAdmin({ search, page, limit, sortOrder, sortField, subAdminIds })
    )
      .then((a) => {
        dispatch(changeLoader(false));
        getSubAdminData();
        setDeleteModal(false);
      })
      .catch(() => dispatch(changeLoader(false)));
  };

  document.title = "Administration | " + process.env.REACT_APP_NAME;

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Administration" pageTitle="Administration" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader className="align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">
                    Administration
                  </h4>
                </CardHeader>

                <CardBody>
                  <div id="table-loading-state">
                    <Row className="g-4 mb-3">
                      <Col className="col-sm-auto">
                        <div>
                          {modules?.["administrator-add-admin-button"] == 1 && (
                            <Button
                              color="success"
                              className="add-btn me-1 rounded-pill "
                              onClick={() => setModal(true)}
                              id="create-btn"
                            >
                              <i className="ri-add-line align-bottom me-1"></i>
                              Add
                            </Button>
                          )}
                          {modules?.["administrator-delete-admin-button"] ==
                            1 && (
                            <Button
                              className="btn btn-soft-danger rounded-pill"
                              onClick={deleteMultiple}
                            >
                              <i className="ri-delete-bin-2-line"></i>
                            </Button>
                          )}
                        </div>
                      </Col>
                      <Col className="col-sm">
                        <div className="d-flex justify-content-sm-end">
                          <div className="search-box ms-2">
                            <input
                              type="text"
                              className="form-control search"
                              placeholder="Search..."
                              value={searchVal}
                              onChange={handleSearchChange}
                            />
                            <i className="ri-search-line search-icon"></i>
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <ListAdministrations
                      data={subAdmins}
                      totalRows={totalRows}
                      handlePerRowsChange={handlePerRowsChange}
                      handlePageChange={setPage}
                      handleSort={handleSort}
                      loader={loading}
                      handleEdit={handleEdit}
                      handleSelection={handleSelection}
                      setSelectedIds={setSelectedIds}
                      handleDelete={handleDelete.bind(null)}
                      setAccount={setAccount}
                      setDetailModal={setDetailModal}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <AddSubAdminModal
            setModal={setModal.bind(null)}
            isOpen={modal}
            toggle={toggle.bind(null)}
            addSubAdmin={addSubAdmin.bind(null)}
            editData={editData}
            updateSubAdmin={updateSubAdmin}
            roleDropdownList={roleDropdownList}
          />

          <DeleteModal
            show={deleteModal}
            onDeleteClick={() => deleteSubAdmins(selectedIds)}
            onCloseClick={() => {
              setDeleteModal(false);
              setSelectedIds([]);
            }}
          />

          <DetailModal
            isOpen={detailModal}
            account={account}
            toggleDetail={toggleDetail.bind(null)}
          />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default SubAdminList;
