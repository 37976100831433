import { createSlice } from "@reduxjs/toolkit";

import {
  getCasinoProvidersList,
  addCasinoProvider,
  updateCasinoProvider,
  getCasinoGameTypesList,
  addCasinoGameType,
  updateCasinoGameType,
  getGameProviders,
  getGameTypes,
  getCasinoList,
  createCasino,
  updateCasino,
  changeCasinoGameStatus,
  deleteCasino,
} from "./action";
import { toast } from "react-toastify";
import { getallCasinoList } from "./action";

const initialState = {
  casinos: {
    data: [],
    totalRows: 0,
  },
  casinoProviders: {
    data: [],
    totalRows: 0,
  },
  casinoProvidersList: [],
  casinoGameTypes: {
    data: [],
    totalRows: 0,
  },
  casinoGameTypesList: [],
  allcasinosList: [],
};

const Casinos = createSlice({
  name: "Casinos",
  initialState,
  reducers: {},
  extraReducers: (b) => {
    b.addCase(getCasinoList.fulfilled, (state, action) => {
      state.casinos = {
        data: action.payload.items,
        totalRows: action.payload.total,
      };
    });

    b.addCase(createCasino.fulfilled, (state, action) => {
      if (action.payload)
        return {
          ...state,
          casinos: {
            data: [...state.casinos.data, action.payload],
            totalRows: state.casinos.totalRows + 1,
          },
        };
    }).addCase(createCasino.rejected, (state, action) => {
      if (action.error) {
        toast.error(action?.error?.message);
      }
    });

    b.addCase(updateCasino.fulfilled, (state, action) => {
      if (action.payload) {
        toast.success(action.payload?.message);
        return {
          ...state,
          casinos: {
            data: state.casinos.data.map((cp) =>
              cp._id.toString() === action.payload.data._id.toString()
                ? { ...cp, ...action.payload }
                : cp
            ),
            totalRows: state.casinos.totalRows,
          },
        };
      }
    }).addCase(updateCasino.rejected, (state, action) => {
      if (action.error) {
        toast.error(action?.error?.message);
      }
    });

    b.addCase(changeCasinoGameStatus.fulfilled, (state, action) => {
      if (action.payload) {
        toast.success(action.payload?.message);
      }
    });

    b.addCase(deleteCasino.fulfilled, (state, action) => {
      if (action.payload) {
        toast.success(action.payload.message);
      }
    });
    b.addCase(changeCasinoGameStatus.rejected, (state, action) => {
      if (action?.error) toast.error(action?.error?.message);
      else toast.error("Error Occured");
    });

    b.addCase(getCasinoProvidersList.fulfilled, (state, action) => {
      state.casinoProviders = {
        data: action.payload.items,
        totalRows: action.payload.total,
      };
    });

    b.addCase(addCasinoProvider.fulfilled, (state, action) => {
      if (action.payload) {
        toast.success(action.payload.message);
        return {
          ...state,
          casinoProviders: {
            data: [...state.casinoProviders.data, action.payload.data],
            totalRows: state.casinoProviders.totalRows + 1,
          },
        };
      }
      toast.success(action.payload?.message || "Casino Provider Added.");
    });

    b.addCase(addCasinoProvider.rejected, (state, action) => {
      if (action?.error) {
        toast.error(action?.error?.message);
      }
    });
    b.addCase(updateCasinoProvider.fulfilled, (state, action) => {
      if (action.payload) {
        toast.success(action.payload.message);
        return {
          ...state,
          casinoProviders: {
            data: state.casinoProviders.data.map((cp) =>
              cp._id.toString() === action.payload.data._id.toString()
                ? { ...cp, ...action.payload.data }
                : cp
            ),
            totalRows: state.casinoProviders.totalRows,
          },
        };
      }
    });
    b.addCase(updateCasinoProvider.rejected, (state, action) => {
      if (action?.error) {
        toast.error(action?.error?.message);
      }
    });

    b.addCase(getGameProviders.fulfilled, (state, action) => {
      state.casinoProvidersList = action.payload;
    });

    b.addCase(getCasinoGameTypesList.fulfilled, (state, action) => {
      state.casinoGameTypes = {
        data: action.payload.items,
        totalRows: action.payload.total,
      };
    });

    b.addCase(addCasinoGameType.rejected, (state, action) => {
      if (action?.error) {
        toast.error(action?.error?.message);
      }
    });

    b.addCase(updateCasinoGameType.fulfilled, (state, action) => {
      if (action.payload)
        return {
          ...state,
          casinoGameTypes: {
            data: state.casinoGameTypes.data.map((cp) =>
              cp._id.toString() === action.payload._id.toString()
                ? { ...cp, ...action.payload }
                : cp
            ),
            totalRows: state.casinoGameTypes.totalRows,
          },
        };
    });
    b.addCase(updateCasinoGameType.rejected, (state, action) => {
      if (action?.error) {
        toast.error(action?.error?.message);
      }
    });

    b.addCase(getGameTypes.fulfilled, (state, action) => {
      state.casinoGameTypesList = action.payload;
    });

    b.addCase(getallCasinoList.fulfilled, (state, action) => {
      state.allcasinosList = action.payload;
    }).addCase(getallCasinoList.rejected, (state, action) => {
      if (action?.error) toast.error(action.error.message);
      else toast.error("Error occured");
    });
  },
});

export default Casinos.reducer;
