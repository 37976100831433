import { createSlice } from "@reduxjs/toolkit";
import {
  deleteAccountMarketLimit,
  deleteSportMarketLimit,
  getAccountMarketLimit,
  getMarketLimit,
  getSportMarketLimit,
  setAccountMarketLimit,
  setMarketLimit,
  setSportMarketLimit,
  //   updateStakeButton,
  //   deleteStakeButton,
} from "./action";
import { toast } from "react-toastify";

const initialState = {
  marketLimit: {},
  fancyMarket: {},
  advanceSessionMarket: {},
  bookmarker: {},
  manualOdds: {},
  sportsbook: {},
};

const LimitSetting = createSlice({
  name: "LimitSetting",
  initialState,
  reducers: {},
  extraReducers: (b) => {
    b.addCase(getMarketLimit.fulfilled, (state, action) => {
      if (action.payload)
        return {
          ...state,
          marketLimit:
            action.payload.filter((item) => item.type === "MARKET")[0] || null,
          fancyMarket:
            action.payload.filter((item) => item.type === "FANCY")[0] || null,
          advanceSessionMarket:
            action.payload.filter(
              (item) => item.type === "ADVANCE_SESSION_MARKET"
            )[0] || null,
          bookmarker:
            action.payload.filter((item) => item.type === "BOOKMAKER")[0] ||
            null,
          manualOdds:
            action.payload.filter((item) => item.type === "MANUAL_ODDS")[0] ||
            null,
          sportsbook:
            action.payload.filter((item) => item.type === "SPORTSBOOK")[0] ||
            null,
        };
    });
    b.addCase(getSportMarketLimit.fulfilled, (state, action) => {
      if (action.payload)
        return {
          ...state,
          marketLimit:
            action.payload.filter((item) => item.type === "MARKET")[0] || null,
          fancyMarket:
            action.payload.filter((item) => item.type === "FANCY")[0] || null,
          advanceSessionMarket:
            action.payload.filter(
              (item) => item.type === "ADVANCE_SESSION_MARKET"
            )[0] || null,
          bookmarker:
            action.payload.filter((item) => item.type === "BOOKMAKER")[0] ||
            null,
          manualOdds:
            action.payload.filter((item) => item.type === "MANUAL_ODDS")[0] ||
            null,
          sportsbook:
            action.payload.filter((item) => item.type === "SPORTSBOOK")[0] ||
            null,
        };
    });
    b.addCase(setMarketLimit.fulfilled, (state, action) => {
      if (action.payload) {
        toast.success(`${action.payload.data.type} ${action.payload.message}`);
        // eslint-disable-next-line default-case
        switch (action.payload.type) {
          case "MARKET":
            return { ...state, marketLimit: action.payload };
          case "ADVANCE_SESSION_MARKET":
            return { ...state, advanceSessionMarket: action.payload };
          case "BOOKMAKER":
            return { ...state, bookmarker: action.payload };
          case "FANCY":
            return { ...state, fancyMarket: action.payload };
          case "MANUAL_ODDS":
            return { ...state, manualOdds: action.payload };
          case "SPORTSBOOK":
            return { ...state, sportsbook: action.payload };
        }
      }
    });
    b.addCase(setSportMarketLimit.fulfilled, (state, action) => {
      if (action.payload) {
        toast.success(`${action.payload.data.type} ${action.payload.message} `);
        // eslint-disable-next-line default-case
        switch (action.payload.data.type) {
          case "MARKET":
            return { ...state, marketLimit: action.payload.data };
          case "ADVANCE_SESSION_MARKET":
            return { ...state, advanceSessionMarket: action.payload.data };
          case "BOOKMAKER":
            return { ...state, bookmarker: action.payload.data };
          case "FANCY":
            return { ...state, fancyMarket: action.payload.data };
          case "MANUAL_ODDS":
            return { ...state, manualOdds: action.payload.data };
          case "SPORTSBOOK":
            return { ...state, sportsbook: action.payload.data };
        }
      }
    });
    b.addCase(getAccountMarketLimit.fulfilled, (state, action) => {
      if (action.payload)
        return {
          ...state,
          marketLimit:
            action.payload.filter((item) => item.type === "MARKET")[0] || null,
          fancyMarket:
            action.payload.filter((item) => item.type === "FANCY")[0] || null,
          advanceSessionMarket:
            action.payload.filter(
              (item) => item.type === "ADVANCE_SESSION_MARKET"
            )[0] || null,
          bookmarker:
            action.payload.filter((item) => item.type === "BOOKMAKER")[0] ||
            null,
          manualOdds:
            action.payload.filter((item) => item.type === "MANUAL_ODDS")[0] ||
            null,
          sportsbook:
            action.payload.filter((item) => item.type === "SPORTSBOOK")[0] ||
            null,
        };
    });
    b.addCase(setAccountMarketLimit.fulfilled, (state, action) => {
      if (action.payload) {
        toast.success(`${action.payload.data.type} ${action.payload.message} `); // eslint-disable-next-line default-case
        switch (action.payload.data.type) {
          case "MARKET":
            return { ...state, marketLimit: action.payload.data };
          case "ADVANCE_SESSION_MARKET":
            return { ...state, advanceSessionMarket: action.payload.data };
          case "BOOKMAKER":
            return { ...state, bookmarker: action.payload.data };
          case "FANCY":
            return { ...state, fancyMarket: action.payload.data };
          case "MANUAL_ODDS":
            return { ...state, manualOdds: action.payload.data };
          case "SPORTSBOOK":
            return { ...state, sportsbook: action.payload.data };
        }
      }
    });
    b.addCase(deleteAccountMarketLimit.fulfilled, (state, action) => {
      if (action.payload) toast.success(action.payload.data.message);
    });
    b.addCase(deleteSportMarketLimit.fulfilled, (state, action) => {
      if (action.payload) toast.success(action.payload.data.message);
    });
  },
});

export default LimitSetting.reducer;
