import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Card,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
  CardBody,
  TabContent,
  TabPane,
} from "reactstrap";
import { useDispatch } from "react-redux";
import classnames from "classnames";
import ChangePasswordTab from "./DetailsTabs/ChangePasswordTab";

const DetailModal = (props) => {
  const { isOpen, account, toggleDetail } = props;

  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("profile");
  useEffect(() => {
    setActiveTab("profile");
  }, [account]);

  const tabChange = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  useEffect(() => {}, [isOpen]);

  return (
    <Modal
      id="showModal"
      isOpen={isOpen}
      toggle={toggleDetail}
      centered
      className="modal-xl  modal-dialog-scrollable"
    >
      <ModalHeader className="bg-light p-3" toggle={toggleDetail}>
        Details
      </ModalHeader>

      <ModalBody className="p-0">
        <Card className="mb-0">
          <CardHeader>
            <Nav
              className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
              role="tablist"
            >
              <NavItem>
                <NavLink
                  onClick={() => tabChange("profile")}
                  className={classnames({
                    active: activeTab === "profile",
                  })}
                >
                  Profile
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  onClick={() => tabChange("change-password")}
                  className={classnames({
                    active: activeTab === "change-password",
                  })}
                  type="button"
                >
                  Change Password
                </NavLink>
              </NavItem>
            </Nav>
          </CardHeader>
          <CardBody className="p-0">
            <TabContent activeTab={activeTab}>
              <TabPane tabId="profile">
                {/* <ProfileTab customer={customer} /> */}P
              </TabPane>
              <TabPane tabId="change-password">
                <ChangePasswordTab account={account} toggle={toggleDetail} />
              </TabPane>
            </TabContent>
          </CardBody>
        </Card>
      </ModalBody>
    </Modal>
  );
};

export default DetailModal;
