import { APIHelper } from "../../api_helper";
import * as url from "../../url_helper";

// get User Profile
export const getUserProfile = () => APIHelper.get(url.GET_USER_PROFILE);

// get qr and secret for enable 2fa
export const enable2FA = () => APIHelper.get(url.ENABLE_2FA);
export const verify2FA = (code) => APIHelper.post(url.VERIFY_2FA, code);
export const disable2FA = (code) => APIHelper.post(url.DISABLE_2FA, code);

export const resetPasswordApi = (data) =>
  APIHelper.patch(url.RESET_USER_PASSWORD, data);

export const updatePasswordApi = (data) =>
  APIHelper.patch(url.UPDATE_USER_PASSWORD, data);
export const updateTransactionCodeApi = (data) =>
  APIHelper.patch(url.UPDATE_USER_TRANSACTION_CODE, data);
export const updateUserLockApi = (data) => {
  return APIHelper.patch(url.UPDATE_USER_LOCK, data);
};
export const getSignedImageApi = (data) =>
  APIHelper.post(url.GET_SIGNED_IMAGE, data);

export const getUserLoginActivityApi = (userId, params) =>
  APIHelper.get(url.USER_LOGIN_ACTIVITY(userId), params);
export const getUserModuleApi = (_id) => APIHelper.get(url.USER_MODULES(_id));
export const getUserMenuApi = (_id) => APIHelper.get(url.USER_MENU(_id));

// bank details related
export const getBankDetailsApi = (userId, params = {}) => {
  return APIHelper.get(url.GET_USER_BANK_DETAILS(userId), params);
};

export const updateUserApi = (data) => APIHelper.patch(url.UPDATE_USER, data);
export const updateCreditAmount = (data) =>
  APIHelper.post(url.UPDATE_CREDIT_USER, data);
export const decreaseCreditAmount = (data) =>
  APIHelper.post(url.DECREASE_CREDIT_USER, data);

export const transactionHistory = (data) =>
  APIHelper.post(url.TRANSACTION_HISTORY, data);
export const getSingleUserDetailsApi = (params) =>
  APIHelper.get(url.GET_SINGLE_USER_DETAIL, params);
