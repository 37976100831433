import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getInPlayData,
  getAllMatchData,
  getBetsCountData,
  getEventMarketData,
  getEventBetsCountData,
  getOpenBetMarketsData,
  getMarketBetsData,
  getMarketOddsData,
  getPlAnalysisAPI,
  getPlByMarketData,
  getMarketTypeData,
  getPlUserBetsData,
  getEventTypeData,
} from "../../helpers/backend_helper";

export const getInPlay = createAsyncThunk(
  "market/getInPlay",
  async (payload) => {
    const response = await getInPlayData(payload);
    return response.data;
  }
);

export const getAllMatch = createAsyncThunk(
  "market/getAllMatch",
  async (payload) => {
    const response = await getAllMatchData(payload);
    return response.data;
  }
);

export const getBetsCount = createAsyncThunk(
  "market/getBetsCount",
  async (payload) => {
    const response = await getBetsCountData(payload);
    return response.data;
  }
);

export const getEventMarkets = createAsyncThunk(
  "market/getEventMarkets",
  async (payload) => {
    const response = await getEventMarketData(payload);
    return response.data;
  }
);

export const getEventBetsCount = createAsyncThunk(
  "market/getEventBetsCount",
  async (payload) => {
    const response = await getEventBetsCountData(payload);
    return response.data;
  }
);

export const getOpenBetMarkets = createAsyncThunk(
  "market/getOpenBetMarkets",
  async (payload) => {
    const response = await getOpenBetMarketsData(payload);
    return response.data;
  }
);

export const getMarketBets = createAsyncThunk(
  "market/getMarketBets",
  async (payload) => {
    const response = await getMarketBetsData(payload);
    return response.data;
  }
);

export const getMarketOdds = createAsyncThunk(
  "market/getMarketOdds",
  async (payload) => {
    const response = await getMarketOddsData(payload);
    return response.data;
  }
);
export const getPlAnalysis = createAsyncThunk(
  "market/getPlAnalysis",
  async (payload) => {
    const response = await getPlAnalysisAPI(payload);
    return response.data;
  }
);

export const getPlByMarket = createAsyncThunk("market/getPlByMarket", async (payload) => {
  const response = await getPlByMarketData(payload);
  return response.data;
});

export const getPlUserBets = createAsyncThunk("market/getPlUserBets", async (payload) => {
  const response = await getPlUserBetsData(payload);
  return response.data;
});

export const getPlMarketTypes = createAsyncThunk("market/getPlMarketTypes", async () => {
  const response = await getMarketTypeData();
  return response.data;
});

export const getPlEventTypes = createAsyncThunk("market/getPlEventTypes", async () => {
  const response = await getEventTypeData();
  return response.data;
});
