import React from "react";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";

import { useSelector } from "react-redux";

const Deposit = () => {
  const { data } = useSelector((state) => ({
    data: state.Dashboard?.deposits?.data,
  }));

  return (
    <React.Fragment>
      <Col xl={12} md={12}>
        <Card className="card-height-100">
          <CardHeader className="align-items-center d-flex">
            <h4 className="card-title mb-0 flex-grow-1">Deposit</h4>
          </CardHeader>

          <CardBody>
            <Row className="align-items-center">
              <Col xs={6}>
                <h6 className="text-muted text-uppercase fw-semibold text-truncate fs-12 mb-3">
                  Total
                </h6>
                <h4 className="fs- mb-0">
                  <i className="mdi mdi-currency-inr align-middle text-primary me-2"></i>
                  {Math.round(data?.totalVerifiedDeposits * 100) / 100 || 0}
                </h4>
              </Col>
              <Col xs={6}>
                <h6 className="text-muted text-uppercase fw-semibold text-truncate fs-12 mb-3">
                  Average
                </h6>
                <h4 className="fs- mb-0">
                  <i className="mdi mdi-currency-inr align-middle text-primary me-2"></i>
                  {Number(data?.totalVerifiedDeposits) &&
                  Number(data?.countVerifiedDeposits)
                    ? Math.round(
                        (Number(data?.totalVerifiedDeposits) /
                          Number(data?.countVerifiedDeposits)) *
                          100
                      ) / 100
                    : 0}
                </h4>
              </Col>
            </Row>

            <div className="mt-3 pt-2">
              <div className="d-flex mb-2">
                <div className="flex-grow-1">
                  <p className="text-truncate text-muted fs-14 mb-0">
                    <i className="mdi mdi-circle align-middle text-primary me-2"></i>
                    Payment Gateway Deposit{" "}
                    {`(${
                      data?.countVerifiedOnlineDeposits
                        ? data?.countVerifiedOnlineDeposits
                        : 0
                    })`}
                  </p>
                </div>
                <div className="flex-shrink-0">
                  <p className="mb-0">
                    <i className="mdi mdi-currency-inr align-middle text-primary me-2"></i>
                    {Math.round(data?.totalVerifiedOnlineDeposits * 100) /
                      100 || 0}
                  </p>
                </div>
              </div>
              <div className="d-flex mb-2">
                <div className="flex-grow-1">
                  <p className="text-truncate text-muted fs-14 mb-0">
                    <i className="mdi mdi-circle align-middle text-info me-2"></i>
                    Manual Deposit{" "}
                    {` (${
                      data?.manualDepositsCount ? data?.manualDepositsCount : 0
                    })`}
                  </p>
                </div>
                <div className="flex-shrink-0">
                  <p className="mb-0">
                    <i className="mdi mdi-currency-inr align-middle text-primary me-2"></i>
                    {Math.round(
                      data?.totalVerifiedManualDepositsAllUsers * 100
                    ) / 100 || 0}
                  </p>
                </div>
              </div>
            </div>
            {/* <div className="mt-3 pt-2">
              <p className="mb-1">
                Target <span className="float-end">75%</span>
              </p>
              <div className="progress mt-2" style={{ height: "6px" }}>
                <div
                  className="progress-bar progress-bar-striped bg-primary"
                  role="progressbar"
                  style={{ width: "75%" }}
                  aria-valuenow="75"
                  aria-valuemin="0"
                  aria-valuemax="75"
                ></div>
              </div>
            </div> */}
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default Deposit;
