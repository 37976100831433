import { createSlice } from "@reduxjs/toolkit";
import {
  getOpenBetsList,
  getBetHistory,
  searchWithdrawUsers,
  searchDepositUsers,
  AccountStatementUsers,
  DownloadAccountStatement,
  getAuraCasinoReport,
  getCasinoAnalysisReport,
  getVoidBetsList,
} from "./action";

const initialState = {
  openBets: { items: [], totalRows: 0 },
  voidBets: { items: [], totalRows: 0 },
  betHistory: { items: [], totalRows: 0 },
  withdrawHistory: { items: [], totalRows: 0 },
  depositHistory: { items: [], totalRows: 0 },
  transactionHistory: { items: [], totalRows: 0, csvBuffer: {}, pdfBuffer: {} },
  userTransactionHistory: { items: [], totalRows: 0 },
  auraCasinoReportData: { items: [], totalRows: 0 },
  casinoAnalysisReport: { items: [] },
};

const Report = createSlice({
  name: "Report",
  initialState,
  reducers: {
    resetAuraCasinoData: (state, action) => {
      return {
        ...state,
        auraCasinoReportData: {
          items: [],
          totalRows: 0,
        },
      };
    },
    resetCasinoAnalysisData: (state, action) => {
      return {
        ...state,
        casinoAnalysisReport: {
          items: [],
        },
      };
    },
  },
  extraReducers: (b) => {
    b.addCase(getOpenBetsList.fulfilled, (state, action) => {
      state.openBets.items = action.payload?.items;
      state.openBets.totalRows = action.payload?.total;
    });

    b.addCase(getVoidBetsList.fulfilled, (state, action) => {
      state.voidBets.items = action.payload?.items;
      state.voidBets.totalRows = action.payload?.total;
    });


    b.addCase(getBetHistory.fulfilled, (state, action) => {
      state.betHistory.items = action.payload?.items;
      state.betHistory.totalRows = action.payload?.total;
    });

    b.addCase(searchWithdrawUsers.fulfilled, (state, action) => {
      state.withdrawHistory.items = action.payload?.details;
      state.withdrawHistory.totalRows = action.payload?.total;
    });

    b.addCase(searchDepositUsers.fulfilled, (state, action) => {
      state.depositHistory.items = action.payload?.items;
      state.depositHistory.totalRows = action.payload?.total;
    });

    b.addCase(AccountStatementUsers.fulfilled, (state, action) => {
      state.transactionHistory.items = action.payload?.items;
      state.transactionHistory.totalRows = action.payload?.total;
    });

    b.addCase(DownloadAccountStatement.fulfilled, (state, action) => {
      state.transactionHistory.csvBuffer = action.payload?.csvBuffer;
      state.transactionHistory.pdfBuffer = action.payload?.pdfBuffer;
    });

    b.addCase(getAuraCasinoReport.fulfilled, (state, action) => {
      state.auraCasinoReportData.items = action.payload?.items;
      state.auraCasinoReportData.totalRows = action.payload?.total;
    });

    b.addCase(getCasinoAnalysisReport.fulfilled, (state, action) => {
      state.casinoAnalysisReport.items = action.payload?.items;
    });
  },
});

export const { resetAuraCasinoData, resetCasinoAnalysisData } = Report.actions;

export default Report.reducer;
