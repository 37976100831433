import { APIHelper } from "../../api_helper";
import * as url from "../../url_helper";

// Ip Button
export const getIpsList = (payload) => APIHelper.get(url.GET_IP_LIST, payload);
export const addBlacklistApi = (payload) =>
  APIHelper.post(url.ADD_BLACKLIST_API, payload);
export const deleteBlacklistApi = (data) =>
  APIHelper.delete(url.DELETE_BLACKLIST_API, { data });

// Stake Button
export const getStakeButton = (payload) =>
  APIHelper.get(url.GET_STAKE_BUTTON, payload);
export const createStakeButton = (payload) =>
  APIHelper.post(url.SET_STAKE_BUTTON, payload);
export const updateStakeButton = (id, payload) =>
  APIHelper.put(url.PUT_STAKE_BUTTON(id), payload);
export const deleteStakeButton = (data) =>
  APIHelper.delete(url.DELETE_STAKE_BUTTON, { data });

// Payment Mode
export const getPaymentMode = (payload) =>
  APIHelper.get(url.GET_PAYMENT_MODE, payload);
export const createPaymentMode = (payload) =>
  APIHelper.post(url.SET_PAYMENT_MODE, payload);
export const updatePaymentMode = (id, payload) =>
  APIHelper.put(url.PUT_PAYMENT_MODE(id), payload);
export const deletePaymentMode = (data) =>
  APIHelper.delete(url.DELETE_PAYMENT_MODE, { data });

export const getLimitSettings = () => APIHelper.get(url.GET_MARKET_LIMIT);
export const getSportLimitSettings = (data) =>
  APIHelper.post(url.GET_SPORT_MARKET_LIMIT(data.sport), {
    id: data.id,
    marketType: data.marketType,
    eventId: data.eventId,
  });
export const setLimitSettings = (type, payload) =>
  APIHelper.post(url.SET_MARKET_LIMIT(type), payload);
export const setSportLimitSettings = (data) =>
  APIHelper.post(url.SET_SPORT_MARKET_LIMIT(data.type), data.payload);
export const deleteSportLimitSetting = (id, type) =>
  APIHelper.post(url.DELETE_SPORT_MARKET_LIMIT(type), { id });
export const getAccountLimitSettings = (id) =>
  APIHelper.get(url.GET_ACCOUNT_MARKET_LIMIT(id));
export const setAccountLimitSettings = (id, type, payload) =>
  APIHelper.post(url.SET_ACCOUNT_MARKET_LIMIT(id, type), payload);
export const deleteAccountLimitSetting = (id, type) =>
  APIHelper.delete(url.DELETE_ACCOUNT_MARKET_LIMIT(id, type));
export const setFancySettings = (type, payload) =>
  APIHelper.post(url.SET_MARKET_LIMIT(type), payload);

// Casinos
export const getCasinos = (payload) => APIHelper.get(url.GET_CASINOS, payload);
export const createCasino = (payload) =>
  APIHelper.post(url.POST_CASINOS, payload);
export const updateCasino = (id, payload) =>
  APIHelper.put(url.PUT_CASINOS(id), payload);
export const changeCasinoGameStatusApi = (id, payload) =>
  APIHelper.patch(url.PATCH_CASINO(id), payload);
export const deleteCasino = (ids) => APIHelper.patch(url.DELETE_CASINOS, ids);

// Aura Casinos
export const getAuraCasinos = (payload) =>
  APIHelper.get(url.GET_AURA_CASINOS, payload);
export const createAuraCasino = (payload) =>
  APIHelper.post(url.POST_AURA_CASINOS, payload);
export const updateAuraCasino = (id, payload) =>
  APIHelper.put(url.PUT_AURA_CASINOS(id), payload);
export const changeAuraCasinoGameStatusApi = (id, payload) =>
  APIHelper.patch(url.PATCH_AURA_CASINO(id), payload);
export const deleteAuraCasino = (ids) =>
  APIHelper.patch(url.DELETE_AURA_CASINOS, ids);

//All Casinos
export const getAllCasinos = () => APIHelper.get(url.GET_ALL_CASINOS);

// CasinosProviders
export const getCasinoProviders = (payload) =>
  APIHelper.get(url.GET_CASINO_PROVIDERS, payload);
export const addCasinoProvider = (payload) =>
  APIHelper.post(url.POST_CASINO_PROVIDERS, payload);
export const updateCasinoProvider = (id, payload) =>
  APIHelper.put(url.PUT_CASINO_PROVIDERS(id), payload);
export const getGameProviders = () =>
  APIHelper.get(url.GET_CASINO_PROVIDERS_LIST);

// CasinosGameTypes
export const getCasinoGameTypes = (payload) =>
  APIHelper.get(url.GET_CASINO_GAME_TYPES, payload);
export const addCasinoGameType = (payload) =>
  APIHelper.post(url.POST_CASINO_GAME_TYPES, payload);
export const updateCasinoGameType = (id, payload) =>
  APIHelper.put(url.PUT_CASINO_GAME_TYPES(id), payload);
export const getGameTypes = () => APIHelper.get(url.GET_CASINO_GAME_TYPES_LIST);

//Rules
export const getSportTypesListApi = () =>
  APIHelper.get(url.GET_EVENT_TYPE_LIST);
export const addSiteRulesAPI = (data) =>
  APIHelper.post(url.ADD_SITE_RULES, data);
export const getSiteRulesAPI = (payload) =>
  APIHelper.get(url.GET_SITE_RULES, payload);
export const updateSiteRulesAPI = ({ values, id }) =>
  APIHelper.put(url.UPDATE_SITE_RULES(id), values);
export const deleteSiteRulesAPI = ({ id }) =>
  APIHelper.delete(url.DELETE_SITE_RULES(id));

//General Settings
export const getGeneralSettingsApi = (data) =>
  APIHelper.get(url.GET_GENERAL_SETTINGS(data));
export const updateGeneralSettingsApi = ({ name, data }) =>
  APIHelper.patch(url.PATCH_GENERAL_SETTINGS(name), data);
