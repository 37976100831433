import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getEvents as getEventsAPI,
  importEvent as importEventAPI,
  eventBetLock as eventBetLockAPI,
} from '../../../helpers/backend_helper';

export const getEvents = createAsyncThunk('market/getEvents', async (data) => {
  const { payload, params } = data;
  const response = await getEventsAPI(payload, params);
  return response.data;
});


export const importEvent = createAsyncThunk('market/importEvent', async (payload) => {
  const response = await importEventAPI(payload);
  return response.data;
});

export const eventBetLock = createAsyncThunk("market/eventBetLock", async (payload) => {
  const response = await eventBetLockAPI(payload);
  return response.data;
});
