import React from 'react';
const moment = require('moment-timezone');

const DateTimeDisplay = ({ date }) => {
  const getTime = () => {
    const timeZone = moment.tz.guess();
    const res = moment(date, moment.ISO_8601, true)["_isValid"];
    if(res) return moment(date).tz(timeZone).format('DD-MM-YYYY hh:mm:ss A')
    else return moment.unix(date).tz(timeZone).format('DD-MM-YYYY hh:mm:ss A');
  };
  return <React.Fragment>{getTime()}</React.Fragment>;
};

const DateTimeSecondDisplay = ({ date }) => {
  const getTime = () => {
    const timeZone = moment.tz.guess();
    return moment(date).tz(timeZone).format('DD-MM-YYYY hh:mm:ss A');
  };
  return <React.Fragment>{getTime()}</React.Fragment>;
};

export { DateTimeDisplay, DateTimeSecondDisplay };
