import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Container,
  Label,
  Row,
} from "reactstrap";
import { DateTimeSecondDisplay } from "../../../../Components/Common/DateTimeDisplay";
import { useDispatch, useSelector } from "react-redux";

const ProfileTab = ({ account }) => {
  const dispatch = useDispatch();
  const { countUser } = useSelector((state) => ({
    countUser: state.User.userWithSameIp,
  }));

  return (
    <React.Fragment>
      <Container fluid className="bg-light">
        <Row>
          <Col md={6} sm={12} className="mt-3">
            <Card className="p-1">
              <CardBody className="d-flex justify-content-center">
                <div className="d-flex flex-column text-center">
                  <img
                    src="https://cdn.iconscout.com/icon/free/png-256/free-avatar-370-456322.png?f=webp"
                    alt="avatar"
                    width={"100px"}
                  />
                  <CardTitle tag={"h5"}>
                    {account?.firstName}
                    {account?.lastName}
                  </CardTitle>
                  <CardText>{account?.username}</CardText>
                </div>
              </CardBody>
              <hr className="my-3" />
              <div className="d-flex flex-row justify-content-around">
                <div>
                  <i className="ri-phone-fill"></i>
                </div>
                <div>
                  <i className="ri-building-4-fill"></i>
                </div>
              </div>
            </Card>
            <Card className="p-3">
              <CardTitle>Partnership Information</CardTitle>
              <CardBody>
                <Row>
                  <Col>
                    <Label>Partnership Name :</Label>
                  </Col>
                  <Col>
                    <p>Partnership with No Return</p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Label>User Part :</Label>
                  </Col>
                  <Col>
                    <p>100</p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Label>Our Part :</Label>
                  </Col>
                  <Col>
                    <p>{account?.partnershipPercentage}</p>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col md={6} sm={12} className="mt-3">
            <Card className="p-4">
              <CardTitle tag={"h3"}>Additional Information</CardTitle>
              <CardBody>
                <Col>
                  <Row>
                    <Col>
                      <Label>Username : </Label>
                    </Col>
                    <Col> {account?.username}</Col>
                  </Row>
                  <Row>
                    <Col>
                      <Label>FullName :</Label>
                    </Col>
                    <Col>
                      {account?.firstName}
                      {account?.lastName}
                    </Col>
                  </Row>
                  {/* <Row>
                    <Col>
                      <Label>Mobile Number :</Label>{" "}
                    </Col>
                    <Col>
                      {account?.mobileNumber
                        ? account.mobileNumber
                        : "321546987"}
                    </Col>
                  </Row> */}
                  <Row>
                    <Col>
                      <Label>City :</Label>
                    </Col>
                    <Col>{account?.city || "-"} </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Label>Credit pts : </Label>
                    </Col>
                    <Col>{Math.round(account?.credit * 100) / 100 || 0}</Col>
                  </Row>
                  <Row>
                    <Col>
                      <Label>Balance :</Label>{" "}
                    </Col>
                    <Col>{account?.balance || 0} </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Label>Client P/L :</Label>{" "}
                    </Col>
                    <Col>{account?.profitLoss || 0}</Col>
                  </Row>
                  <Row>
                    <Col>
                      <Label>Exposure : </Label>
                    </Col>
                    <Col>{account?.exposure || 0}</Col>
                  </Row>
                  <Row>
                    <Col>
                      <Label>Casino Pts :</Label>{" "}
                    </Col>
                    <Col>{account?.totalCasinoProfit || 0} </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Label>Sports Pts : </Label>
                    </Col>
                    <Col>{account?.totalSportProfit || 0} </Col>
                  </Row>
                  {/* <Row>
                    <Col>
                      <Label>Third Party Pts : </Label>
                    </Col>
                    <Col>0 </Col>
                  </Row> */}
                  <Row>
                    <Col>
                      <Label>Created Date :</Label>
                    </Col>
                    <Col>
                      <DateTimeSecondDisplay date={account?.createdAt} />{" "}
                    </Col>
                  </Row>
                </Col>
              </CardBody>
            </Card>

            <Card className="p-4">
              <CardTitle tag={"h3"}>More Information</CardTitle>
              <CardBody>
                <Row>
                  <Col>
                    <Label>Users With Same IP Address</Label>
                  </Col>
                  <Col>{countUser}</Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default ProfileTab;
