import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import { getPaymentModeList } from "../../../../store/actions";

const PaymentModeTab = ({ account }) => {
  const dispatch = useDispatch();

  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [sortField, setSortField] = useState("amount");
  const [sortOrder, setSortOrder] = useState("desc");
  const [loading, setLoading] = useState(false);

  const { paymentModes, totalRows } = useSelector((state) => ({
    paymentModes: state.PaymentMode.paymentModes,
    totalRows: state.PaymentMode.totalRows,
  }));

  const columns = [
    {
      name: <span className="font-weight-bold fs-13">Name</span>,
      selector: (row) => row.name,
      sortable: true,
      fieldName: "name",
    },
    {
      name: <span className="font-weight-bold fs-13">Type</span>,
      selector: (row) => row.type,
      sortable: true,
      fieldName: "type",
    },
    {
      name: <span className="font-weight-bold fs-13">Bank Name</span>,
      selector: (row) => row.bank_name,
      sortable: true,
      fieldName: "bank_name",
    },
    {
      name: <span className="font-weight-bold fs-13">Account Holder Name</span>,
      selector: (row) => row.bank_account_holder_name,
      sortable: true,
      fieldName: "bank_account_holder_name",
    },
    {
      name: <span className="font-weight-bold fs-13">IFSC Code</span>,
      selector: (row) => row.bank_ifsc,
      sortable: true,
      fieldName: "bank_ifsc",
    },
    {
      name: <span className="font-weight-bold fs-13">Account Number</span>,
      selector: (row) => row.bank_account_number,
      sortable: true,
      fieldName: "bank_account_number",
    },
    {
      name: <span className="font-weight-bold fs-13">QR Code</span>,
      selector: (row) => row.bank_qr_code,
      sortable: true,
      fieldName: "bank_qr_code",
    },
    {
      name: <span className="font-weight-bold fs-13">Min Amount</span>,
      selector: (row) => row.min_amount,
      sortable: true,
      fieldName: "min_amount",
    },
    {
      name: <span className="font-weight-bold fs-13">Max Amount</span>,
      selector: (row) => row.max_amount,
      sortable: true,
      fieldName: "max_amount",
    },
    {
      name: <span className="font-weight-bold fs-13">QR Image</span>,
      selector: (row) => (
        <React.Fragment>
          {row.bank_qr_image ? (
            <img
              src={row.bank_qr_image}
              alt={row.name}
              height="30"
              className="me-2 border"
            />
          ) : null}
        </React.Fragment>
      ),
      sortable: false,
      fieldName: "bank_qr_image",
    },
    {
      name: <span className="font-weight-bold fs-13">Status</span>,
      selector: (row) => (
        <React.Fragment>{row.isActive ? "Active" : "In Active"}</React.Fragment>
      ),
      sortable: true,
      fieldName: "isActive",
    },
  ];

  const refreshData = () => {
    setLoading(true);
    dispatch(getPaymentModeList({ page, limit, sortOrder, sortField, userId: account._id }))
    .then( res => setLoading(false))
  };

  useEffect(() => {
    if(account?._id) refreshData();
  }, [dispatch, page, limit, sortOrder, sortField, account]);

  const handlePerRowsChange = async (newPerPage, page) => setLimit(newPerPage);

  const handleSort = (column, sortDirection) => {
    setSortField(column.fieldName);
    setSortOrder(String(sortDirection).toLowerCase());
  };

  return (
    <React.Fragment>
      <DataTable
        columns={columns}
        data={paymentModes}
        pagination={true}
        paginationTotalRows={totalRows}
        paginationDefaultPage={page}
        paginationPerPage={limit}
        onChangePage={(e) => setPage(e)}
        onChangeRowsPerPage={(e) => setLimit(e)}
        onSort={handleSort}
        progressPending={loading}
        responsive={true}
      />
    </React.Fragment>
  );
};

export default PaymentModeTab;
