import React, { useEffect, useState } from "react";
import DwCommonTable from "../DwComonTable";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Row } from "reactstrap";
import {
  getApprovedVerifiedDeposit,
  getApprovedWithdrawal,
} from "../../../../store/payment/action";
import { DateTimeSecondDisplay } from "../../../../Components/Common/DateTimeDisplay";
import { changeLoader } from "../../../../store/actions";

const DWTab = ({ account }) => {
  const dispatch = useDispatch();

  const {
    approvedWithdrawList,
    withdrawTotal,
    approvedVerifiedDepositList,
    depositTotal,
  } = useSelector((state) => ({
    approvedWithdrawList: state.Payment.approvedWithdrawList.data,
    withdrawTotal: state.Payment.approvedWithdrawList.total,
    approvedVerifiedDepositList: state.Payment.approvedVerifiedDepositList.data,
    depositTotal: state.Payment.approvedVerifiedDepositList.total,
  }));

  const [sortField, setSortField] = useState("verificationDate");
  const [sortFieldWithdraw, setSortFieldWithdraw] = useState("approvalDate");
  const [sortOrder, setSortOrder] = useState("desc");
  const [sortOrderWithdraw, setSortOrderWithdraw] = useState("desc");
  const [limit, setLimit] = useState(10);
  const [limitWithdraw, setLimitWithdraw] = useState(10);
  const [page, setPage] = useState(1);
  const [pageWithdraw, setPageWithdraw] = useState(1);
  const [loading, setLoading] = useState(false);

  const handlePerRowsChange = async (newPerPage, page) => {
    setLimit(newPerPage);
  };
  const handlePerRowsChangeWithdraw = async (newPerPage, page) => {
    setLimitWithdraw(newPerPage);
  };

  const handleSort = (column, sortDirection) => {
    setSortField(column.fieldName);
    setSortOrder(String(sortDirection).toLowerCase());
  };

  const handleSortWithdraw = (column, sortDirection) => {
    setSortFieldWithdraw(column.fieldName);
    setSortOrderWithdraw(String(sortDirection).toLowerCase());
  };

  const handleSelection = () => false;

  const paginationData = {
    userId: account._id,
    sortField,
    sortOrder,
    limit,
    page,
  };

  const paginationDataWithdraw = {
    userId: account._id,
    sortField: sortFieldWithdraw,
    sortOrder: sortOrderWithdraw,
    limit: limitWithdraw,
    page: pageWithdraw,
  };

  useEffect(() => {
    if (account?._id) {
      dispatch(changeLoader(true));
      dispatch(getApprovedVerifiedDeposit(paginationData)).then((res) =>
        dispatch(changeLoader(false))
      );
      dispatch(changeLoader(false));
    }
  }, [sortField, sortOrder, limit, page, account]);

  useEffect(() => {
    if (account?._id) {
      dispatch(changeLoader(true));
      dispatch(getApprovedWithdrawal(paginationDataWithdraw)).then((res) =>
        dispatch(changeLoader(false))
      );
      dispatch(changeLoader(false));
    }
  }, [
    sortFieldWithdraw,
    sortOrderWithdraw,
    limitWithdraw,
    pageWithdraw,
    account,
  ]);

  const withdrawColumns = [
    {
      name: <span className="font-weight-bold fs-13">Approval Date</span>,
      sortable: true,
      fieldName: "approvalDate",
      minWidth: "210px",
      cell: (e) => {
        return (
          <React.Fragment>
            <Row className="pt-1 pb-1">
              <Col sm="12">
                <DateTimeSecondDisplay date={e.approvalDate} />
              </Col>
            </Row>
          </React.Fragment>
        );
      },
    },

    {
      name: <span className="font-weight-bold fs-13">Status</span>,
      maxWidth: "40px",

      selector: (row) => {
        return (
          <div className="d-flex gap-2 align-items-center">
            <span className="badge badge-soft-info">
              {row.status === 1 && "Approved"}
            </span>
          </div>
        );
      },
    },
    {
      name: <span className="font-weight-bold fs-13">Remarks</span>,
      selector: (row) => row?.approvalRemark,
      sortable: true,
      fieldName: "approvalRemark",
      maxWidth: "60px",
    },
    {
      name: <span className="font-weight-bold fs-13">Verified By</span>,
      selector: (row) => row?.username,
      sortable: true,
      fieldName: "username",
    },
  ];

  const depositColumns = [
    {
      name: <span className="font-weight-bold fs-13">Amount</span>,
      selector: (row) => Math.round(row?.amount * 100) / 100,
      sortable: true,
      fieldName: "amount",
      minWidth: "60px",
    },
    {
      name: <span className="font-weight-bold fs-13">Date</span>,
      sortable: false,
      minWidth: "210px",
      sortable: true,
      fieldName: "verificationDate",
      cell: (e) => {
        return (
          <React.Fragment>
            <Row className="pt-1 pb-1">
              <Col sm="12">
                TD : <DateTimeSecondDisplay date={e.transactionDate} />
              </Col>
              <Col sm="12">
                VD : <DateTimeSecondDisplay date={e.verificationDate} />
              </Col>
            </Row>
          </React.Fragment>
        );
      },
    },

    {
      name: <span className="font-weight-bold fs-13">Status</span>,
      minWidth: "90px",

      selector: (row) => {
        return (
          <div className="d-flex gap-2 align-items-center">
            <span className="badge badge-soft-info">
              {row.status == 1
                ? "Approved"
                : row.status == 2
                ? "Appr.PC"
                : row.status == 4
                ? "Appr.Direct"
                : ""}
            </span>
          </div>
        );
      },
    },
    {
      name: <span className="font-weight-bold fs-13">Verified By</span>,
      selector: (row) => row?.username,
      sortable: true,
      fieldName: "username",
      minWidth: "140px",
    },
  ];

  return (
    <Container fluid>
      <div className="d-flex gap-2 w-100">
        <div className="p-1 w-50">
          <h5 className="mt-3 mb-2 ps-2 ">Deposit History</h5>
          <hr />
          <DwCommonTable
            columns={depositColumns}
            data={approvedVerifiedDepositList}
            sortField={sortField}
            sortOrder={sortOrder}
            totalRows={depositTotal}
            limit={limit}
            page={page}
            loading={loading}
            setPage={setPage}
            handlePerRowsChange={handlePerRowsChange}
            handleSort={handleSort}
            handleSelection={handleSelection}
          />
        </div>

        <div className="p-1 w-50">
          <h5 className="mt-3 mb-2 ps-2">Withdraw History</h5>
          <hr />

          <DwCommonTable
            columns={withdrawColumns}
            data={approvedWithdrawList}
            sortField={sortFieldWithdraw}
            sortOrder={sortOrderWithdraw}
            totalRows={withdrawTotal}
            limit={limitWithdraw}
            page={pageWithdraw}
            loading={loading}
            setPage={setPageWithdraw}
            handlePerRowsChange={handlePerRowsChangeWithdraw}
            handleSort={handleSortWithdraw}
            handleSelection={handleSelection}
          />
        </div>
      </div>
    </Container>
  );
};

export default DWTab;
