import React, { useLayoutEffect } from "react";
import { Navigate, Route } from "react-router-dom";
import { setAuthorization } from "../helpers/api_helper";
import { useDispatch, useSelector } from "react-redux";
import { useProfile } from "../Components/Hooks/UserHooks";
import { logoutUser } from "../store/actions";
import { isFunction } from "lodash";

const AuthProtected = (props) => {
  const dispatch = useDispatch();
  const { userProfile, loading, token } = useProfile();

  const { authRoutes } = useSelector((state) => ({
    authRoutes: state.User.authRoutes,
  }));

  useLayoutEffect(() => {
    if (userProfile && !loading && token) {
      setAuthorization(token);
    } else if (!userProfile && loading && !token) {
      dispatch(logoutUser());
    }
  }, [token, userProfile, loading, dispatch]);
  if (Object.keys(authRoutes).length && authRoutes[props.route] !== 1) {
    const routeUrl = props.route?.trim().split("/").filter(Boolean);

    if (
      routeUrl.length > 2 &&
      authRoutes[`/${routeUrl[0]}/${routeUrl[1]}`] === 1
    ) {
      return <>{props.children}</>;
    }
    const module = routeUrl?.length >= 1 ? routeUrl[routeUrl.length - 1] : "";
    return <>{props.children}</>;
    // return (
    //   <Navigate to="/dashboard" state={{ isRedirect: true, module: module }} />
    // );
  }

  /*
    redirect is un-auth access protected routes via url
  */
  if (!userProfile && loading && !token) {
    return (
      <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
    );
  }
  return <>{props.children}</>;
};

const AccessRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          <>
            <Component {...props} />
          </>
        );
      }}
    />
  );
};

export { AuthProtected, AccessRoute };
