import axios from "axios";
import { api } from "../config";
import { postRefreshToken } from "../helpers/backend_helper";
import { json, useHistory } from "react-router-dom";
import { ClientJS } from "clientjs";
import { decrypt } from "./methods/encryptDecrypt";
import * as url from "./url_helper";

const client = new ClientJS();

const browser = client.getBrowser();
const browserVersion = client.getBrowserVersion();
const fingerprint = client.getFingerprint();
const isMobile = client.isMobile();

let ipData = localStorage.getItem("_GE_IED_");
if (ipData) ipData = decrypt(ipData);
const headers = { "Content-Type": "application/json" };
let updateHeaders = {
  "Content-Type": "application/json",
  "X-Client-Browser": browser,
  "X-Client-Browser-Version": browserVersion,
  "X-Client-Fingerprint": fingerprint,
  "X-Client-Device": isMobile ? "MOBILE" : "WEB",
};

if (ipData) {
  updateHeaders = {
    ...updateHeaders,
    ...{
      "X-Client-IP": ipData?.ip ? ipData?.ip : fingerprint,
    },
  };
}
// const history = useHistory();
const apiRequest = axios.create({
  baseURL: api.API_URL, // URL of your API
  headers,
});

// content type
const token = JSON.parse(localStorage.getItem("authUser"))
  ? JSON.parse(localStorage.getItem("authUser")).token
  : null;
if (token) apiRequest.defaults.headers.common["Authorization"] = "Bearer " + token;

/**
 * Sets the default authorization setRefreshToken
 * @param {*} token
 */
const setRefreshToken = (token) => {
  apiRequest.defaults.headers.common["Authorization"] = "Bearer " + token;
  apiRequest.interceptors.request.use((config) => {
    config.headers["Authorization"] = "Bearer " + token;
    return config;
  });
};

/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token) => {
  apiRequest.interceptors.request.use((config) => {
    config.headers["Authorization"] = "Bearer " + token;
    return config;
  });
  apiRequest.defaults.headers.common["Authorization"] = "Bearer " + token;
};

const updateHeader = (ipData) => {
  updateHeaders = {
    ...updateHeaders,
    ...{ "X-Client-IP": ipData?.ip ? ipData?.ip : fingerprint },
  };

  apiRequest.interceptors.request.use((config) => {
    config.headers = {
      ...updateHeaders,
      ...{ "X-Client-IP": ipData?.ip ? ipData?.ip : fingerprint },
    };
    return config;
  });

  apiRequest.defaults.headers.common["X-Client-IP"] = ipData?.ip ? ipData?.ip : fingerprint;
};

apiRequest.interceptors.request.use(
  (config) => {
    let userProfileSession = getLoggedinUser();
    if (userProfileSession) {
      const { accessToken } = userProfileSession;
      if (accessToken) {
        if (config.url !== url.GET_IP)
          config.headers = {
            ...updateHeaders,
            ...{
              Authorization: `Bearer ${accessToken}`,
              Accept: "application/json",
            },
          };
        else
          config.headers = {
            Authorization: `Bearer ${accessToken}`,
            Accept: "application/json",
          };
      }
    } else {
      if (config.url !== url.GET_IP) config.headers = updateHeaders;
      else config.headers = { Accept: "application/json" };
    }
    return config;
  },
  (err) => Promise.reject(err)
);

// intercepting to capture errors
apiRequest.interceptors.response.use(
  function (response) {
    return response.data ? response.data : response;
  },
  async function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    let message;
    let userProfileSession;
    let response;
    let refreshCounter = localStorage.getItem("refreshCounter");
    let originalRequest;
    switch (error?.response?.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 404:
        message = "Sorry! the data you are looking for could not be found";
        break;
      case 403:
        originalRequest = error.config;
        userProfileSession = getLoggedinUser();

        if (refreshCounter == null) {
          localStorage.setItem("refreshCounter", 0);
          //   refreshCounter = 3;
        }
        refreshCounter = !refreshCounter ? localStorage.getItem("refreshCounter") : refreshCounter;
        if (refreshCounter < 5) {
          refreshCounter++;
          localStorage.setItem("refreshCounter", refreshCounter);
          setRefreshToken(userProfileSession.refreshToken);
          response = await postRefreshToken({
            refreshToken: userProfileSession.refreshToken,
          });
          if (response) {
            const jsonString = JSON.stringify(response.data);
            localStorage.setItem("authUser", jsonString);
            localStorage.removeItem("refreshCounter");
            setAuthorization(response.data.accessToken);

            const originalConfig = {
              baseURL: originalRequest.baseURL,
              url: originalRequest.url,
              method: originalRequest.method,
              data: originalRequest.data,
              headers: {
                ...originalRequest.headers,
                Authorization: `Bearer ${response.data.accessToken}`,
              },
            };

            return apiRequest.request(originalConfig);
          } else {
            localStorage.removeItem("refreshCounter");
            localStorage.removeItem("authUser");
            window.location = "/";
          }
        } else {
          window.location = "/";
          localStorage.removeItem("refreshCounter");
          localStorage.removeItem("authUser");
          message = "Refresh token is not working";
        }

        break;
      default:
        message = error.response?.data?.message || error.message || error;
    }
    return Promise.reject(message);
  }
);

class APIClient {
  /**
   * Fetches data from given url
   */

  //  get = (url, params) => {
  //   return apiRequest.get(url, params);
  // };
  get = (url, params) => {
    let response;

    let paramKeys = [];
    if (params) {
      Object.keys(params).map((key) => {
        paramKeys.push(key + "=" + params[key]);
        return paramKeys;
      });

      const queryString = paramKeys && paramKeys.length ? paramKeys.join("&") : "";
      response = apiRequest.get(`${url}?${queryString}`);
    } else {
      response = apiRequest.get(`${url}`);
    }

    return response;
  };

  /**
   * post given data to url
   */
  post = (url, data, params) => {
    let paramKeys = [];
    if (params) {
      Object.keys(params).map((key) => {
        paramKeys.push(key + "=" + params[key]);
        return paramKeys;
      });

      const queryString = paramKeys && paramKeys.length ? paramKeys.join("&") : "";
      url = `${url}?${queryString}`;
    }
    return apiRequest.post(url, data);
  };
  /**
   * Updates data
   */
  update = (url, data) => {
    return apiRequest.patch(url, data);
  };

  put = (url, data) => {
    return apiRequest.put(url, data);
  };

  patch = (url, data) => {
    return apiRequest.patch(url, data);
  };
  /**
   * Delete
   */
  delete = (url, config) => {
    return apiRequest.delete(url, { ...config });
  };
}

const getLoggedinUser = () => {
  const user = localStorage.getItem("authUser");
  if (!user) {
    return null;
  } else {
    return JSON.parse(user);
  }
};

const APIHelper = new APIClient();
export { APIHelper, setAuthorization, getLoggedinUser, updateHeader };
