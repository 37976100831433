import { createSlice } from "@reduxjs/toolkit";

import {
  getAuraCasinoList,
  createAuraCasino,
  updateAuraCasino,
  changeAuraCasinoGameStatus,
  deleteAuraCasino,
} from "./action";
import { toast } from "react-toastify";

const initialState = {
  auraCasinos: {
    data: [],
    totalRows: 0,
  },
  allAuraCasinosList: [],
};

const AuraCasinos = createSlice({
  name: "AuraCasinos",
  initialState,
  reducers: {},
  extraReducers: (b) => {
    b.addCase(getAuraCasinoList.fulfilled, (state, action) => {
      state.auraCasinos = {
        data: action.payload.items,
        totalRows: action.payload.total,
      };
    });

    b.addCase(createAuraCasino.fulfilled, (state, action) => {
      if (action.payload)
        return {
          ...state,
          casinos: {
            data: [...state.auraCasinos.data, action.payload.data],
            totalRows: state.auraCasinos.totalRows + 1,
          },
        };
    }).addCase(createAuraCasino.rejected, (state, action) => {
      if (action.payload) toast.success(action.payload.message);

      if (action.error) {
        toast.error(action?.error?.message);
      }
    });

    b.addCase(updateAuraCasino.fulfilled, (state, action) => {
      if (action.payload) {
        toast.success(action.payload.message);
        return {
          ...state,
          casinos: {
            data: state.auraCasinos.data.map((cp) =>
              cp._id.toString() === action.payload.data._id.toString()
                ? { ...cp, ...action.payload.data }
                : cp
            ),
            totalRows: state.auraCasinos.totalRows,
          },
        };
      }
    }).addCase(updateAuraCasino.rejected, (state, action) => {
      if (action.error) {
        toast.error(action?.error?.message);
      }
    });

    b.addCase(changeAuraCasinoGameStatus.fulfilled, (state, action) => {
      if (action.payload) {
        toast.success(action.payload?.message);
      }
    });

    b.addCase(deleteAuraCasino.fulfilled, (state, action) => {
      if (action.payload) {
        toast.success(action.payload.message);
      }
    });
    b.addCase(changeAuraCasinoGameStatus.rejected, (state, action) => {
      if (action.error) toast.error(action?.error?.message);
      else toast.error("Error occured");
    });
  },
});

export default AuraCasinos.reducer;
