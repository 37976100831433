import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getStakeButton,
  createStakeButton as createStakeButtonAPI,
  updateStakeButton as updateStakeButtonAPI,
  deleteStakeButton as deleteStakeButtonAPI,
} from "../../../helpers/backend_helper";
import { toast } from "react-toastify";

// StakeButtons
export const getStakeButtonList = createAsyncThunk("market/getStakeButtonList", async (payload) => {
  const response = await getStakeButton(payload);
  return response.data;
});

export const createStakeButton = createAsyncThunk("market/createStakeButton", async (payload) => {
  try {
    const response = await createStakeButtonAPI(payload);
    return response.data;
  } catch (error) {
    toast.error("Failed to Add Stake Button");
    return false;
  }
});

export const updateStakeButton = createAsyncThunk("market/updateStakeButton", async (payload) => {
  try {
    const response = await updateStakeButtonAPI(payload.id, payload.data);
    return response.data;
  } catch (error) {
    toast.error("Failed to Update Stake Button");
    return false;
  }
});

export const deleteStakeButton = createAsyncThunk("market/deleteStakeButton", async (payload) => {
  try {
    const response = await deleteStakeButtonAPI(payload);
    return response.data;
  } catch (error) {
    toast.error("Failed to Delete Stake Button");
    return false;
  }
});
