import React from "react";
import Datatable from "../../../Components/Common/Datatable";

const DwCommonTable = ({
  totalRows,
  loading,
  setPage,
  handlePerRowsChange,
  handleSort,
  handleSelection,
  data,
  columns,
}) => {
  return (
    <React.Fragment>
      <Datatable
        columns={columns}
        data={data}
        totalRows={totalRows}
        handlePerRowsChange={handlePerRowsChange}
        handlePageChange={setPage}
        handleSort={handleSort}
        handleSelection={handleSelection}
        loader={loading}
        selectableRows={false}
      />
    </React.Fragment>
  );
};

export default DwCommonTable;
