import { createSlice } from "@reduxjs/toolkit";
import {
  getInPlay,
  getAllMatch,
  getBetsCount,
  getEventMarkets,
  getEventBetsCount,
  getOpenBetMarkets,
  getMarketBets,
  getMarketOdds,
  getPlAnalysis,
  getPlByMarket,
  getPlMarketTypes,
  getPlEventTypes,
} from "./action";

const initialState = {
  inplayMatches: [],
  allMatches: [],
  oddsBets: {},
  fancyBets: {},
  eventData: {},
  marketdata: {},
  openBetsMarkets: [],
  plAnalysis: { items: [], total: 0 },
  plEventTypes: [],
  plMarketTypes: [],
  plMarketBet: { items: [], totalRows: 0 },
};

const Analysis = createSlice({
  name: "Analysis",
  initialState,
  reducers: {
    setMarketData: (state, action) => {
      state.marketdata[action.payload.subscribedMarketID] = action.payload;
    },
    resetMarketData: (state, action) => {
      state.oddsBets = {};
      state.fancyBets = {};
      state.eventData = {};
      state.marketdata = {};
      state.openBetsMarkets = [];
    },
  },
  extraReducers: (b) => {
    b.addCase(getInPlay.fulfilled, (state, action) => {
      state.inplayMatches = action.payload;
    });
    b.addCase(getAllMatch.fulfilled, (state, action) => {
      state.allMatches = action.payload;
    });
    b.addCase(getBetsCount.fulfilled, (state, action) => {
      if (action.payload?.oddsBets) {
        const transformedData = state.oddsBets;
        action.payload?.oddsBets.forEach((item) => (transformedData[item._id] = item.data));
        state.oddsBets = transformedData;
      }
      if (action.payload?.fancyBets) {
        const transformedData = state.fancyBets;
        action.payload?.fancyBets.forEach((item) => (transformedData[item._id] = item.data));
        state.fancyBets = transformedData;
      }
    });

    b.addCase(getEventMarkets.fulfilled, (state, action) => {
      state.eventData = action?.payload;
    });

    b.addCase(getEventBetsCount.fulfilled, (state, action) => {
      // console.log("===", action);
      // state.allMatches = action.payload;
    });

    b.addCase(getOpenBetMarkets.fulfilled, (state, action) => {
      state.openBetsMarkets = action?.payload;
    });
    b.addCase(getMarketBets.fulfilled, (state, action) => {
      state.marketBets = action?.payload;
    });

    b.addCase(getMarketOdds.fulfilled, (state, action) => {
      if (action.payload && action.payload.length) {
        for (let x = 0; x < action.payload.length; x++)
          state.marketdata[action.payload[x].subscribedMarketID] = action.payload[x];
      }
    });
    b.addCase(getPlAnalysis.fulfilled, (state, action) => {
      state.plAnalysis.items = action?.payload.items;
      state.plAnalysis.total = action?.payload.total;
    });

    b.addCase(getPlByMarket.fulfilled, (state, action) => {
      state.plMarketBet.items = action?.payload?.items;
      state.plMarketBet.totalRows = action?.payload?.totalRows;
    });

    b.addCase(getPlMarketTypes.fulfilled, (state, action) => {
      state.plMarketTypes = action?.payload.map((item) => item._id);
    });

    b.addCase(getPlEventTypes.fulfilled, (state, action) => {
      state.plEventTypes = action?.payload;
    });
  },
});

export const { setMarketData, resetMarketData } = Analysis.actions;

export default Analysis.reducer;
