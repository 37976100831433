import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getAllRevenueData,
  getCreditGraphAPI,
  getDepositsDataAPI,
  getPLGraphAPI,
  getWithdrawalsDataAPI,
} from "../../helpers/backend_helper";

export const getRevenueChartsData = createAsyncThunk(
  "Dashboard/getRevenueChartsData",
  getAllRevenueData
);
export const getDepositsData = createAsyncThunk(
  "Promotion/getDepositsData",
  async (params) => {
    const response = await getDepositsDataAPI(params);
    return response.data;
  }
);

export const getWithdrawalsData = createAsyncThunk(
  "Promotion/getWithdrawalsData",
  async (params) => {
    const response = await getWithdrawalsDataAPI(params);
    return response.data;
  }
);

export const getPLGraphData = createAsyncThunk(
  "Dashboard/getPLGraphData",
  async (params) => {
    const response = await getPLGraphAPI(params);
    return response.data;
  }
);

export const getCreditGraphData = createAsyncThunk(
  "Dashboard/getCreditGraphData",
  async (params) => {
    const response = await getCreditGraphAPI(params);
    return response.data;
  }
);
