import { createSlice } from "@reduxjs/toolkit";
import { getSportTypes, updateSportType } from "./action";
import { toast } from "react-toastify";

const initialState = {
  sports: [],
  totalRows: 0,
  sportNames: [],
};

const Sports = createSlice({
  name: "Sports",
  initialState,
  reducers: {},
  extraReducers: (b) => {
    b.addCase(getSportTypes.fulfilled, (state, action) => {
      state.sports = action.payload.items ? action.payload.items : [];
      state.totalRows = action.payload.total ? action.payload.total : 0;
    });

    b.addCase(updateSportType.fulfilled, (state, action) => {
      return {
        ...state,
        sports: state.sports.map((sport) =>
          sport._id.toString() === action.payload._id.toString()
            ? { ...sport, ...action.payload }
            : sport
        ),
      };
    });
  },
});

export default Sports.reducer;
