import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Card,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
  CardBody,
  TabContent,
  TabPane,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import ChangePasswordTab from "./DetailsTabs/ChangePasswordTab";
import ChangeTransactionCodeTab from "./DetailsTabs/ChangeTransactionCodeTab";
import UserLockTab from "./DetailsTabs/UserLockTab";
import LoginHistoryTab from "./DetailsTabs/LoginHistoryTab";
import DWTab from "./DetailsTabs/DWTab";
import ProfileTab from "./DetailsTabs/ProfileTab";
import { changeLoader, getSingleUserDetails } from "../../../store/actions";
import { ENUM_ADMIN_TYPE } from "../../../Components/constants/commonConstants";
import PaymentModeTab from "./DetailsTabs/PaymentModeTab";

const DetailModal = (props) => {
  const { isOpen, account, toggleDetail, userId } = props;
  const { singleUserDetail, modules } = useSelector((state) => ({
    singleUserDetail: state.User.singleUserDetail,
    modules: state.User.modules,
  }));
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("profile");
  useEffect(() => {
    setActiveTab("profile");
    if (modules?.["account-profile-button"] === 1 && userId) {
      dispatch(changeLoader(true));
      dispatch(getSingleUserDetails({ userId }))
        .then((res) => {
          dispatch(changeLoader(false));
        })
        .catch((error) => {
          dispatch(changeLoader(false));
        });
    }
  }, [userId]);

  const tabChange = (tab) => {
    if (activeTab !== tab) {
      //    navigate(`/users/${tab}`);
      setActiveTab(tab);
    }
  };

  useEffect(() => {}, [isOpen]);
  return (
    <Modal
      id="showModal"
      isOpen={isOpen}
      toggle={toggleDetail}
      centered
      className="modal-xl "
      // for scrolling====>  add classname --> modal-dialog-scrollable
    >
      <ModalHeader className="bg-light p-3" toggle={toggleDetail}>
        Details
      </ModalHeader>

      <ModalBody className="p-0">
        <Card className="mb-0">
          <CardHeader>
            <Nav
              className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
              role="tablist"
            >
              <NavItem>
                <NavLink
                  onClick={() => tabChange("profile")}
                  className={classnames({
                    active: activeTab === "profile",
                  })}
                >
                  Profile
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  onClick={() => tabChange("dw-history")}
                  className={classnames({
                    active: activeTab === "dw-history",
                  })}
                  type="button"
                >
                  D/W History
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  onClick={() => tabChange("login-history")}
                  className={classnames({
                    active: activeTab === "login-history",
                  })}
                  type="button"
                >
                  Login History
                </NavLink>
              </NavItem>
              {modules?.["account-profile-user-lock-button"] ||
              modules?.["account-profile-bet-lock-button"] ? (
                <NavItem>
                  <NavLink
                    onClick={() => tabChange("user-Lock")}
                    className={classnames({
                      active: activeTab === "user-Lock",
                    })}
                    type="button"
                  >
                    User Lock
                  </NavLink>
                </NavItem>
              ) : (
                ""
              )}
              <NavItem>
                <NavLink
                  onClick={() => tabChange("change-password")}
                  className={classnames({
                    active: activeTab === "change-password",
                  })}
                  type="button"
                >
                  Change Password
                </NavLink>
              </NavItem>
              {modules?.["site-management:payment-mode"] &&
                (singleUserDetail.type == ENUM_ADMIN_TYPE.AGENT ||
                  singleUserDetail.type == ENUM_ADMIN_TYPE.MASTER) && (
                  <NavItem>
                    <NavLink
                      onClick={() => tabChange("payment-mode")}
                      className={classnames({
                        active: activeTab === "payment-mode",
                      })}
                      type="button"
                    >
                      Payment Mode
                    </NavLink>
                  </NavItem>
                )}
              {modules?.["change-transaction-code-button"] &&
                singleUserDetail.type !== "USER" && (
                  <NavItem>
                    <NavLink
                      onClick={() => tabChange("change-transaction-code")}
                      className={classnames({
                        active: activeTab === "change-transaction-code",
                      })}
                      type="button"
                    >
                      Change Transaction Code
                    </NavLink>
                  </NavItem>
                )}
            </Nav>
          </CardHeader>
          <CardBody className="p-0">
            <TabContent activeTab={activeTab}>
              <TabPane tabId="profile">
                <ProfileTab account={singleUserDetail} />
              </TabPane>
              <TabPane tabId="dw-history">
                <DWTab account={singleUserDetail} />
              </TabPane>
              <TabPane tabId="login-history">
                <LoginHistoryTab account={singleUserDetail} />
              </TabPane>
              <TabPane tabId="user-Lock">
                <UserLockTab account={singleUserDetail} toggle={toggleDetail} />
              </TabPane>
              <TabPane tabId="change-password">
                <ChangePasswordTab
                  account={singleUserDetail}
                  toggle={toggleDetail}
                />
              </TabPane>
              {modules?.["site-management:payment-mode"] ? (
                <TabPane tabId="payment-mode">
                  <PaymentModeTab account={singleUserDetail} />
                </TabPane>
              ) : (
                ""
              )}
              <TabPane tabId="change-transaction-code">
                <ChangeTransactionCodeTab
                  account={singleUserDetail}
                  toggle={toggleDetail}
                />
              </TabPane>
            </TabContent>
          </CardBody>
        </Card>
      </ModalBody>
    </Modal>
  );
};

export default DetailModal;
