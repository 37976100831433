import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import socket from "../../sockets/socket";
import { useDispatch, useSelector } from "react-redux";
import { pendingDepositTotal } from "../../store/actions";

const PendingDeposit = () => {
  const dispatch = useDispatch();

  const { data } = useSelector((state) => ({
    data: state.Dashboard?.deposits?.data,
  }));

  // const getAmountRecord = (Ttype, type) => {
  //   if (pendingDeposit && pendingDeposit.length) {
  //     const data =
  //       pendingDeposit.filter((item) => item._id === Ttype)[0] || null;
  //     if (data && type === "amount")
  //       return `${data.totalAmount.toLocaleString("en-IN")}`;
  //     if (data && type === "record") return `(${data.recordCount})`;
  //   }
  //   if (type === "amount") return 0;
  // };

  // const getTotal = () => {
  //   if (pendingDeposit && pendingDeposit.length) {
  //     const totalAmount = pendingDeposit.reduce(
  //       (acc, item) => acc + item.totalAmount,
  //       0
  //     );
  //     return `${totalAmount.toLocaleString("en-IN")}`;
  //   }
  //   return 0;
  // };

  return (
    <React.Fragment>
      <Col xl={12} md={12}>
        <Card className="card-height-100">
          <CardHeader className="align-items-center d-flex">
            <h4 className="card-title mb-0 flex-grow-1">Pending Deposit</h4>
          </CardHeader>

          <CardBody>
            <Row className="align-items-center">
              <Col xs={6}>
                <h6 className="text-muted text-uppercase fw-semibold text-truncate fs-12 mb-3">
                  Total
                </h6>
                <h4 className="fs- mb-0">
                  <i className="mdi mdi-currency-inr align-middle text-primary me-2"></i>
                  {Math.round(data?.totalPendingDeposits * 100) / 100 || 0}
                </h4>
              </Col>
            </Row>

            <div className="mt-3 pt-2">
              <div className="d-flex mb-2">
                <div className="flex-grow-1">
                  <p className="text-truncate text-muted fs-14 mb-0">
                    <Link
                      to="/payment/deposit"
                      className="d-inline-block auth-logo"
                    >
                      <i className="mdi mdi-circle align-middle text-primary me-2"></i>
                      Payment Gateway Deposit{" "}
                      {`(${
                        data?.countPendingOnlineDeposits
                          ? data?.countPendingOnlineDeposits
                          : 0
                      })`}
                    </Link>
                  </p>
                </div>
                <div className="flex-shrink-0">
                  <p className="mb-0">
                    <i className="mdi mdi-currency-inr align-middle text-primary me-2"></i>
                    {Math.round(data?.totalPendingOnlineDeposits * 100) / 100 ||
                      0}
                  </p>
                </div>
              </div>
              <div className="d-flex mb-2">
                <div className="flex-grow-1">
                  <p className="text-truncate text-muted fs-14 mb-0">
                    <Link
                      to="/payment/deposit"
                      className="d-inline-block auth-logo"
                    >
                      <i className="mdi mdi-circle align-middle text-info me-2"></i>
                      Manual Deposit{" "}
                      {`(${
                        data?.pendingManualDepositsCount
                          ? Math.round(data?.pendingManualDepositsCount * 100) /
                            100
                          : 0
                      })`}
                    </Link>
                  </p>
                </div>
                <div className="flex-shrink-0">
                  <p className="mb-0">
                    <i className="mdi mdi-currency-inr align-middle text-primary me-2"></i>
                    {Math.round(
                      data?.totalPendingManualDepositsAllUsers * 100
                    ) / 100 || 0}
                  </p>
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default PendingDeposit;
