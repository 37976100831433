import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  FormFeedback,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Form,
  Row,
} from "reactstrap";
import DatePicker from "react-datepicker";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  DATE_RANGE,
  dropdownDateMapping,
} from "../../Components/constants/commonConstants";
const CustomDateModal = ({ isOpen, toggle, setDateRange }) => {
  const validation = useFormik({
    // enableReinitialize: true,

    initialValues: {
      startDate: new Date(),
      endDate: new Date(),
    },
    validationSchema: Yup.object({
      startDate: Yup.date()
        .required("Start date is required")
        .test(
          "is-before-endDate",
          "Start date must be before or equal to end date",
          function (startDate) {
            const endDate = this.parent.endDate;
            if (!startDate || !endDate) return true;

            return new Date(startDate) <= new Date(endDate);
          }
        ),
      endDate: Yup.date()
        .required("End date is required")
        .test(
          "is-after-startDate",
          "End date must be after or equal to start date",
          function (endDate) {
            const startDate = this.parent.startDate;
            if (!startDate || !endDate) return true;

            return new Date(endDate) >= new Date(startDate);
          }
        ),
    }),
    onSubmit: (values) => {
      const dateRange = dropdownDateMapping[DATE_RANGE.customDate](
        values.startDate,
        values.endDate
      );
      setDateRange(dateRange);
      toggle();
      validation.resetForm();
    },
  });

  useEffect(() => {
    setDateRange(dropdownDateMapping[DATE_RANGE.today]);
  }, []);

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Select Custom Date</ModalHeader>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          //   toggle();
          return false;
        }}
      >
        <ModalBody>
          <Row>
            <Col md={6}>
              {" "}
              <label htmlFor="startDate">From Date</label>
              <DatePicker
                className="form-control"
                selected={validation.values.startDate}
                onChange={(date) => {
                  validation.setFieldValue("startDate", date);
                }}
                name="startDate"
                dateFormat="dd-MM-yyyy"
              />
              {validation.touched.startDate && validation.errors.startDate && (
                <FormFeedback type="invalid">
                  {validation.errors.startDate}
                </FormFeedback>
              )}
            </Col>
            <Col md={6}>
              <label htmlFor="endDate">To Date</label>

              <DatePicker
                className="form-control"
                selected={validation.values.endDate}
                name="endDate"
                onChange={(date) => {
                  validation.setFieldValue("endDate", date);
                }}
                dateFormat="dd-MM-yyyy"
              />
              {validation.touched.endDate && validation.errors.endDate && (
                <FormFeedback type="invalid">
                  {validation.errors.endDate}
                </FormFeedback>
              )}
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            type="submit"
            className="ms-2 rounded-pill float-end"
          >
            Search
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default CustomDateModal;
