import React, { useState } from "react";
import { Button, Input, UncontrolledTooltip } from "reactstrap";
import Datatable from "../../../Components/Common/Datatable";
import { useSelector } from "react-redux";

const ListAdministrations = ({
  data,
  totalRows,
  handlePerRowsChange,
  handlePageChange,
  handleSort,
  loader,
  handleEdit,
  handleSelection,
  handleDelete,
  setAccount,
  setDetailModal,
}) => {
  const { modules } = useSelector((state) => ({
    modules: state.User.modules,
  }));

  const columns = [
    {
      name: <span className="font-weight-bold fs-13">Name</span>,
      selector: (row) => (
        <React.Fragment>
          {row.firstName} {row.lastName}
        </React.Fragment>
      ),
      sortable: true,
      fieldName: "firstName",
    },
    {
      name: <span className="font-weight-bold fs-13">Username</span>,
      selector: (row) => <React.Fragment>{row.username}</React.Fragment>,
      sortable: true,
      fieldName: "username",
    },
    // {
    //   name: <span className="font-weight-bold fs-13">Mobile Number</span>,
    //   selector: (row) => <React.Fragment>{row.mobileNumber}</React.Fragment>,
    //   sortable: true,
    //   fieldName: "mobileNumber",
    // },
    {
      name: <span className="font-weight-bold fs-13">Role</span>,
      selector: (row) => (
        <React.Fragment>{row?.roleName ? row?.roleName : ""}</React.Fragment>
      ),
      sortable: false,
      fieldName: "roleName",
    },
    {
      name: <span className="font-weight-bold fs-13">Action</span>,
      sortable: false,
      cell: (e) => {
        return (
          <React.Fragment>
            {modules?.["administrator-add-admin-button"] == 1 && (
              <React.Fragment>
                <Button
                  type="button"
                  className="btn btn-primary btn-icon waves-effect waves-light me-1"
                  onClick={() => handleEdit(e)}
                  id={`edit-${e._id}`}
                >
                  <i className="ri-pencil-fill "></i>
                </Button>
                <UncontrolledTooltip
                  placement="bottom"
                  target={`edit-${e._id}`}
                >
                  Edit
                </UncontrolledTooltip>
              </React.Fragment>
            )}
            {modules?.["administrator-delete-admin-button"] == 1 && (
              <React.Fragment>
                <Button
                  type="button"
                  className="btn btn-danger btn-icon   waves-effect waves-light me-1"
                  id={`delete-${e._id}`}
                  onClick={() => handleDelete([e._id])}
                >
                  <i className="ri-delete-bin-5-line"></i>
                </Button>
                <UncontrolledTooltip
                  placement="bottom"
                  target={`delete-${e._id}`}
                >
                  Delete
                </UncontrolledTooltip>
              </React.Fragment>
            )}
            {modules?.["administrator-profile-admin-button"] == 1 && (
              <React.Fragment>
                <Button
                  type="button"
                  className="btn btn-primary btn-icon me-1"
                  id={`profile-${e._id}`}
                  onClick={() => {
                    setAccount(e);
                    setDetailModal(true);
                  }}
                >
                  P
                </Button>
                <UncontrolledTooltip
                  placement="bottom"
                  target={`profile-${e._id}`}
                >
                  Profile
                </UncontrolledTooltip>
              </React.Fragment>
            )}
          </React.Fragment>
        );
      },
    },
  ];

  return (
    <React.Fragment>
      <Datatable
        columns={columns}
        data={data}
        totalRows={totalRows}
        handlePerRowsChange={handlePerRowsChange}
        handlePageChange={handlePageChange}
        handleSort={handleSort}
        loader={loader}
        selectableRows={true}
        handleSelection={handleSelection}
      />
    </React.Fragment>
  );
};

export default ListAdministrations;
