import { createSlice } from "@reduxjs/toolkit";
import {
  getModuleList,
  getRoleDropdownList,
  getRoleList,
  getRoleSettings,
} from "./action";
const initialState = {
  roleList: [],
  roleCount: 0,
  roleType: {},
  roleModules: {},
  roleActions: {},
  modules: [],
  rolesCheckboxes: [],
  roleDropdownList: [],
};

const usersSlice = createSlice({
  name: "Role",
  initialState,
  reducers: {
    setRoleList: (state, action) => {
      state.roleList = action.payload.items;
      state.roleCount = action.payload.total;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRoleSettings.fulfilled, (state, action) => {
        state.roleType = action.payload.data.roleType;
        state.roleModules = action.payload.data.module;
        state.roleActions = action.payload.data.action;
      })
      .addCase(getRoleList.fulfilled, (state, action) => {
        state.roleList = action.payload.items;
        state.roleCount = action.payload.total;
      })
      .addCase(getRoleDropdownList.fulfilled, (state, action) => {
        state.roleDropdownList = action.payload;
      })
      .addCase(getModuleList.fulfilled, (state, action) => {
        state.modules = action.payload?.data?.modules;
        state.rolesCheckboxes = action.payload?.data?.rolesDisplayModules;
      });
  },
});

export const { setRoleList, setRoleSetting, setError } = usersSlice.actions;

export default usersSlice.reducer;
