import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getSportTypes as getSportTypesAPI,
  updateSportType as updateSportTypeAPI,
} from "../../../helpers/backend_helper";

export const getSportTypes = createAsyncThunk(
  "market/getSportTypes",
  async (payload) => {
    const response = await getSportTypesAPI(payload);
    return response.data;
  }
);

export const updateSportType = createAsyncThunk(
  "market/updateSportType",
  async (data) => {
    const { eventTypeId, payload } = data;
    const response = await updateSportTypeAPI(eventTypeId, payload);
    return response.data;
  }
);
