import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getUserProfile as getUserProfileAPI,
  enable2FA as enable2FAAPI,
  verify2FA as verify2FAAPI,
  disable2FA as disable2FAAPI,
  resetPasswordApi,
  getUserModuleApi,
  getUserMenuApi,
  getBankDetailsApi,
  updateUserApi,
  updatePasswordApi,
  updateTransactionCodeApi,
  getUserLoginActivityApi,
  updateCreditAmount as updateCreditAmountAPI,
  decreaseCreditAmount as decreaseCreditAmountAPI,
  getSingleUserDetailsApi,
  transactionHistory as transactionHistoryAPI,
} from "../../helpers/backend_helper";
import { toast } from "react-toastify";

// 2FA
export const enable2FA = createAsyncThunk("user/enable2FA", async () => {
  const response = await enable2FAAPI();
  return response.data;
});
export const verify2FA = createAsyncThunk("user/verify2FA", async (code) => {
  const response = await verify2FAAPI(code);
  return response.data;
});

export const disable2FA = createAsyncThunk("user/disable2FA", (code) => {
  const response = disable2FAAPI(code);
  return response.data;
});

export const resetVerify2FA = createAsyncThunk("user/resetVerify2FA", () => {
  return true;
});

export const editProfile = createAsyncThunk("user/editProfile", (user) => {
  return true;
});

export const getUserProfile = createAsyncThunk(
  "user/getUserProfile",
  async () => {
    const response = await getUserProfileAPI();
    return response.data;
  }
);

export const updateUserDetail = createAsyncThunk(
  "Users/updateUserDetail",
  async (data) => {
    try {
      if (!("update" in data)) return;
      await updateUserApi(data.update);
      delete data.update;
      toast.success("User Update Successfully");
      return true;
    } catch (error) {
      toast.error("Failed to Update user");
      return false;
    }
  }
);

export const updateUserProfile = createAsyncThunk(
  "Users/updateUserProfile",
  async (data) => {
    if (!("update" in data)) return;
    await updateUserApi(data.update);
    delete data.update;
    return true;
  }
);

export const resetPassword = createAsyncThunk(
  "user/resetPassword",
  async (data) => {
    return await resetPasswordApi(data);
  }
);
export const updatePassword = createAsyncThunk(
  "user/account/updatePassword",
  async (data) => {
    const response = await updatePasswordApi(data);
    return response.data;
  }
);

export const updateTransactionCode = createAsyncThunk(
  "user/account/updateTransactionCode",
  async (data) => {
    const response = await updateTransactionCodeApi(data);
    return response.data;
  }
);

export const getUserModule = createAsyncThunk(
  "user/getUserModule",
  async (data) => {
    return await getUserModuleApi(data);
  }
);

export const getUserMenu = createAsyncThunk(
  "user/getUserMenu",
  async (data) => {
    return await getUserMenuApi(data);
  }
);

export const getBankDetails = createAsyncThunk(
  "user/getBankDetails",
  async ({ userId, data }) => {
    const response = await getBankDetailsApi(userId, data);
    return response.data;
  }
);

export const getUserLoginActivity = createAsyncThunk(
  "user/getUserLoginActivity",
  async ({ userId, data }) => {
    const response = await getUserLoginActivityApi(userId, data);
    return response.data;
  }
);

export const updateCreditAmount = createAsyncThunk(
  "user/updateCreditAmount",
  async (data) => {
    const response = await updateCreditAmountAPI(data);
    return response.data;
  }
);

export const decreaseCreditAmount = createAsyncThunk(
  "user/decreaseCreditAmount",
  async (data) => {
    const response = await decreaseCreditAmountAPI(data);
    return response.data;
  }
);

export const transactionHistory = createAsyncThunk(
  "user/transactionHistory",
  async (data) => {
    const response = await transactionHistoryAPI(data);
    return response.data;
  }
);

export const getSingleUserDetails = createAsyncThunk(
  "Users/getSingleUserDetails",
  async (payload) => {
    const response = await getSingleUserDetailsApi(payload);
    return response.data?.[0] || {};
  }
);
