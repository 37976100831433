import { createSlice } from "@reduxjs/toolkit";
import {
  addSiteRules,
  createEventRules,
  deleteSiteRules,
  getSiteRules,
  getSportTypesList,
  updateSiteRules,
} from "./action";
import { toast } from "react-toastify";

const initialState = {
  eventTypesList: [],
  siteRules: [],
  total: 0,
};

const Rules = createSlice({
  name: "Rules",
  initialState,
  reducers: {},
  extraReducers: (b) => {
    b.addCase(getSportTypesList.fulfilled, (state, action) => {
      state.eventTypesList = action.payload.data;
    });
    b.addCase(addSiteRules.fulfilled, (state, action) => {
      toast.success(action.payload.message);
      state.siteRules = action.payload;
    });
    b.addCase(getSiteRules.fulfilled, (state, action) => {
      state.siteRules = action.payload.res;
      state.total = action.payload.total;
    });
    b.addCase(updateSiteRules.fulfilled, (state, action) => {
      toast.success(action.payload.message);
      state.siteRules = action.payload;
    });
    b.addCase(createEventRules.fulfilled, (state, action) => {
      toast.success(action.payload.message);
    });
    b.addCase(deleteSiteRules.fulfilled, (state, action) => {
      toast.success(action.payload.message);
    });
  },
});

export default Rules.reducer;
