import React from "react";
import DataTable from "react-data-table-component";
import { Spinner } from "reactstrap";

const DatatableLoader = () => {
  return (
    <React.Fragment>
      <div className="d-flex justify-content-center mx-2 mt-2">
        <Spinner color="primary"> Loading... </Spinner>
      </div>
    </React.Fragment>
  );
};

const Datatable = ({
  columns,
  data,
  totalRows,
  handlePerRowsChange,
  handlePageChange,
  handleSort,
  handleSelection,
  loader,
  selectableRows,
  rowDisabledCriteria,
  conditionalRowStyles,
}) => {
  return (
    <React.Fragment>
      <DataTable
        responsive={true}
        columns={columns}
        data={data}
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        onSort={handleSort}
        sortServer
        selectableRows={selectableRows ? selectableRows : null}
        onSelectedRowsChange={handleSelection ? handleSelection : null}
        progressPending={loader}
        progressComponent={<DatatableLoader />}
        selectableRowDisabled={rowDisabledCriteria}
        conditionalRowStyles={conditionalRowStyles ? conditionalRowStyles : {}}
      />
    </React.Fragment>
  );
};

export default Datatable;
